export default {
  HAVE_OWN_CARDS: 'Create your own cards',
  VALIDATE_REQUEST: 'Validate transaction requests',
  EDIT_TRANSACTION_DETAILS: 'Edit transaction details',
  VIEW_HIS_TRANSACTION: 'See your own transactions',
  VIEW_EXPORT_ALL_TRANSACTIONS: 'View and export all transactions',
  VIEW_STC: 'View account balance',
  INIT_TRANSFER: 'Initiating transfers',
  MANAGE_ALL_CARDS: 'Manage cards',
  MANAGE_TEAM: 'Manage the team',
  EXECUTE_REQUEST: 'Make transaction requests',
};
