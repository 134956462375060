import React from 'react';
import { useAppSelector } from '../../../../redux/hook';
import AppBar from './AppBar';

const AppBarProvider = () => {
  const company = useAppSelector((state) => state.account.myCompany?.company);
  return <AppBar idCompany={company?.id || ''} />;
};

export default AppBarProvider;
