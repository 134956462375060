import gql from 'graphql-tag';

const RIGHT_FRAGMENT = gql`
  fragment rightInfo on RoleRight {
    id
    title
    constantCode
  }
`;
const ROLES_INVITE_FRAGMENT = gql`
  fragment roleInviteInfo on Role {
    id
    name
  }
`;

const ROLES_FRAGMENT = gql`
  fragment roleInfo on Role {
    id
    name
    rights {
      ...rightInfo
    }
  }
  ${RIGHT_FRAGMENT}
`;

export { ROLES_FRAGMENT, ROLES_INVITE_FRAGMENT, RIGHT_FRAGMENT };
