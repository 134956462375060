import gql from 'graphql-tag';

export const PUT_SIGNED_URL_FRAGMENT = gql`
  fragment putSignedUrlInfo on UploadFileSignedUrl {
    uuid
    putSignedUrl
  }
`;

export const DOWNLOAD_FILE_SIGNED_URL_FRAGMENT = gql`
  fragment DownloadFileSignedUrlInfo on DownloadFileSignedUrl {
    getSignedUrl
  }
`;

export const DOCUMENT_TYPE_FRAGMENT = gql`
  fragment DocumentTypeInfo on DocumentType {
    id
    name
    category
  }
`;

export const DOCUMENT_FRAGMENT = gql`
  fragment DocumentInfo on Document {
    id
    uuid
    filename
    filetype
    documentTypeId
  }
`;



export const DOCUMENT_INVITE_FRAGMENT = gql`
  fragment DocumentInfo on Document {
    id
    uuid
    filename
    filetype
    documentTypeId
  }
`;