import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DropDown from '@material-ui/icons/ArrowDropDown';
import React, { MouseEvent, useRef, useState } from 'react';
import { AccountAction } from '../Layout/AppBar/AccountAction';
import MemberAvatar from '../MemberAvatar';

const styles = makeStyles(theme => ({
  blockProfile: {
    color: theme.palette.text.primary,
    cursor: 'pointer',
    '& svg': {
      color: 'currentColor',
    },
  },
}));

const AppBarProfile = () => {
  const classes = styles();
  const [open, setOpen] = useState(false);
  const dropdownClick = useRef<any>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleToggle = (event: MouseEvent<HTMLElement>) => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  };

  const profileClick = (event: MouseEvent<HTMLElement>) => {
    dropdownClick.current.click();
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      marginLeft={2}
      className={classes.blockProfile}
      onClick={profileClick}
    >
      <MemberAvatar />
      <IconButton
        size="small"
        onClick={handleToggle}
        ref={dropdownClick}
        color="inherit"
      >
        <DropDown htmlColor="currentColor" />
      </IconButton>
      <AccountAction
        open={open}
        anchorEl={anchorEl}
        handleClickAway={handleClickAway}
      />
    </Box>
  );
};

export default AppBarProfile;
