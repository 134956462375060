export default {
  'panel.text.titleHome': 'Transactions en cours',
  'panel.button.virement': 'Virements',
  'panel.button.prelevement': 'Prélèvements',
  'panel.button.validate': 'Valider',
  'transaction.panel.pass': 'Passer',
  'panel.button.cancel': 'Annuler',
  'panel.button.seeAll': 'Voir tout',
  'transaction.panel.virement.details.file': 'Pièce Justificative',
  'transction.detail.prelevement.lastTransaction': 'Dernière Opération',
  'transction.detail.prelevement.nextTransaction': 'Prochaine',
  'transction.detail.prelevement.countTransaction': 'Total',
  'transction.detail.prelevement.historyTransaction': 'Historique',
  'transction.detail.prelevement.buttonTransaction': 'Suspendre',
  'transaction.detail.label.validate': 'À valider',
  'transaction.detail.label.progress': 'En cours',
  'transaction.detail.label.noTransaction':
    "Vous n'avez aucun virement en attente.",
  'transaction.detail.label.message':
    'Tous les virements validés basculeront dans cette section.',
  'transaction.panel.list.validate': 'Liste des virements à valider',
  'transaction.panel.list.waiting': 'Liste des virements en cours',
  'transaction.label.accountCredited': 'Créditer',
  'account.credited.title': 'Créditer votre compte',
  'transaction.label.creditAccount': 'Créditer votre compte',
  'account.credited.price': 'Montant',
  'account.credited.success': 'Votre compte a été crédité avec succès',
  'payment.input.amount': 'Montant',
};
