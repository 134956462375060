import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { INVITE_URL_EXPIRATION } from '../../../graphql/team/query';
import {
  InviteUrlExpiration,
  InviteUrlExpirationVariables,
} from '../../../graphql/team/types/InviteUrlExpiration';
import { DashboardItem, _dashboards } from '../../../indexDB/data';
import { clearToken, setToken } from '../../../redux/auth/loginSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';
import { clearLocalStorage } from '../../../services/LocalStorage';

const useCheckToken = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.login.token);

  // check token if valid
  const [verifyToken] = useLazyQuery<
    InviteUrlExpiration,
    InviteUrlExpirationVariables
  >(INVITE_URL_EXPIRATION, {
    fetchPolicy: 'network-only',
    onCompleted: (result) => {
      if (result && !result.inviteUrlExpiration) {
        clearData();
      }
    },
  });

  useEffect(() => {
    if (token) {
      verifyToken({
        variables: {
          token,
        },
      });
    } else {
      setReduxToken();
    }
  }, [location.pathname]);

  const setReduxToken = async () => {
    const storageToken = await _dashboards.getItem(DashboardItem.MY_ID);
    const locaEmail = await _dashboards.getItem(DashboardItem.MY_EMAIL);
    if (storageToken) {
      verifyToken({
        variables: {
          token: storageToken as string,
        },
      }).then((result) => {
        if (result?.data?.inviteUrlExpiration) {
          dispatch(
            setToken({
              token: storageToken as string,
              email: locaEmail ? (locaEmail as string) : '',
            }),
          );
        }
      });
    } else {
      clearData();
    }
  };
  const clearData = async () => {
    dispatch(clearToken());
    await _dashboards.removeItem(DashboardItem.MY_ID);
    clearLocalStorage();
    navigate('/login');
  };
};

export default useCheckToken;
