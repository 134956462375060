import Email from '@material-ui/icons/EmailOutlined';
import React from 'react';
import { NOTIFICATION_TYPE } from '../../../utils/constant';
import Functionality from '../../Assets/Icons/Functionality/Functionality';
import Service from '../../Assets/Icons/Service/Service';
const CustomNotificationIcon = (props: { constantCode: string }) => {
  const { constantCode } = props;
  switch (constantCode) {
    case NOTIFICATION_TYPE.FONCTIONNALITE:
      return <Functionality viewBox="0 0 32 32" />;
    case NOTIFICATION_TYPE.MESSAGE:
      return <Email />;
    default:
      return <Service />;
  }
};

export default CustomNotificationIcon;
