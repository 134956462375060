import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ISetAccountForState {
    accountForStateId?:string;
    data?: string;
}
export interface ISetCategorie {
    CategoriesId?:string;
    data?: string;
}


export const statisticSlice = createSlice({
    name:"statistic",
    initialState:{
        statistic:{
            accountForStateList : [] as ISetAccountForState[],
            categoriesList: [] as ISetCategorie[],
            dataCategoriesTr:"",
            dataTableBarStatisticList:""
        }
        
    },
    reducers:{
        setAccountForStatesList:(state,action:PayloadAction<ISetAccountForState[]>)=>{
            state.statistic.accountForStateList= action.payload
        },
        setCategoriesList:(state,action:PayloadAction<ISetCategorie[]>)=>{
            state.statistic.categoriesList=  action.payload
        },
        setDataStatisticList:(state,action:PayloadAction<string>)=>{
            state.statistic.dataCategoriesTr=action.payload
        },
        setDataTableBarStatisticList:(state,action:PayloadAction<string>)=>{
            state.statistic.dataTableBarStatisticList=action.payload
        },
    }
})

export const { setAccountForStatesList,setCategoriesList,setDataStatisticList,setDataTableBarStatisticList}=statisticSlice.actions;
export default statisticSlice.reducer;