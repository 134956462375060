import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';
import { useAppSelector } from '../../../redux/hook';

const MemberName = () => {
  const myCompany = useAppSelector((state) => state.account.myCompany);

  return (
    <Typography component="span" style={{ fontWeight: 600 }}>{`${
      myCompany?.myAccount?.firstName || ''
    }`}</Typography>
  );
};

export default MemberName;
