import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { MyCompany_myCompany } from '../../graphql/graphql_account/company/types/MyCompany';
import { MyCompanySetting_myCompanySetting } from '../../graphql/graphql_account/company/types/MyCompanySetting';
import { Setting } from '../../components/customHooks/company/myCompanySetting';
export interface ISetAccountPayload {
  solde?: string;
  payin?: string;
  payout?: string;
  urlAvatar?: string;
}
export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    header: {
      solde: '',
      payin: '',
      payout: '',
    },
    avatarUrl: '',
    readonly: false,
    myCompany: {} as MyCompany_myCompany | null,
    myCompanySetting: {} as MyCompanySetting_myCompanySetting | null,
    companySetting: {} as Setting,
  },
  reducers: {
    setCompanyReadOnly: (state, action: PayloadAction<boolean>) => {
      state.readonly = action.payload;
    },
    setAccount: (state, action: PayloadAction<ISetAccountPayload>) => {
      let newObject = {};
      Object.assign(newObject, action.payload);
      state.header = { ...state.header, ...newObject };
    },
    setUrlAvatar: (state, action: PayloadAction<string>) => {
      state.avatarUrl = action.payload;
    },
    setMyCompany: (state, action: PayloadAction<MyCompany_myCompany>) => {
      state.myCompany = action.payload;
    },
    setMyCompanySetting: (
      state,
      action: PayloadAction<MyCompanySetting_myCompanySetting>,
    ) => {
      state.myCompanySetting = action.payload;
    },
    setCompanySetting: (state, action: PayloadAction<Setting>) => {
      state.companySetting = action.payload;
    },
  },
});

export const {
  setAccount,
  setUrlAvatar,
  setCompanyReadOnly,
  setMyCompany,
  setMyCompanySetting,
  setCompanySetting,
} = accountSlice.actions;

export default accountSlice.reducer;
