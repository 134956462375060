export default {
  'company.label.companyActivity': `Activité de l'entreprise`,
  'company.legalName.empty':
    "Veuillez renseigner la raison sociale de l'entreprise.",
  'company.legalForm.empty':
    "Veuillez renseigner la forme juridique de l'entreprise.",
  'company.legalRegistrationNumber.empty': 'Veuillez renseigner SIRET.',
  'company.address.empty': `Veuillez renseigner l'adresse de la société.`,
  'company.postCode.empty': `Veuillez renseigner le code postal de la société.`,
  'company.city.empty': `Veuillez renseigner la ville où se trouve la société.`,
  'company.getInfo.error':
    'Erreur lors de la récupération des informations de la société.',
  'company.updateInfo.error':
    'Erreur lors de la mise à jour des informations de la société.',
  'company.update.success': 'Entreprise mise à jour avec succès.',
  'company.getAccountInfo.error':
    'Erreur lors de la récupération de vos informations personnelles.',
  'company.label.beingCreated': 'Votre compagnie est en cours de création.',
  'company.label.noSIRET': 'Vous ne possédez pas de numéro SIRET.',
  'setting.iban': 'IBAN',
  'setting.bic': 'BIC',
  'setting.actualOffer': 'Offre actuelle',
  'setting.getOfferError': `Erreur lors de la récupération des informations concernant votre offre.`,
  'setting.change.email': 'Changer',
  'setting.offer.upgrade': 'Mettre à jour',

  'setting.facture.title': `Vos Factures`,
  'setting.facture.link': `Téléchargez tout`,
  'facture.dialogue': `Factures`,
  'setting.download.facture.error':
    'Problème lors du téléchargement de facture',

  'setting.download.rib': 'RIB',
  'setting.download.rib.error': 'Problème lors du téléchargement du RIB',
  'setting.offer.actual': 'Actuel',
  'setting.offers': 'Forfaits',
  'setting.offers.details': 'Détails des forfaits',
  'setting.dialog.email.title': `Changer mon adresse mail`,
  'setting.dialog.email.text':
    'Vous devez fournir un mot de passe pour changer votre mail',
  'password.not.match': 'Veuillez insérer le bon mot de passe',

  'setting.text.changeMail': 'Entrez votre nouveau mail pour le changer',
  'setting.email.empty': `Le champ email est requis`,
  'setting.email.notValid': `L'adresse mail est invalide`,

  'setting.dialog.offer.title': 'Détails des offres',
  'setting.offer.details': 'Détails',

  'offer.free': 'Gratuit',

  'offer.servicePlus': 'Service Plus',
  'offer.liveCard': 'Live Card',
  'offer.smartDashboard': 'Smart Dashboard',
  'offer.categorisationAuto': 'Catégorisation Automatique',

  'offer.fonctionality': 'Fonctionnalités',
  'offer.users': 'Utilisateurs',
  'offer.smartAccess': 'Smart Access',
  'offer.encaissement': 'Encaissements',
  'offer.cheque': 'Chèques',
  'offer.quantity': 'Quantité',
  'offer.withdrawal': 'Retraits',
  'offer.payinsAndpayouts': 'Virements / Prélèvements',
  'offer.cardTransaction': 'Paiements par carte',
  'offer.virtual': 'Virtuelle',
  'offer.physical': 'Physique',
  'offer.businnesMastercard': 'Business Mastercard',
  'offer.noLimit': 'Illimités',
  'offer.limitAtmWeek': 'Limite retrait / semaine',
  'offer.limitPaymentMonth': 'Limite paiement / mois',
  'offer.autorized': 'Autorisé',

  'setting.email.validationText':
    'Merci de renseigner le code de sécurité qui vous a été envoyé dans votre mail.',
  'setting.dialog.edit.title': 'Modifier les informations de la société',

  'setting.edit.identity': 'Identité',
  'setting.edit.coordinate': 'Coordonnées',
  'setting.edit.informations': 'Informations',
  'img.deposit1': 'glissez déposez',
  'img.deposit2': 'dans le cadre',
  'setting.import.image': `Veuillez survoler le cadre pour ajouter le logo`,
  'request.email.error': `Erreur lors de la vérification de l'adresse mail`,
  'change.email.error': `Erreur lors de la mise à jour de l'adresse mail`,
  'change.avatar.error': `Erreur lors de la mise à jour de le l'avatar`,

  'setting.company.cgs': `Conditions générales de services - Sapheer`,
  'setting.company.cgu': `Conditions générales d'utilisations - Cartes`,
  'setting.company.payment': `Conditions générales d'utilisations - Services de paiement`,
  'setting.company.confidentiality': `Politique de confidentialité`,

  'setting.title.contract': `Contrats`,
  'setting.title.confidentiality': `Données personnelles`,
  'setting.company.Pcc': ` Politique de confidentialité Contact`,

  'setting.company.footer': `Spark, société immatriculée au registre du commerce et des sociétés de Nanterre sous le numéro 853 100 527, exploitant le nom commercial Sapheer est enregistrée par l’Autorité de Contrôle Prudentiel et de Résolution (« ACPR ») sous le numéro 85319, enregistrement consultable dans le Registre des agents financiers (www.regafi.fr) en tant qu’agent de services de paiement de l’établissement de monnaie électronique Treezor (CIB 16798), dont le siège social est situé 41 rue de Prony 75017 Paris.`,

  'setting.company.contact': 'Contact',
};
