import Common from './common';
import SignIn from './auth/signin';
import Forgot from './auth/forgot';
import Card from './card/card';
import CardPayment from './card/hipay';
import OrderHipay from './order/order';
import CardCommand from './card/command';
import Reset from './auth/reset';
import InviteMember from './member/member';
import Role from './member/role';
import CompanyInfo from './settings/companyInfo';
import TeamMain from './team/main';
import TeamMemberDialog from './team/memberDialog';
import ErrorCode from './errorCode';
import Transaction from './transaction/transaction';
import TransactionDialog from './transaction/dialog';
import TransactionToolbar from './transaction/toolbar';
import TransactionPanel from './transaction/panel';
import Dashboard from './dashboard/dashboard';
import Services from './services/services';
import Category from './category';
import Notification from './notification/notification';
import TransactionAccountStatement from './transaction/accountStatement';
import Cheque from './cheque';
export default {
  ...Dashboard,
  ...CardPayment,
  ...OrderHipay,
  ...Card,
  ...Role,
  ...CardCommand,
  ...InviteMember,
  ...Reset,
  ...Common,
  ...SignIn,
  ...Forgot,
  ...CompanyInfo,
  ...TeamMain,
  ...TeamMemberDialog,
  ...ErrorCode,
  ...Transaction,
  ...TransactionDialog,
  ...TransactionToolbar,
  ...TransactionPanel,
  ...Category,
  ...Services,
  ...Notification,
  ...TransactionAccountStatement,
  ...Cheque,
};
