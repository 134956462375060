import gql from 'graphql-tag';

export const ACCOUNT_FRAGMENT = gql`
  fragment AccountInfo on Account {
    id
    email
    phone
    isInvitationAccepted
    avatarUuid
    avatarFilename
  }
`;
