import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ISetAuth {
  token: string;
  email: string;
}

export const authSlice = createSlice({
  name: 'login',
  initialState: {
    token: '',
    email: '',
  },
  reducers: {
    setToken: (state, action: PayloadAction<ISetAuth>) => {
      state.token = action.payload.token;
      state.email = action.payload.email;
    },
    clearToken: (state, action: PayloadAction) => {
      state.token = '';
    },
  },
});

export const { setToken, clearToken } = authSlice.actions;

export default authSlice.reducer;
