export const getLocale = (): string => {
  const supportedLanguages = ['en-US', 'fr-FR'];

  let locale: string =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    'fr-FR';

  locale = locale === 'fr' ? 'fr-FR' : locale;

  if (!supportedLanguages.find((l) => l === locale)) {
    locale = 'fr-FR';
  }

  if (localStorage.getItem('language')) {
    locale = localStorage.getItem('language') || '';
  }
  return locale;
};


export const setPriceLocale = (price: any): string => {
  const supportedLanguages = ['en-US', 'fr-FR'];

  let locale: string =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    'fr-FR';

  locale = locale === 'fr' ? 'fr-FR' : locale;

  if (!supportedLanguages.find((l) => l === locale)) {
    locale = 'fr-FR';
  }

  if (localStorage.getItem('language')) {
    locale = localStorage.getItem('language') || '';
  }
  const currency = locale === 'fr-FR' ? 'EUR' : 'EUR'; // => USD if english
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(price);
}