export default {
  'transaction.label.downloadStatement': 'Relevé',
  'transaction.label.downloadStatement.title': 'Télécharger relevé de compte',
  'transaction.label.accountStatement': 'Relevé de compte',
  'transaction.text.downloadAccountStatement':
    'Vous pouvez télécharger votre relevé de compte',
  'transaction.error.invalidDatePeriod': '* La période de dates est invalide.',
  'transaction.error.downloadStatement':
    'Erreur lors du téléchargement du relevé.',
};
