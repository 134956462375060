import { Avatar } from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

const CustomAvatar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 30,
      height: 30,
      fontSize: '.75rem',
    },
    colorDefault: {
      color: 'inherit',
      backgroundColor: 'transparent',
      border: `1px solid currentColor`,
    },
  }),
)(Avatar);

export default CustomAvatar;
