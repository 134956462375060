export default {
  'notification.error.getNotifications': 'Error while getting notifications.',
  'notification.error.cannotGetMedia': `Error while getting notification's image or video, please check your Internet connexion.`,
  'notification.text.noNotification': `You don't have any notifications yet.`,
  'notification.text.selectNotification': `Select a notification to display it in this section.`,
  'notification.text.notificationList': 'Notifications lists',
  'notification.title.functionalities': 'Functionalities',
  'notification.title.messages': 'Messages',
  'notification.title.servicesPlus': 'Services Plus',
};
