import { useState } from 'react';
import { fetcherWEB } from '../../../apolloClient';
import { API_ACCOUNT } from '../../../config';
import { getIp } from '../../../utils/common';
import { ADD_CONNEXION } from '../../../graphql/graphql_account/signin/mutation';
import { DeviceType } from '../../../utils/constant';
import {
  AddConnexion,
  AddConnexionVariables,
} from '../../../graphql/graphql_account/signin/types/AddConnexion';
import { setConnexionID } from '../../../services/LocalStorage';

export type conexionInput = {
  id?: string | null;
  email: string;
  isSignin: boolean;
};

const useAddConnexion = () => {
  const [loading, setLoading] = useState(false);
  const setAddConnexion = async (conexionInput: conexionInput) => {
    setLoading(true);
    const { id, email, isSignin } = conexionInput;
    const { data } = await getIp();
    const variables = {
      id,
      email,
      ipAddress: data?.ip || '0.0.0.0',
      isSignin,
      deviceType: DeviceType.WEB,
    };
    return await fetcherWEB<AddConnexionVariables, AddConnexion>(
      ADD_CONNEXION,
      variables,
      API_ACCOUNT,
    )
      .then(async (result) => {
        setLoading(false);
        if (result?.addConnexion?.id) {
          const connexionString = JSON.stringify(result.addConnexion);
          setConnexionID(connexionString);
          return result;
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const addConnexion = async (
    conexionInput: conexionInput,
  ): Promise<AddConnexion> => {
    return (await setAddConnexion(conexionInput)) as AddConnexion;
  };

  return { loading, addConnexion };
};

export default useAddConnexion;
