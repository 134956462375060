import { useApolloClient, useQuery, useSubscription } from '@apollo/client';
import React from 'react';
import { IS_ALL_TRANSACTION_PANEL } from '../../../../graphql/Common/snackbar/query';
import { ALL_TRANSACTIONS } from '../../../../graphql/transaction/query';
import { ON_TRANSACTION_SEND } from '../../../../graphql/transaction/subscription';
import {
  AllCompanyTransactions,
  AllCompanyTransactionsVariables,
} from '../../../../graphql/transaction/types/AllCompanyTransactions';
import {
  OnTransactionSend,
  OnTransactionSendVariables,
} from '../../../../graphql/transaction/types/OnTransactionSend';
import {
  DEFAULT_NOT_VALIDATED_TR_ARGS,
  TR_INVALIDATED_SIZE,
} from '../../../../utils/transaction';
import CardTransactionSub from './CardTransactionSub';
import PayoutSub from './PayoutSub';
import TransactionCanceled from './TransactionCanceled';
import TransfertSub from './TransfertSub';
import ChequeSub from './ChequeSub';
import {
  DEFAULT_CHEQUE_LIST_PARAMS,
  VIEW_TABLE_DEFAULT_VIEW,
} from '../../../Cheque/ChequeLogic';
import { IS_CHEQUE_TAB } from '../../../../graphql/Common/snackbar/query';

const TransactionSub = (props: { companyId: string }) => {
  const { companyId } = props;
  let size: number | null = TR_INVALIDATED_SIZE;
  let chequeSize: number | null = DEFAULT_CHEQUE_LIST_PARAMS.size;
  const client = useApolloClient();
  const { data } = useQuery(IS_ALL_TRANSACTION_PANEL, {
    fetchPolicy: 'cache-only',
  });
  const { data: chequeData } = useQuery(IS_CHEQUE_TAB, {
    fetchPolicy: 'cache-only',
  });

  useSubscription<OnTransactionSend, OnTransactionSendVariables>(
    ON_TRANSACTION_SEND,
    {
      variables: { companyId },
      client,
      onSubscriptionData: ({ subscriptionData: { data } }) => {
        if (data && data.onTransactionSend) {
          const sendTransaction = data.onTransactionSend;
          // Update invalidated transaction list (remove canceled transaction)
          const invalidatedTr = client.readQuery<
            AllCompanyTransactions,
            AllCompanyTransactionsVariables
          >({
            query: ALL_TRANSACTIONS,
            variables: {
              ...DEFAULT_NOT_VALIDATED_TR_ARGS,
              size,
              page: null,
            },
          });
          if (
            invalidatedTr &&
            invalidatedTr.AllCompanyTransactions &&
            invalidatedTr.AllCompanyTransactions.transactions
          ) {
            const invalidatedTransaction =
              invalidatedTr.AllCompanyTransactions.transactions;

            // Only write into client for new transfert (for update transfert, has timer, no need to update client)
            client.writeQuery<
              AllCompanyTransactions,
              AllCompanyTransactionsVariables
            >({
              query: ALL_TRANSACTIONS,
              variables: {
                ...DEFAULT_NOT_VALIDATED_TR_ARGS,
                size,
                page: null,
              },
              data: {
                AllCompanyTransactions: {
                  ...invalidatedTr.AllCompanyTransactions,
                  transactions: invalidatedTransaction.map((tr) => {
                    if (tr && tr.id && tr.id !== sendTransaction.id) {
                      return {
                        ...tr,
                        transactionState: sendTransaction.transactionState,
                      };
                    }
                    return tr;
                  }),
                },
              },
            });
          }
        }
      },
    },
  );
  if (data && data.isAllTransactionPanel && data.isAllTransactionPanel.isAll) {
    size = null;
  }
  if (chequeData && chequeData.isChequeTab && chequeData.isChequeTab.isTab) {
    chequeSize = VIEW_TABLE_DEFAULT_VIEW;
  }

  return (
    <>
      <CardTransactionSub companyId={companyId} />
      <ChequeSub companyId={companyId} size={chequeSize} />
      <PayoutSub companyId={companyId} size={size} />
      <TransfertSub companyId={companyId} size={size} />
      <TransactionCanceled companyId={companyId} size={size} />
    </>
  );
};

export default TransactionSub;
