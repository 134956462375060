export default {
  'panel.text.titleHome': 'Ongoing transactions',
  'panel.button.virement': 'Transfer',
  'panel.button.prelevement': 'Sample',
  'transaction.panel.pass': 'Skip',
  'panel.button.validate': 'Validate',
  'panel.button.cancel': 'Cancel',
  'panel.button.seeAll': 'See all',
  'transaction.panel.virement.details.file': 'Supporting document',
  'transction.detail.prelevement.lastTransaction': 'Last Operation',
  'transction.detail.prelevement.nextTransaction': 'Next',
  'transction.detail.prelevement.countTransaction': 'Total',
  'transction.detail.prelevement.historyTransaction': 'History',
  'transction.detail.prelevement.buttonTransaction': 'Suspend',
  'transaction.detail.label.validate': 'To validate',
  'transaction.detail.label.progress': 'In progress',
  'transaction.detail.label.noTransaction': 'You have no pending transfer.',
  'transaction.detail.label.message':
    'All validated transfers will switch to this section.',
  'transaction.panel.list.validate': 'List of all transfer to validate',
  'transaction.panel.list.waiting': 'List of all transfer pending',
  'transaction.label.accountCredited': 'Credit',

  'account.credited.title': 'Credit your account',
  'transaction.label.creditAccount': 'Credit your account',
  'account.credited.price': 'Amount',
  'account.credited.success': 'Your account was credited with success',
  'payment.input.amount': 'Amount',
};
