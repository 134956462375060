import gql from 'graphql-tag';
import { TRANSACTION_CARD_FRAGMENT } from './fragments/fragment_transaction';

export const ON_CARD_TRANSACTION_CREATE = gql`
  subscription OnCardTransactionCreated($companyId: ID!) {
    onCardTransactionCreate(companyId: $companyId) {
      ...TransactionCardInfo
    }
  }
  ${TRANSACTION_CARD_FRAGMENT}
`;

export const ON_PAYOUT_CREATE = gql`
  subscription OnPayoutCreated($companyId: ID!) {
    onPayoutCreate(companyId: $companyId) {
      ...TransactionCardInfo
    }
  }
  ${TRANSACTION_CARD_FRAGMENT}
`;

export const ON_PAYOUT_UPDATE = gql`
  subscription OnPayoutUpdated($companyId: ID!) {
    onPayoutUpdate(companyId: $companyId) {
      ...TransactionCardInfo
    }
  }
  ${TRANSACTION_CARD_FRAGMENT}
`;

export const ON_TRANSFERT_VALIDATED = gql`
  subscription OnTransferValidated($companyId: ID!) {
    onTransferValidate(companyId: $companyId) {
      ...TransactionCardInfo
    }
  }
  ${TRANSACTION_CARD_FRAGMENT}
`;
export const ON_TRANSFERT_CREATED = gql`
  subscription OnTransferCreated($companyId: ID!) {
    onTransferCreate(companyId: $companyId) {
      ...TransactionCardInfo
    }
  }
  ${TRANSACTION_CARD_FRAGMENT}
`;

export const ON_TRANSACTION_CANCELED = gql`
  subscription OnTransactionCancel($companyId: ID!) {
    onTransactionCancel(companyId: $companyId) {
      id
    }
  }
`;
