import gql from 'graphql-tag';
import {
  TRANSACTION_DETAILS_FRAGMENT,
  TRANSACTION_COMPANY_FRAGMENT,
  TRANSACTION_BY_MEMBERS_FRAGMENT,
  TRANSACTION_STATISTIC_FRAGMENT,
  TRANSACTION_BY_MODALITY_TYPE_FRAGMENT,
  TRANSACTION_FLUX_FRAGMENT,
  TRANSACTION_BENEFICIARY_FRAGMENT,
  TRANSACTION_CARD_STATISTIC_FRAGMENT,
} from './fragment';

const TRANSACTION = gql`
  query Transaction($id: ID!) {
    transaction(id: $id) {
      ...TransactionDetailsInfo
    }
  }
  ${TRANSACTION_DETAILS_FRAGMENT}
`;

const SUM_AND_GROUP_ALL_COMPANY_TRANSACTIONS = gql`
  query sumAndGroupCompanyTxByDateAndModType($totalDay: Int) {
    sumAndGroupCompanyTxByDateAndModType(totalDay: $totalDay) {
      dateTransaction
      totalIn
      totalOut
      solde
    }
  }
`;

const SUM_MEMBERS_TRANSACTIONS = gql`
  query SumMembersTransactions(
    $modalityType: Int!
    $totalDay: Int
    $member: Int
  ) {
    sumAllMembersTransactions(
      modalityType: $modalityType
      totalDay: $totalDay
      member: $member
    ) {
      ...TransactionByMembersInfo
    }
  }
  ${TRANSACTION_BY_MEMBERS_FRAGMENT}
`;

const SUM_TRANSACTIONS_BY_CATEGORIES = gql`
  query SumAllTransactionsByCategory(
    $modalityType: Int!
    $totalDay: Int
    $categoryId: Int
  ) {
    sumAllTransactionsByCategory(
      modalityType: $modalityType
      totalDay: $totalDay
      categoryId: $categoryId
    ) {
      ...TransactionStatisticInfo
    }
  }
  ${TRANSACTION_STATISTIC_FRAGMENT}
`;

const SUM_ALL_CARD_TRANSACTIONS_BY_CATEGORY = gql`
  query SumAllCardTransactionsByCategory(
    $cardId: Int!
    $totalDay: Int
    $categoryId: Int
  ) {
    sumAllCardTransactionsByCategory(
      cardId: $cardId
      totalDay: $totalDay
      categoryId: $categoryId
    ) {
      ...TransactionCardStatisticInfo
    }
  }
  ${TRANSACTION_CARD_STATISTIC_FRAGMENT}
`;

const SUM_TRANSACTIONS_BY_MODALITY_TYPE = gql`
  query SumAllTransactionsByModalityType($totalDay: Int) {
    sumAllTransactionsByModalityType(totalDay: $totalDay) {
      ...TransactionByModalityTypeInfo
    }
  }
  ${TRANSACTION_BY_MODALITY_TYPE_FRAGMENT}
`;

const SUM_TRANSACTIONS_BY_MODALITY = gql`
  query SumAllTransactionsByModality($modalityType: Int!, $totalDay: Int) {
    sumAllTransactionsByModality(
      modalityType: $modalityType
      totalDay: $totalDay
    ) {
      ...TransactionFluxInfo
    }
  }
  ${TRANSACTION_FLUX_FRAGMENT}
`;

const ALL_TRANSACTIONS = gql`
  query AllCompanyTransactions(
    $beneficiaryName: String
    $categoryId: ID
    $modality: [Int]
    $modalityType: Int
    $recurrenceType: Int
    $totalDay: Int
    $transactionState: [String]
    $page: Int
    $size: Int
    $transactionTreezorId: Boolean
  ) {
    AllCompanyTransactions(
      beneficiaryName: $beneficiaryName
      categoryId: $categoryId
      modality: $modality
      modalityType: $modalityType
      recurrenceType: $recurrenceType
      totalDay: $totalDay
      transactionState: $transactionState
      page: $page
      size: $size
      transactionTreezorId: $transactionTreezorId
    ) {
      transactions {
        ...TransactionCompanyInfo
      }
      page
      size
      totalPage
      totalElements
    }
  }
  ${TRANSACTION_COMPANY_FRAGMENT}
`;

const TRANSACTION_BENEFICIARY = gql`
  query TransactionBeneficiary(
    $beneficiaryName: String
    $categoryId: ID
    $modality: [Int]
    $modalityType: Int
    $recurrenceType: Int
    $totalDay: Int
    $transactionState: [String]
    $page: Int
    $size: Int
  ) {
    AllCompanyTransactions(
      beneficiaryName: $beneficiaryName
      categoryId: $categoryId
      modality: $modality
      modalityType: $modalityType
      recurrenceType: $recurrenceType
      totalDay: $totalDay
      transactionState: $transactionState
      page: $page
      size: $size
    ) {
      transactions {
        ...TransactionBeneficiaryInfo
      }
      page
      size
      totalPage
    }
  }
  ${TRANSACTION_BENEFICIARY_FRAGMENT}
`;

const TRANSACTION_TIMER = gql`
  query TransactionTimer($id: ID!) {
    transaction(id: $id) {
      id
      timer
    }
  }
`;

const CURRENT_SERVER_DATE = gql`
  query CurrentServerDate {
    currentServerDate
  }
`;

const DOWNLOAD_FACTURE = gql`
  query DownloadFacture(
    $startDate: DateTime
    $endDate: DateTime
    $totalDay: Int
    $selectedDate: DateTime
  ) {
    downloadFacture(
      startDate: $startDate
      endDate: $endDate
      totalDay: $totalDay
      selectedDate: $selectedDate
    )
  }
`;

const ALL_UNPAID_TRANSACTION = gql`
  query AllUnpaidTransaction {
    allUnpaidTransaction
  }
`;

const CHECK_EXISTED_FACTURE = gql`
  query CheckExistedFacture($startDate: DateTime, $endDate: DateTime) {
    checkExistedFacture(startDate: $startDate, endDate: $endDate)
  }
`;

const LENGTH_TX_NOT_VIEWED = gql`
  query LengthTxNotViewed {
    lengthTxNotViewed
  }
`;

export {
  DOWNLOAD_FACTURE,
  TRANSACTION,
  SUM_MEMBERS_TRANSACTIONS,
  SUM_TRANSACTIONS_BY_CATEGORIES,
  SUM_TRANSACTIONS_BY_MODALITY_TYPE,
  SUM_TRANSACTIONS_BY_MODALITY,
  ALL_TRANSACTIONS,
  TRANSACTION_BENEFICIARY,
  TRANSACTION_TIMER,
  SUM_ALL_CARD_TRANSACTIONS_BY_CATEGORY,
  CURRENT_SERVER_DATE,
  ALL_UNPAID_TRANSACTION,
  CHECK_EXISTED_FACTURE,
  SUM_AND_GROUP_ALL_COMPANY_TRANSACTIONS,
  LENGTH_TX_NOT_VIEWED,
};
