import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const Cheque = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g id="banker" transform="translate(0 -0.219)">
        <g
          id="Groupe_12079"
          data-name="Groupe 12079"
          transform="translate(0 9.539)"
        >
          <g id="Groupe_12078" data-name="Groupe 12078">
            <path
              id="Tracé_896"
              data-name="Tracé 896"
              d="M24.1,180.826H22.551l-4.649,4.65a.776.776,0,0,1-.2.145l-4.6,2.3a2.322,2.322,0,0,1-3.115-3.115l1.99-3.982H2.322A2.324,2.324,0,0,0,0,183.148v12.435A2.324,2.324,0,0,0,2.322,197.9H24.1a2.324,2.324,0,0,0,2.322-2.322V183.148A2.324,2.324,0,0,0,24.1,180.826ZM3.87,187.018h4.7a.774.774,0,0,1,0,1.548H3.87a.774.774,0,0,1,0-1.548Zm18.678,7.791H3.87a.774.774,0,1,1,0-1.548H22.548a.774.774,0,1,1,0,1.548Zm0-3.1H3.87a.774.774,0,1,1,0-1.548H22.548a.774.774,0,1,1,0,1.548Z"
              transform="translate(0 -180.826)"
            />
          </g>
        </g>
        <g
          id="Groupe_12081"
          data-name="Groupe 12081"
          transform="translate(21.91 0.219)"
        >
          <g id="Groupe_12080" data-name="Groupe 12080">
            <path
              id="Tracé_897"
              data-name="Tracé 897"
              d="M428.416.877a2.378,2.378,0,0,0-3.283,0l-.547.547,3.284,3.283.547-.547a2.322,2.322,0,0,0,0-3.284Z"
              transform="translate(-424.585 -0.219)"
            />
          </g>
        </g>
        <g
          id="Groupe_12083"
          data-name="Groupe 12083"
          transform="translate(11.285 10.333)"
        >
          <g id="Groupe_12082" data-name="Groupe 12082">
            <path
              id="Tracé_898"
              data-name="Tracé 898"
              d="M220.709,196.2l-1.939,3.88a.774.774,0,0,0,1.038,1.039l3.88-1.939Z"
              transform="translate(-218.688 -196.204)"
            />
          </g>
        </g>
        <g
          id="Groupe_12085"
          data-name="Groupe 12085"
          transform="translate(14.248 2.519)"
        >
          <g id="Groupe_12084" data-name="Groupe 12084">
            <path
              id="Tracé_899"
              data-name="Tracé 899"
              d="M282.673,44.778l-6.568,6.567,3.284,3.284,6.568-6.567Z"
              transform="translate(-276.105 -44.778)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Cheque;
