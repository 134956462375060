import { useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import { ON_BALANCE_UPDATED } from '../../../../../graphql/card/subscription';
import { OnBalanceUpdated } from '../../../../../graphql/card/types/OnBalanceUpdated';
import { DashboardItem, _dashboards } from '../../../../../indexDB/data';
import { setAccount } from '../../../../../redux/account/accountSlice';
import { useAppDispatch } from '../../../../../redux/hook';
import { fetcherWEB } from '../../../../../apolloClient';
import { WalletBalance } from '../../../../../graphql/graphql_transaction/types/WalletBalance';
import { WALLET_BALANCE } from '../../../../../graphql/graphql_transaction/query';
import { API_TRANSACTION } from '../../../../../config';

export interface IAccount {
  payin?: string;
  payout?: string;
  solde?: string;
}

export const useGetBalance = (idCompany?: string) => {
  const dispatch = useAppDispatch();
  useSubscription<OnBalanceUpdated>(ON_BALANCE_UPDATED, {
    variables: {
      companyId: idCompany || '',
    },
    onSubscriptionData: () => {
      GetBalanceWallet();
    },
  });

  const GetBalanceWallet = () => {
    fetcherWEB<null, WalletBalance>(WALLET_BALANCE, null, API_TRANSACTION).then(
      async (data) => {
        if (data?.walletBalance) {
          const { authorizedBalance, transactionOut, transactionIn } =
            data.walletBalance;
          const result = {
            solde: authorizedBalance || '-',
            payout: transactionOut || '-',
            payin: transactionIn || '-',
          };
          dispatch(setAccount({ ...result }));
          await _dashboards.setItem(
            DashboardItem.MY_BALANCE,
            JSON.stringify({ ...result }),
          );
        }
      },
    );
  };

  useEffect(() => {
    if (idCompany) getData();
  }, [idCompany]);

  const getData = async () => {
    const _cacheBalance = await _dashboards.getItem(DashboardItem.MY_BALANCE);
    if (_cacheBalance) {
      const parsedBalance = JSON.parse(_cacheBalance as string);
      const _data: IAccount = {};
      _data.solde = parsedBalance.solde;
      _data.payin = parsedBalance.payin;
      _data.payout = parsedBalance.payout;
      dispatch(
        setAccount({
          ..._data,
        }),
      );
    }
    GetBalanceWallet();
  };
  return { getData };
};
