import { useApolloClient, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { GET_COMPANY_ROLES_TEAMS } from '../../../graphql/company/query';
import { CompanyRolesTeams } from '../../../graphql/company/types/CompanyRolesTeams';
import { TeamItem, _teams } from '../../../indexDB/data';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';
import { ISetTeam, setTeam } from '../../../redux/team/teamSlice';
import { MESSAGE_TYPE } from '../../../utils/constant';
import { displaySnackBar } from '../../../utils/snackBarUtils';

export const useSetTeamData = (isSynchronize?: boolean) => {
  const client = useApolloClient();
  const intl = useIntl();

  const onGetCompanyError = () => {
    displaySnackBar(client, {
      type: MESSAGE_TYPE.ERROR,
      message: intl.formatMessage({ id: 'company.getInfo.error' }),
      isOpen: true,
    });
  };

  const [getRoles, { loading: rolesLoading }] = useLazyQuery<CompanyRolesTeams>(
    GET_COMPANY_ROLES_TEAMS,
    {
      onCompleted: async (data) => {
        if (data?.myCompany?.roles && data?.myCompany?.teams) {
          const roles = JSON.stringify(data.myCompany.roles);
          const teams = JSON.stringify(data.myCompany.teams);
          await _teams.setItem(TeamItem.ROLES, roles);
          await _teams.setItem(TeamItem.TEAMS, teams);
        }
      },
      onError: () => onGetCompanyError(),
    },
  );

  const getData = async () => {
    const _cacheRoles = await _teams.getItem(TeamItem.ROLES);
    const _cacheTeams = await _teams.getItem(TeamItem.TEAMS);
    if (!_cacheRoles || !_cacheTeams) {
      getRoles();
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return { loading: rolesLoading };
};

export const useGetTeamData = () => {
  const dispatch = useAppDispatch();
  const { companyRolesTeams, teams } = useAppSelector(
    (state) => state.team.header,
  );

  useEffect(() => {
    getDataTeams();
  }, []);

  const getDataTeams = async () => {
    const _cacheRoles = await _teams.getItem(TeamItem.ROLES);
    const _cacheTeams = await _teams.getItem(TeamItem.TEAMS);
    let _data: ISetTeam = {};
    if (_cacheRoles) {
      _data.companyRolesTeams = _cacheRoles as string;
    }
    if (_cacheTeams) {
      _data.teams = _cacheTeams as string;
    }
    dispatch(setTeam(_data));
  };
  return { companyRolesTeams, teams };
};
