import { START_OFFER_PRICE } from '../../../../utils/constant';

export default {
  'error.pinNotComplex':
    'Please enter a PIN code with 4 non-consecutive and non-repetitive digits',
  'error.getLeaderShareholders':
    'Error while getting company leader and shareholders informations',
  'error.getMembers':
    'Error while getting company leader and members informations',
  'text.cmcStep1.commandMasterCard': `Order your Sapheer card now!`,

  'text.cmcStep1.deliverMasterCard': `In the current context of the fight against bank card fraud, and to avoid any attempt to do so, we are putting in place verification mechanisms to ensure that we have the right to verify the authenticity of the transactions.
  We use payment methods to ensure the security of our customers' accounts.`,
  'text.cmcStep1.offerMasterCard': `Please pay by card for the first month of your package if you have subscribed to the Smart offer.
  For customers who have subscribed to the free Start offer, a one-off payment of ${START_OFFER_PRICE}€ excluding VAT for the physical card is required.`,

  'text.cmcStep1.payOnlineAsStore': `Pay online as in store`,
  'text.cmcStep1.masterCardAcceptedInFrance': `Your Sapheer Mastercard is accepted everywhere in France, online or in store.`,
  'text.cmcStep1.manageRealtime': `Manage everything in real time!`,

  'text.cmcStep1.paymentCaps': `Adjust your payment, online or withdrawal limits.`,
  'text.cmcStep1.paymentCaps2': `Activate or deactivate your card and even the contactless card.`,

  'text.cmcStep2.whereToShip': `Where would you like to send your card?`,
  'text.cmcStep2.address': `Where do you want to ship your card?`,
  'text.cmcStep2.addressToShip': `Select the address you want to receive your FREE Credit Card. You will receive your card within 5 working days after checking your account.`,
  'text.cmcStep3.setPin': `Set the PIN code of your Credit Card`,
  'text.cmcStep3.rememberPin': `Remember this PIN code. You will be asked for any payment by card with code, or to withdraw money from the distributors.`,
  'text.cmcStep3.4digitPin': `4 digit PIN code`,
  'text.cmcStep3.confirmPin': `Confirm PIN code`,
  'text.cmcStep4.commandRegistered': `Your order is registered!`,
  'text.cmcStep4.receiveAfter3days': `You will normally receive your card within 2 to 5 days depending on your location.`,
  'text.cmcTypeCard.title': 'Payment of your card',
  'text.cmcTypeCard.paragraph':
    'Pay monthly or at once for your Standard card.',
  'text.cmcStep3.card': 'Votre carte',
  'error.cmcStep5.passwordNotMatch': 'Code do not match.',
  'command.card.error': 'Error on command card',
  "command.card.payment.title": "Your card",

  'command.card.payment.subTitle': 'Payment Form',
  'command.card.payment.img': 'Preview of your card',

  'command.card.noTitular': 'Please choose a holder',


  'text.command.title': `For anti-fraud reasons, we set up payment checks to ensure the security of our clients' accounts. 
  In this context, we ask our dear customers to pay by card the first month in order to order their Sapheer physical card :`,
  'text.command.offer.start': `Start (19.99€ HT)`,
  'text.command.offer.smart': `Smart (9.99€ HT)`,
  'text.command.offer.premium': `Premium (19.99€ HT)`,
  'text.command.offer.corporate': `Corporate (199.99€ HT)`,
  'text.command.subttext': 'For the Start offer, the Sapheer Standard physical card is optional.',
  'text.command.subtitle1': `Pay everywhere very easily!`,
  'text.command.subtitle1.detail': `Your Sapheer Business Mastercard is accepted everywhere in Europe online or in store`,
  'text.command.subtitle2': `Manage everything in real time!`,
  'text.command.subtitle2.detail1': `Activate or deactivate your card or the contactless system`,
  'text.command.subtitle2.detail2': `Adjust your payment or withdrawal limits`,
  'text.command.subtitle2.detail3': `Block payments abroad`,
};
