import gql from 'graphql-tag';

export const PERSON_FRAGMENT = gql`
  fragment PersonInfo on Person {
    id
    type
    title
    name
    firstName
    nationality
    birthday
    placeOfBirth
    birthCountry
    birthPostCode
    address
    postCode
    city
    capital
  }
`;
