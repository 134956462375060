import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const Team = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g id="ios-people" transform="translate(-31.5 -110.199)">
        <path
          id="Tracé_894"
          data-name="Tracé 894"
          d="M130.165,123.389c-.658-.235-1.733-.247-2.209-.429a3.121,3.121,0,0,1-1.022-.476,6.317,6.317,0,0,1-.17-1.674,2.7,2.7,0,0,0,.582-.822,9.14,9.14,0,0,0,.282-1.616s.388.165.54-.611c.129-.67.376-1.022.311-1.516s-.341-.376-.341-.376a4.4,4.4,0,0,0,.341-2.2,3.813,3.813,0,0,0-7.6,0,4.465,4.465,0,0,0,.335,2.2s-.276-.118-.341.376.176.846.311,1.516c.153.781.54.611.54.611a9.229,9.229,0,0,0,.282,1.616,2.7,2.7,0,0,0,.582.822,6.316,6.316,0,0,1-.17,1.674,3,3,0,0,1-1.022.47c-.47.182-1.545.206-2.209.441a4.146,4.146,0,0,0-2.691,3.948h16.35A4.138,4.138,0,0,0,130.165,123.389Z"
          transform="translate(-80.006 0)"
        />
        <path
          id="Tracé_895"
          data-name="Tracé 895"
          d="M37.874,166.838a3.988,3.988,0,0,0,1.962-.546c-.911-1.381-.417-2.99-.605-4.494a2.479,2.479,0,0,0-2.744-2.4h-.023a2.486,2.486,0,0,0-2.72,2.4c-.188,1.5.335,3.29-.6,4.494a3.691,3.691,0,0,0,1.968.488,2.975,2.975,0,0,1-.059.987,1.484,1.484,0,0,1-.7.323,13.631,13.631,0,0,0-1.522.423A2.474,2.474,0,0,0,31.5,170.7h4.717a4.22,4.22,0,0,1,1.88-1.751,3.944,3.944,0,0,1,1.451-.305s.223-.353-.511-.488a5.008,5.008,0,0,1-1.128-.394C37.8,167.631,37.874,166.838,37.874,166.838Zm13.63,0a3.988,3.988,0,0,1-1.962-.546c.911-1.381.417-2.99.605-4.494a2.479,2.479,0,0,1,2.744-2.4h.023a2.486,2.486,0,0,1,2.72,2.4c.188,1.5-.335,3.29.6,4.494a3.691,3.691,0,0,1-1.968.488,2.976,2.976,0,0,0,.059.987,1.484,1.484,0,0,0,.7.323,13.632,13.632,0,0,1,1.522.423,2.474,2.474,0,0,1,1.328,2.185H53.16a4.22,4.22,0,0,0-1.88-1.751,3.944,3.944,0,0,0-1.451-.305s-.223-.353.511-.488a5.008,5.008,0,0,0,1.128-.394C51.58,167.631,51.5,166.838,51.5,166.838Z"
          transform="translate(0 -46.31)"
        />
      </g>
    </SvgIcon>
  );
};

export default Team;
