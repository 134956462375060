import gql from 'graphql-tag';

export const CARD_PAN_PHYSICAL_FRAGMENT = gql`
  fragment CardPanPhysicalInfo on Card {
    id
    isPhysical
    maskedPan
    account {
      id
      person {
        id
        name
        firstName
      }
    }
  }
`;
