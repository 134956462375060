import gql from 'graphql-tag';

export const GET_SNACKBAR_STATE = gql`
  query SnackBar {
    snackBar @client {
      type
      message
      isOpen
      isLogin
    }
  }
`;

export const IS_ALL_TRANSACTION_PANEL = gql`
  query IsAllTransactionPanel {
    isAllTransactionPanel @client {
      isAll
    }
  }
`;

export const IS_CHEQUE_TAB = gql`
  query IsChequeTab {
    isChequeTab @client {
      isTab
    }
  }
`;

export const CAN_CREATE_TR = gql`
  query CanCreateTr {
    canCreateTr @client {
      date
      total
    }
  }
`;
