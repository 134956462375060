import { displaySnackBar } from '../../../utils/snackBarUtils';
import { MESSAGE_TYPE } from '../../../utils/constant';
import { useIntl } from 'react-intl';
import { useApolloClient } from '@apollo/client';

export const useOpenFile = () => {
  const intl = useIntl();
  const client = useApolloClient();

  const openUrlFile = async (url: string, fileName: string) => {
    if (window) {
      window.open(url, 'popup');
    } else {
      download(
        url,
        intl.formatMessage({
          id: fileName,
        }),
      );
    }
  };

  function download(url: string, filename: string) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(() => {
        displaySnackBar(client, {
          type: MESSAGE_TYPE.ERROR,
          message: intl.formatMessage({
            id: 'download.file.error',
          }),
          isOpen: true,
        });
      });
  }

  return { openUrlFile };
};
