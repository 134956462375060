export default {
  'toolbar.button.addTransaction': 'Créer une transaction',
  'toolbar.input.searchTransaction': 'Rechercher une transaction',
  'toolbar.input.searchBeneficiary': 'Rechercher un bénéficiaire',
  'toolbar.input.calendarTransaction': 'Période',
  'transaction.filter.category': 'Catégorie',
  'transaction.filter.modality': 'Modalité',
  'transaction.filter.type': 'Type',
  'transaction.filter.reccurence': 'Récurrence',
  'transaction.filter.beneficiary': 'Bénéficiaire',
  'transaction.toolbar.today': "Aujourd'hui",
  'transaction.toolbar.lastThreeDay': '3 jours',
  'transaction.toolbar.sevenDay': '7 jours',
  'transaction.toolbar.lastMonth': '1 mois',
  'transaction.toolbar.twoLastMonth': '2 mois',
  'transaction.toolbar.lastThreeMonth': '3 mois',
  'transaction.virement.cancel.success': 'Transaction supprimée avec succès',
  'transaction.filter': 'Filtre',
  'transaction.filter.title': 'Filtrez vos transactions',
  'transaction.filter.paragraph':
    'Visualisez votre historique selon vos critères et préférences.',
  'transaction.tootlbar.title': 'Filtre transaction',
  'transaction.modal.button.initialize': 'Réinitialiser',
  'transaction.modal.button.apply': 'Appliquer',
  'transaction.validate.sussess': 'Transaction exécuté avec succès',
};
