import gql from 'graphql-tag';
import { TEAM_FRAGMENT } from '../team/fragment';
import {
  DOCUMENT_TYPE_FRAGMENT,
  DOCUMENT_FRAGMENT,
  DOCUMENT_INVITE_FRAGMENT,
} from '../document/fragment';

export const PERSON_FRAGMENT = gql`
  fragment personInfo on Person {
    id
    type
    title
    name
    firstName
    nationality
    birthday
    placeOfBirth
    birthCountry
    birthPostCode
    address
    postCode
    city
    capital
  }
`;

export const PERSON_INVITE_FRAGMENT = gql`
  fragment personInviteInfo on Person {
    id
    type
    title
    name
    firstName
    nationality
    birthday
    placeOfBirth
    birthCountry
    birthPostCode
    address
    postCode
    city
    capital
    specifiedUSPerson
    documents {
      ...DocumentInfo
      type {
        ...DocumentTypeInfo
      }
    }
  }
  ${DOCUMENT_INVITE_FRAGMENT}
  ${DOCUMENT_TYPE_FRAGMENT}
`;

export const PERSON_MEMBER_FRAGMENT = gql`
  fragment personMemberInfo on Person {
    id
    name
    firstName
    address
    city
    postCode
  }
`;
export const PERSON_ACCOUNT_FRAGMENT = gql`
  fragment personAccountInfo on PersonAccount {
    id
    email
    password
    status
    type
    existingCompany
    signupProgression
    teams {
      ...teamInfo
    }
    person {
      ...personInfo
    }
  }
  ${TEAM_FRAGMENT}
  ${PERSON_FRAGMENT}
`;

export const PERSON_INVITE_RESULT_FRAGEMENT = gql`
  fragment personInviteResult on Person {
    id
    type
    title
    name
    firstName
    nationality
    birthday
    placeOfBirth
    birthCountry
    birthPostCode
    address
    postCode
    city
    capital
    documents {
      ...DocumentInfo
      type {
        ...DocumentTypeInfo
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${DOCUMENT_TYPE_FRAGMENT}
`;
