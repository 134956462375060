export default {
  'forgot.title': 'Mot de passe oublié',
  'forgot.details':
    'Vous recevrez un lien pour réinitialiser le mot de passe dans votre e-mail',
  'forgot.email.send': 'Merci de consulter vos emails',
  'forgot.button.send': 'Envoyer',
  'email.notValid': `L'email saisi n'est associé à aucun compte`,
  'invite.headTitle': 'Remplissez le formulaire pour créer votre compte',
  'invite.information': 'Vos informations',
  'invite.paragraph.1': "Vous avez été invité à rejoindre l'équipe ",
  'invite.paragraph.2': ' en tant que ',
  'invite.infomrmation': 'Mes information légales',
  'invite.address': 'Mon adresse personnelle',
  'invite.infomrmationAttachment': "Justificatif de pièce d'identité",
  'invite.infomrmationAttachmentType': "Type de pièce d'identité",
  'invite.addressAttachmentType': 'Type de justificatif de domicile',
  'invite.addressAttachment': 'Justificatif de domicile',
  'invite.checkbox': "J'accepte les termes et conditions générales de Sapheer"
};
