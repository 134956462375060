import gql from 'graphql-tag';

const COMPANY_FRAGMENT = gql`
  fragment companyInfo on Company {
    id
    legalName
    slogan
    activity
    legalForm
    legalFormLabel
    legalRegistrationNumber
    legalShareCapital
    legalRegistrationDate
    address
    address2
    postCode
    city
    logoUrl
  }
`;

const COMPANY_BENEFICIARY_FRAGMENT = gql`
  fragment CompanyBeneficiaryInfo on Company {
    id
    legalName
    address
    postCode
    city
    logoUrl
  }
`;
export { COMPANY_FRAGMENT, COMPANY_BENEFICIARY_FRAGMENT };
