import { Box, Typography } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Theme, withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomButton } from '../CustomButton';
import CustomDialog from '../CustomDialog';

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

interface ConfirmationDialogProps {
  title?: string;
  message: string;
  open: boolean;
  closable?: boolean;
  isNotConfirm?: boolean;
  handleAccept(): void;
  handleClose(): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  blockConfirm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minWidth: 350,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  button: {
    padding: '15px 30px 15px 30px',
    fontSize: '.875rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    margin: '10px',
  },
}));

const ConfirmationDialog = (props: ConfirmationDialogProps & DialogProps) => {
  const { title, open, isNotConfirm, message, handleClose, handleAccept } =
    props;
  const classes = useStyles({});
  return (
    <CustomDialog
      dialogTitle={<FormattedMessage id={title ? title : ''} />}
      open={open}
      closable={true}
      handleClose={handleClose}
      maxWidth="md"
      aria-labelledby="contrat-sapheer"
    >
      <DialogContent>
        <Box className={classes.blockConfirm}>
          <Typography align="center" variant="h2">
            {message}
          </Typography>
          <Box
            display="flex"
            justifyContent={isNotConfirm ? 'center' : 'space-between'}
            marginTop={4}
            textAlign="center"
            width={250}
          >
            {isNotConfirm ? (
              <CustomButton
                className={classes.button}
                confirm
                color="primary"
                onClick={handleClose}
              >
                <FormattedMessage id="confirm" />
              </CustomButton>
            ) : (
              <>
                <CustomButton
                  confirm
                  onClick={handleClose}
                  className={classes.button}
                >
                  <FormattedMessage id="dialog.button.cancel" />
                </CustomButton>
                <CustomButton
                  confirm
                  onClick={handleAccept}
                  color="primary"
                  className={classes.button}
                >
                  <FormattedMessage id="confirm" />
                </CustomButton>
              </>
            )}
          </Box>
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};

export default ConfirmationDialog;
