import { Box, Typography, useMediaQuery } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import classname from 'classnames';
import React, { FC, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useTheme } from '../../../../App';
import { arrondi } from '../../../../utils/common';
import { VIEW } from '../../../../utils/constant';
import Notification from '../../../Notification';
import AppBarProfile from '../../AppBarProfile';
import MemberName from '../../MemberName';
import ShowTransaction from '../../ShowTransaction/ShowTransaction';
import styles from './style';
import SwitchMode from './SwitchMode/SwitchMode';
import { setPriceLocale } from '../../../../services/Locale';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/hook';
import { getThemeTypeStorage } from '../../../../services/LocalStorage';
import { useGetBalance } from './AppBarData/AppBarData';

const Header: FC<{ idCompany: string }> = (props) => {
  const { idCompany } = props;
  let viewHeader = false;
  const classes = styles({});
  const balance = useAppSelector((state) => state.account.header);
  const themeStorage = getThemeTypeStorage() === VIEW.LIGHT ? false : true;
  const [themeCheked, setThemeChecked] = useState(themeStorage);
  const themeToggle = useTheme();
  const isFullWidth = useMediaQuery('(min-width:1601px)');
  const history = useLocation();
  useGetBalance(idCompany);
  const isSetting = history.pathname === '/setting';
  const isService =
    history.pathname === '/service' || history.pathname === '/cheque';

  const handleChange = () => {
    setThemeChecked((prev) => !prev);
    themeToggle.toggle();
  };

  if (isSetting) {
    viewHeader = true;
  }
  return (
    <Box>
      {!isService && (
        <AppBar
          className={classname(
            classes.appBar,
            isFullWidth ? classes.fullWidth : {},
          )}
          position="fixed"
          color="default"
        >
          <Toolbar className={!viewHeader ? classes.toolBar : classes.ktoolbar}>
            {!viewHeader && (
              <Box display="flex" alignItems="center" flexGrow={1}>
                <Box marginRight={3}>
                  <Box display="flex" justifyContent="flex-end">
                    <Typography variant="h2" className={classes.companyName}>
                      <FormattedMessage id="greeting" />
                      <MemberName />
                    </Typography>
                  </Box>
                  <Typography>
                    <FormattedDate
                      value={new Date()}
                      year="numeric"
                      month="long"
                      day="2-digit"
                    />
                  </Typography>
                </Box>
                <Box flexBasis="83%" display="flex" textAlign="center">
                  {balance?.solde.toString() &&
                    balance?.payin.toString() &&
                    balance?.payout.toString() && (
                      <>
                        <ShowTransaction
                          type={'Solde'}
                          price={
                            isNaN(parseFloat(balance.solde))
                              ? '-'
                              : setPriceLocale(
                                  arrondi(parseFloat(balance.solde)),
                                )
                          }
                          color="#329AEC"
                        />
                        <ShowTransaction
                          type={'Entrant'}
                          price={
                            isNaN(parseFloat(balance.payin))
                              ? '-'
                              : setPriceLocale(
                                  arrondi(parseFloat(balance.payin)),
                                )
                          }
                          color="#4CAF50"
                        />
                        <ShowTransaction
                          type={'Sortant'}
                          price={
                            isNaN(parseFloat(balance.payout))
                              ? '-'
                              : setPriceLocale(
                                  arrondi(parseFloat(balance.payout)),
                                )
                          }
                          color="#FB4766"
                        />
                      </>
                    )}
                </Box>
              </Box>
            )}
            <Box className={classes.headerSetting}>
              <SwitchMode checked={themeCheked} handleChange={handleChange} />
              <Notification />
              <AppBarProfile />
            </Box>
          </Toolbar>
        </AppBar>
      )}
    </Box>
  );
};
export default Header;
