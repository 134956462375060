import { Badge, Box, makeStyles, Typography } from '@material-ui/core';
import className from 'classnames';
import React from 'react';
import { FormattedDate } from 'react-intl';

const styles = makeStyles(theme => ({
  badge: {
    display: 'flex',
    '& .MuiBadge-anchorOriginTopLeftRectangle': {
      top: 3,
      left: 3,
    },
  },
  entriesRoot: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: 6,
    border: '2px solid transparent',
    cursor: 'pointer',
    '& svg': {
      marginRight: theme.spacing(1),
      fontSize: 14,
    },
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
    '&>div:last-child': {
      border: 'none !important',
    },
  },
  entriesTitle: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  entriesContent: {
    padding: theme.spacing(1),
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    border: `1px solid ${theme.palette.text.secondary}`,
    borderTopWidth: 0,
  },
}));

interface INotificationEntries {
  icon: JSX.Element;
  title: string;
  content: string;
  date: Date;
  viewed: boolean;
  isSelected: boolean;
  notificationId: string;
  handleSelect: (selectedId: string) => void;
}

export default function NotificationEntries(props: INotificationEntries) {
  const classes = styles();
  const {
    icon,
    content,
    date,
    title,
    viewed,
    isSelected,
    notificationId,
    handleSelect,
  } = props;
  const handleClick = () => {
    handleSelect(notificationId);
  };

  return (
    <Badge
      color="secondary"
      className={classes.badge}
      variant="dot"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      invisible={viewed}
    >
      <Box
        onClick={handleClick}
        className={className(
          classes.entriesRoot,
          isSelected ? classes.selected : '',
        )}
      >
        <Box className={classes.entriesTitle}>
          {icon}
          <Typography variant="body2">{title}</Typography>
          <Box marginLeft="auto">
            <FormattedDate
              value={date}
              year="numeric"
              month="long"
              day="2-digit"
            />
          </Box>
        </Box>
        <Box className={classes.entriesContent}>
          <Typography variant="body2">{content}</Typography>
        </Box>
      </Box>
    </Badge>
  );
}
