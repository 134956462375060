export default {
  URL_EMPTY: `Error when downloading avatar`,
  MEMBER_LIMIT_EXCEEDED: `You reach the number of member limit for your offer.`,
  EMAIL_EXIST: `This email address already exist.`,
  ACCOUNT_NOT_FOUND: `Account not found`,
  ACCOUNT_DISABLED: `Your account is disable or not been validated.Please contact your administrator`,
  SELF_TRANSFERT_TRANSACTION: `Self transfert is not authorized, please check the IBAN.`,
  BENEFICIARY_ALREADY_EXIST: `This beneficiary is already exist.`,
  REQUIRED_INPUTS_ERRORS: 'Please fullfil all needed informations.',
  TRANSACTION_CANNOT_CANCELED: `This transaction can no longer be reversed because the deadline has passed.`,
  USER_CANT_CREATE_TRANSACTION: `You do not have authorization to create a transaction.`,
  USER_CANT_VALID_TRANSACTION: `You do not have authorization to validate a transaction.`,
  ACCEPT_INVITATION_AGAIN: 'You have already accept invitation.',
  STANDARD_CARD_ONLY_FOR_START_OFFER: `Your standart card is only for Start offer`,

  ACTIVATION_CARD_ERROR: `Card activation error`,
  CARD_3DS_ERRORS: ' 3DS Card error',
  TREEZOR_CREATION_CARD_ERROR: 'Error when creating card',
  ACCOUNT_NEED_TO_VALIDATE: `Your account need to be validate`,
  TREEZOR_CONVERSION_CARD_ERROR: `Error when converting virtual card to physical card`,

  TREEZOR_CARD_NOT_FOUND: `Treezor card not found`,
  CARD_ALREADY_ACTIVATED: `Card already activated`,
  LOCK_UNBLOCK_CARD_EROR: `Switch activation card option error.`,
  TREEZOR_UPDATE_CARD_OPTION_ERROR: `Switch  card option error.`,

  PHONE_NUMBER_REQUIRED: `The phone number is required`,
  CODE_INVALID: `This code is invalid`,
  SETTING_PIN_ERROR: `Error when implementing pin code`,
  CARD_NOT_FOUND: `Card not found in server`,
  BENEFICIARY_IBAN_ERROR: `Beneficiary iban error`,
  BIG_AMOUNT_MUST_HAVE_AN_ATTACHMENT: `The supporting document is mandatory for an amount > 10.000€.`,
  PIN_NOT_MATCH: `Pin not match`,
};
