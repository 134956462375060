import { useApolloClient, useSubscription } from '@apollo/client';
import React from 'react';
import { CHEQUE, CHEQUES } from '../../../../graphql/cheque/query';
import { ON_CHEQUE_UPDATE } from '../../../../graphql/cheque/subscription';
import {
  Cheque,
  ChequeVariables,
} from '../../../../graphql/cheque/types/Cheque';
import {
  Cheques,
  ChequesVariables,
} from '../../../../graphql/cheque/types/Cheques';
import {
  OnChequeUpdate,
  OnChequeUpdateVariables,
} from '../../../../graphql/cheque/types/OnChequeUpdate';
import { DEFAULT_CHEQUE_LIST_PARAMS } from '../../../Cheque/ChequeLogic';

interface ChequeSubProps {
  companyId: string;
  size: number | null;
}

const ChequeSub = (props: ChequeSubProps) => {
  const { companyId, size } = props;
  const client = useApolloClient();

  useSubscription<OnChequeUpdate, OnChequeUpdateVariables>(ON_CHEQUE_UPDATE, {
    variables: { companyId },
    client,
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data && data.onChequeUpdate && data.onChequeUpdate.id) {
        const existedCheque = data.onChequeUpdate;
        // Update automatically current list cheque
        const currentCheques = client.readQuery<Cheques, ChequesVariables>({
          query: CHEQUES,
          variables: {
            filter: { ...DEFAULT_CHEQUE_LIST_PARAMS, size },
          },
        });
        if (
          currentCheques &&
          currentCheques.cheques &&
          currentCheques.cheques.cheques
        ) {
          let currentTrs = currentCheques.cheques.cheques;
          client.writeQuery<Cheques, ChequesVariables>({
            query: CHEQUES,
            variables: {
              filter: { ...DEFAULT_CHEQUE_LIST_PARAMS, size },
            },
            data: {
              cheques: {
                ...currentCheques.cheques,
                cheques: [
                  ...currentTrs.map((ch) => {
                    if (
                      ch &&
                      ch.id &&
                      existedCheque &&
                      existedCheque.id &&
                      ch.id == existedCheque.id
                    ) {
                      return existedCheque;
                    }
                    return ch;
                  }),
                ],
              },
            },
          });
        }

        // Update automaticaly current details cheque
        const currentCheque = client.readQuery<Cheque, ChequeVariables>({
          query: CHEQUE,
          variables: { id: data.onChequeUpdate.id },
        });
        if (currentCheque && currentCheque.cheque && currentCheque.cheque.id) {
          client.writeQuery<Cheque, ChequeVariables>({
            query: CHEQUE,
            variables: { id: data.onChequeUpdate.id },
            data: {
              ...currentCheque,
              cheque: { ...existedCheque },
            },
          });
        }
      }
    },
  });

  return <></>;
};

export default ChequeSub;
