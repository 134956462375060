import { ApolloClient } from '@apollo/client';
import { Cheque_cheque } from '../../graphql/cheque/types/Cheque';
import { IS_CHEQUE_TAB } from '../../graphql/Common/snackbar/query';
export interface IChequeCreate {
  key: string;
  cmc7a: string;
  cmc7b: string;
  cmc7c: string;
  amount: string;
  debitorName: string;
  createdDate: Date;
  isNaturalPerson: boolean;
}

export interface IChequeFilter {
  startDate: Date | null;
  endDate: Date | null;
  payinStatus: string | undefined;
}

export interface IChequeInputList {
  page: number | null;
  size: number | null;
  totalDay: number | null;
  startDate: any | null;
  endDate: any | null;
  payinStatus: string | null;
  search: string | null;
}

export const VIEW_TABLE_DEFAULT_VIEW = 10;

export const DEFAULT_CHEQUE_LIST_PARAMS: IChequeInputList = {
  page: 1,
  size: 25,
  totalDay: null,
  startDate: null,
  endDate: null,
  payinStatus: null,
  search: null,
};

export const initCheque: IChequeCreate = {
  key: '',
  cmc7a: '',
  cmc7b: '',
  cmc7c: '',
  amount: '',
  debitorName: '',
  createdDate: new Date(),
  isNaturalPerson: true,
};

export const initFilter: IChequeFilter = {
  startDate: null,
  endDate: null,
  payinStatus: undefined,
};

export const CHEQUE_TYPE = {
  PERSON: 'PERSON',
  COMPANY: 'COMPANY',
};

export const MAX_AMOUNT = 10000;

export const CMC7 = {
  ALENGTH: 6,
  BLENGTH: 13,
  CLENGTH: 11,
};

export const CMC7_LENGTH = 30;

export enum CHEQUE_STATUS {
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  VALIDATED = 'VALIDATED',
}

export interface IChequePaginate {
  page: number;
  size: number;
  totalPage: number;
  totalElements: number;
}

export const getCmc7Info = (cmc7: string | null) => {
  if (!cmc7) {
    return { cmc7a: '-', cmc7b: '', cmc7c: '' };
  }
  return {
    cmc7a: cmc7.substring(0, 5),
    cmc7b: cmc7.substring(6, 19),
    cmc7c: cmc7.substring(20),
  };
};

export const getAvatarName = (name: string): string => {
  if (!name) {
    return '-';
  }
  const words = wordCounter(name);
  if (words.length == 1) {
    return name.substr(0, 2).toUpperCase();
  }
  if (1 < words.length) {
    return `${words[0].substr(0, 1)}${words[1].substr(0, 1)}`.toUpperCase();
  }
  return '-';
};

export const wordCounter = (str: string) => {
  return str.split(' ');
};

export const toggleChequeTabView = (client: ApolloClient<any>, data: any) => {
  client.writeQuery({
    query: IS_CHEQUE_TAB,
    data: {
      isChequeTab: { ...data, __typename: 'IsChequeTab' },
    },
  });
};
