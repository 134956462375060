import gql from 'graphql-tag';
import { ACCOUNT_NOTIF_FRAGMENT } from './fragment';

export const ACCOUNT_NOTIFICATIONS = gql`
  query AccountNotifications($page: Int, $perPage: Int) {
    getAccountNotifications(page: $page, perPage: $perPage) {
      ...AccountNotificationInfo
    }
  }
  ${ACCOUNT_NOTIF_FRAGMENT}
`;

export const GET_NOTIFICATION_FILE_URL = gql`
  query GetNotifSignedUrl($filename: String!, $uuid: String!) {
    generateSignedPreviewUrl(filename: $filename, uuid: $uuid)
  }
`;
