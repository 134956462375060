import gql from 'graphql-tag';
import { CATEGORY_TRANSACTION_FRAGMENT } from '../category/fragment';
import { BENEFICIARY_TRANSACTION_FRAGMENT } from '../beneficiary/fragment';
import { RECURRENCE_TRANSACTION_FRAGMENT } from '../recurrence/fragment';
import { ACCOUNT_TRANSACTION_FRAGMENT } from '../account/fragment';
import { CARD_PAN_PHYSICAL_FRAGMENT } from '../card/fragment';

const ATTACHEMENT_TRANSACTION_FRAGMENT = gql`
  fragment AttachmentTransactionInfo on Attachment {
    id
    uuid
    filename
    filetype
    location
    longitude
    latitude
  }
`;

const TRANSACTION_FRAGMENT = gql`
  fragment TransactionInfo on Transaction {
    id
    title
    dateTransaction
    executionDate
    amount
    currency
    modality
    source
    modalityType
    transactionState
    transactionType
    treezorTransactionId
    note
    ref
    timer
    category {
      ...CategoryTransactionInfo
    }
    beneficiary {
      ...BeneficiaryTransactionInfo
    }
    recurrence {
      ...RecurrenceTransactionInfo
    }
  }
  ${CATEGORY_TRANSACTION_FRAGMENT}
  ${BENEFICIARY_TRANSACTION_FRAGMENT}
  ${RECURRENCE_TRANSACTION_FRAGMENT}
`;

const TRANSACTION_DETAILS_FRAGMENT = gql`
  fragment TransactionDetailsInfo on Transaction {
    id
    title
    dateTransaction
    executionDate
    amount
    currency
    source
    modality
    modalityType
    transactionState
    transactionType
    treezorTransactionId
    note
    ref
    timer
    walletBeneficiaryId
    wallet {
      id
      sapheerBank {
        id
        company {
          id
          legalName
          logoUrl
        }
      }
    }
    account {
      ...AccountTransactionInfo
    }
    category {
      ...CategoryTransactionInfo
    }
    beneficiary {
      ...BeneficiaryTransactionInfo
    }
    recurrence {
      ...RecurrenceTransactionInfo
    }
    attachments {
      ...AttachmentTransactionInfo
    }
    card {
      ...CardPanPhysicalInfo
    }
    viewed
  }
  ${CATEGORY_TRANSACTION_FRAGMENT}
  ${BENEFICIARY_TRANSACTION_FRAGMENT}
  ${RECURRENCE_TRANSACTION_FRAGMENT}
  ${ATTACHEMENT_TRANSACTION_FRAGMENT}
  ${ACCOUNT_TRANSACTION_FRAGMENT}
  ${CARD_PAN_PHYSICAL_FRAGMENT}
`;
const COMPANY_LAST_TRANSACTIONS_FRAGMENT = gql`
  fragment CompanyLastTransactionsInfo on Transaction {
    id
    title
    dateTransaction
    executionDate
    amount
    currency
    modality
    modalityType
    source
    note
    transactionState
    transactionType
    treezorTransactionId
    ref
    timer
    walletBeneficiaryId
    account {
      id
      company {
        id
        legalName
      }
    }
    beneficiary {
      ...BeneficiaryTransactionInfo
    }
    category {
      ...CategoryTransactionInfo
    }
    recurrence {
      ...RecurrenceTransactionInfo
    }
    attachments {
      ...AttachmentTransactionInfo
    }
    card {
      ...CardPanPhysicalInfo
    }
    viewed
  }
  ${BENEFICIARY_TRANSACTION_FRAGMENT}
  ${CATEGORY_TRANSACTION_FRAGMENT}
  ${RECURRENCE_TRANSACTION_FRAGMENT}
  ${CARD_PAN_PHYSICAL_FRAGMENT}
  ${ATTACHEMENT_TRANSACTION_FRAGMENT}
`;

const TRANSACTION_COMPANY_FRAGMENT = gql`
  fragment TransactionCompanyInfo on Transaction {
    id
    title
    dateTransaction
    executionDate
    amount
    currency
    modality
    source
    modalityType
    transactionState
    treezorTransactionId
    note
    ref
    timer
    transactionType
    account {
      id
      company {
        id
        legalName
      }
    }
    beneficiary {
      ...BeneficiaryTransactionInfo
    }
    category {
      ...CategoryTransactionInfo
    }
    recurrence {
      ...RecurrenceTransactionInfo
    }
    viewed
  }
  ${BENEFICIARY_TRANSACTION_FRAGMENT}
  ${CATEGORY_TRANSACTION_FRAGMENT}
  ${RECURRENCE_TRANSACTION_FRAGMENT}
`;

const TRANSACTION_CARD_FRAGMENT = gql`
  fragment TransactionCardInfo on Transaction {
    id
    title
    dateTransaction
    executionDate
    amount
    currency
    modality
    modalityType
    source
    transactionState
    treezorTransactionId
    note
    ref
    timer
    transactionType
    account {
      id
      company {
        id
        legalName
      }
    }
    beneficiary {
      ...BeneficiaryTransactionInfo
    }
    category {
      ...CategoryTransactionInfo
    }
    recurrence {
      ...RecurrenceTransactionInfo
    }
    viewed
  }
  ${BENEFICIARY_TRANSACTION_FRAGMENT}
  ${CATEGORY_TRANSACTION_FRAGMENT}
  ${RECURRENCE_TRANSACTION_FRAGMENT}
`;
const TRANSACTION_BENEFICIARY_FRAGMENT = gql`
  fragment TransactionBeneficiaryInfo on Transaction {
    id
    dateTransaction
    amount

    currency
    modalityType
  }
`;

const TRANSACTION_BY_DATE_FRAGMENT = gql`
  fragment TransactionByDateInfo on TransactionByDate {
    dateTransaction

    total
  }
`;

const TRANSACTION_BY_MEMBERS_FRAGMENT = gql`
  fragment TransactionByMembersInfo on TransactionByMembers {
    accountId
    categoryId

    total
    currency
  }
`;

const TRANSACTION_STATISTIC_FRAGMENT = gql`
  fragment TransactionStatisticInfo on TransactionStatistic {
    accountId
    categoryId

    total
    currency
  }
`;

const TRANSACTION_CARD_STATISTIC_FRAGMENT = gql`
  fragment TransactionCardStatisticInfo on TransactionStatistic {
    accountId
    categoryId

    total
    currency
  }
`;

const TRANSACTION_BY_MODALITY_TYPE_FRAGMENT = gql`
  fragment TransactionByModalityTypeInfo on TransactionByModalityType {
    modalityType
    total

    currency
  }
`;

const TRANSACTION_FLUX_FRAGMENT = gql`
  fragment TransactionFluxInfo on TransactionFlux {
    modality
    total

    currency
  }
`;

const LAST_TRANSACTION_CARD_FRAGMENT = gql`
  fragment LastTransactionCardInfo on Transaction {
    id
    title
    dateTransaction
    executionDate
    amount
    currency
    modality
    modalityType
    source
    transactionState
    treezorTransactionId
    note
    ref
    timer
    transactionType
    beneficiary {
      ...BeneficiaryTransactionInfo
    }
    recurrence {
      ...RecurrenceTransactionInfo
    }
    category {
      ...CategoryTransactionInfo
    }
    account {
      id
      person {
        id
        name
        firstName
      }
    }
    card {
      ...CardPanPhysicalInfo
    }
    viewed
  }
  ${BENEFICIARY_TRANSACTION_FRAGMENT}
  ${RECURRENCE_TRANSACTION_FRAGMENT}
  ${CATEGORY_TRANSACTION_FRAGMENT}
  ${CARD_PAN_PHYSICAL_FRAGMENT}
`;

export {
  TRANSACTION_FRAGMENT,
  TRANSACTION_COMPANY_FRAGMENT,
  TRANSACTION_DETAILS_FRAGMENT,
  ATTACHEMENT_TRANSACTION_FRAGMENT,
  TRANSACTION_BY_DATE_FRAGMENT,
  TRANSACTION_BY_MEMBERS_FRAGMENT,
  TRANSACTION_STATISTIC_FRAGMENT,
  TRANSACTION_BY_MODALITY_TYPE_FRAGMENT,
  TRANSACTION_FLUX_FRAGMENT,
  TRANSACTION_BENEFICIARY_FRAGMENT,
  LAST_TRANSACTION_CARD_FRAGMENT,
  TRANSACTION_CARD_FRAGMENT,
  COMPANY_LAST_TRANSACTIONS_FRAGMENT,
  TRANSACTION_CARD_STATISTIC_FRAGMENT,
};
