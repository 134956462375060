import gql from 'graphql-tag';
import { BENEFICIARY_TRANSACTION_FRAGMENT } from './fragment_beneficiary';
import { CARD_PAN_PHYSICAL_FRAGMENT } from './fragment_card';
import { CATEGORY_TRANSACTION_FRAGMENT } from './fragment_category';
import { RECURRENCE_TRANSACTION_FRAGMENT } from './fragment_recurrence';

const TRANSACTION_CARD_FRAGMENT = gql`
  fragment TransactionCardInfo on Transaction {
    id
    title
    dateTransaction
    executionDate
    amount
    currency
    modality
    modalityType
    source
    transactionState
    treezorTransactionId
    note
    ref
    timer
    transactionType
    beneficiary {
      ...BeneficiaryTransactionInfo
    }
    recurrence {
      ...RecurrenceTransactionInfo
    }
    category {
      ...CategoryTransactionInfo
    }
    account {
      id
      person {
        id
        name
        firstName
        company {
          legalName
        }
      }
    }
    card {
      ...CardPanPhysicalInfo
    }
    viewed
  }
  ${BENEFICIARY_TRANSACTION_FRAGMENT}
  ${RECURRENCE_TRANSACTION_FRAGMENT}
  ${CATEGORY_TRANSACTION_FRAGMENT}
  ${CARD_PAN_PHYSICAL_FRAGMENT}
`;

export { TRANSACTION_CARD_FRAGMENT };
