import { ApolloError, useApolloClient, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { GET_MY_ROLE_RIGHTS } from '../../../graphql/account/query';
import { MyRoleRights } from '../../../graphql/account/types/MyRoleRights';
import { CompanyItem, _company, _dashboards } from '../../../indexDB/data';
import { ISetCard, setCard } from '../../../redux/card/cardSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';
import { defaultErrorHandler } from '../../../utils/snackBarUtils';

export const useSetData = (isSynchronize?: boolean) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const client = useApolloClient();

  const [dataCard, setDataCard] = useState<ISetCard>({
    cardLimitData: '',
    companyData: '',
    myOfferData: '',
    dataCardList: '',
    roleRights: '',
  });

  const getData = async () => {
    const _card: ISetCard = {};
    const _cacheRoles = await _company.getItem(CompanyItem.ROLES_RIGHTS);
    if (_cacheRoles) {
      _card.roleRights = _cacheRoles as string;
    }

    if (_card && _card?.roleRights) {
      dispatch(setCard({ ..._card }));
    }
    if (!_cacheRoles) {
      getRoles();
    }
    setDataCard(_card);
  };

  const [getRoles, { loading: rolesLoading }] = useLazyQuery<MyRoleRights>(
    GET_MY_ROLE_RIGHTS,
    {
      onCompleted: async (result) => {
        if (result?.me?.roleRights) {
          const data = JSON.stringify(result);
          setDataCard((prev) => ({ ...prev, roleRights: data }));
          await _company.setItem(CompanyItem.ROLES_RIGHTS, data);
        }
      },
      onError: (error: ApolloError) =>
        defaultErrorHandler(client, error, intl, 'error.getRoleRights'),
    },
  );

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (dataCard && dataCard?.roleRights) {
      dispatch(setCard({ ...dataCard }));
    }
  }, [dataCard]);

  return {
    rolesLoading,
    loading: rolesLoading,
  };
};

export const useGetData = () => {
  const dispatch = useAppDispatch();
  const { companyData, roleRights } = useAppSelector(
    (state) => state.card.header,
  );

  useEffect(() => {
    getDataCards();
  }, []);

  const getDataCards = async () => {
    const _card: ISetCard = {};

    const _cacheRoles = await _company.getItem(CompanyItem.ROLES_RIGHTS);
    if (_cacheRoles) {
      _card.roleRights = _cacheRoles as string;
    }
    dispatch(setCard(_card));
  };
  return { companyData, roleRights };
};
