import React, { FC, forwardRef, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import MenuItem from '../MenuItem/MenuItem';
import Transaction from '../../../../Assets/Icons/Transaction/Transaction';
import styles from '../style';
import { LENGTH_TX_NOT_VIEWED } from '../../../../../graphql/transaction/query';
import { NavLink, useLocation } from 'react-router-dom';
import { LengthTxNotViewed } from '../../../../../graphql/transaction/types/LengthTxNotViewed';
import { fetcherWEB } from '../../../../../apolloClient';

const TransactionMenu: FC = () => {
  const history = useLocation();
  const path: string = history.pathname;
  const CustomRouterLink = forwardRef((props: any) => <NavLink {...props} />);
  const [notViewedTrNumber, SetNotViewedTrNumber] = useState(0);

  useEffect(() => {
    fetcherWEB<null, LengthTxNotViewed>(LENGTH_TX_NOT_VIEWED, null).then(
      (notViewedTr) => {
        if (notViewedTr?.lengthTxNotViewed) {
          SetNotViewedTrNumber(notViewedTr.lengthTxNotViewed);
        }
      },
    );
  }, []);

  const click = () => {
    SetNotViewedTrNumber(0);
  };

  const classes = styles();
  return (
    <Button
      disableRipple
      className={classes.button}
      component={CustomRouterLink}
      to={'/transaction'}
      onClick={click}
    >
      <MenuItem
        badgeNumber={notViewedTrNumber}
        icon={<Transaction htmlColor="#FFF" viewBox="0 0 26.526 25.117" />}
        title="Transactions"
        active={path === '/transaction'}
      />
    </Button>
  );
};
export default TransactionMenu;
