import gql from 'graphql-tag';
import { PERSON_FRAGMENT, PERSON_MEMBER_FRAGMENT } from '../person/fragment';
import { RIGHT_FRAGMENT } from '../role/fragment';
import { TEAM_FRAGMENT } from '../team/fragment';

export const OFFER_MEMBER_INFO = gql`
  fragment OfferMemberInfo on Offer {
    id
    name
  }
`;

export const ACCOUNT_FRAGMENT = gql`
  fragment accountInfo on Account {
    id
    email
    role {
      id
      name
    }
    roleRights {
      ...rightInfo
    }
    team {
      ...teamInfo
    }
    person {
      ...personInfo
    }
    isSbAccountActivated
    sbAccountId
    isInvitationAccepted
  }
  ${RIGHT_FRAGMENT}
  ${TEAM_FRAGMENT}
  ${PERSON_FRAGMENT}
`;

export const ACCOUNT_MEMBER_FRAGMENT = gql`
  fragment accountMemberInfo on Account {
    id
    offer {
      ...OfferMemberInfo
    }
    person {
      ...personMemberInfo
    }
    isSbAccountActivated
    sbAccountId
  }
  ${OFFER_MEMBER_INFO}
  ${PERSON_MEMBER_FRAGMENT}
`;

export const ACCOUNT_MEMBER_STAT_FRAGMENT = gql`
  fragment AccountMemberStatInfo on Account {
    id
    person {
      id
      name
      firstName
    }
  }
`;

export const ACCOUNT_TRANSACTION_FRAGMENT = gql`
  fragment AccountTransactionInfo on Account {
    id
    person {
      id
      name
      firstName
    }
  }
`;
