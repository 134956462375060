import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      action
      success
      message
      accessToken
    }
  }
`;

export const CONFIRM_LOGIN = gql`
  mutation ConfirmLogin($code: String!) {
    confirmLogin(code: $code)
  }
`;

export const GENERATE_TOKEN = gql`
  mutation GenerateToken {
    generateToken
  }
`;

export const ADD_CONNEXION = gql`
  mutation AddConnexion(
    $deviceType: String!
    $email: String!
    $id: ID
    $ipAddress: String!
    $isSignin: Boolean!
  ) {
    addConnexion(
      deviceType: $deviceType
      email: $email
      id: $id
      ipAddress: $ipAddress
      isSignin: $isSignin
    ) {
      id
      email
      ipAddress
      isSignin
      dateConnexion
      dateDeconnexion
      deviceType
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout($token: String!) {
    logout(token: $token)
  }
`;
