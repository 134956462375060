export default {
  ALIMENTATION: 'Alimentation',
  EQUIPEMENT_MATERIEL: 'Équipement et matériel',
  ASSURANCE: 'Assurance',
  AUTRE_DEPENSE: 'Autres dépenses',
  FINANCE: 'Finance',
  FOURNITURE_BUREAU: 'Fournitures de bureau',
  HOTEL_LOGEMENT: 'Hotels et logements',
  IMPOT_TAXE: 'Impôts et taxes',
  IT_ELECTRONIQUE: 'IT et électroniques',
  JURIDIQUE_COMPTA: 'Juridique et comptabilité',
  LOCATION_BUREAU: 'Location de bureau',
  LOGISTIQUE: 'Logistique',
  MARKETING: 'Marketing',
  PRODUCTION: 'Production',
  RESTAURANT_BAR: 'Restaurants et bars',
  RETRAIT: 'Retraits',
  SALAIRE: 'Salaires',
  SERVICE_EN_LIGNE: 'Services en lignes',
  STATION_ESSENCE: 'Carburant',
  TRANSPORT: 'Transports',
  UTILITAIRE: 'Utilitaires',
};
