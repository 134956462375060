/* eslint-disable */
/*
 * Autogenerated by indeksd v0.0.17
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
 */
export type InfoItem = {
  id: number;
  name: string;
  firstName: string;
  solde: string;
  payin: string;
  payout: string;
  urlAvatar: string;
};
export type LoginItem = {
  id: number;
  email: string;
};
export type TransactionListItem = {
  id: number;
  transactionId: string;
  data: string;
};
export type TransactionLisDetailtItem = {
  id: number;
  transactionId: string;
  dataDetails: string;
};
export type CategoriesListItem = {
  id: number;
  CategoriesId: string;
  data: string;
};
export type accountForStateListItem = {
  id: number;
  accountForStateId: string;
  data: string;
};
export type dataStatisticListItem = {
  id: number;
  dataCategoriesTr: string;
};
export type dataTableBarStatisticListItem = {
  id: number;
  dataTableBar: string;
};
export type dataAccountStateItem = {
  id: number;
  dataAccountState: string;
};
export type TimMembersDataCard = {
  id: number;
  membresData: string;
};
export type CategoriesCardDataItem = {
  id: number;
  CategoriesCard: string;
};
export type dataSettingItem = {
  id: number;
  companyData: string;
  walletIbanCompany: string;
  offers: string;
  myAccountData: string;
  myOfferData: string;
};
export type InfosIndexes =
  | 'id'
  | 'name'
  | 'firstName'
  | 'solde'
  | 'payin'
  | 'payout'
  | 'urlAvatar';
export type LoginIndexes = 'id' | 'email';
export type TransactionListIndexes = 'id' | 'transactionId' | 'data';
export type TransactionListDetailIndexes =
  | 'id'
  | 'transactionId'
  | 'dataDetails';
export type CategoriesListIndexes = 'id' | 'CategoriesId' | 'data';
export type AccountForStateListIndexes = 'id' | 'accountForStateId' | 'data';
export type DataStatisticListIndexes = 'id' | 'dataCategoriesTr';
export type DataTableBarStatisticListIndexes = 'id' | 'dataTableBar';
export type DataAccountStateIndexes = 'id' | 'dataAccountState';
export type MembersDataCardsIndexes = 'id' | 'membresData';
export type CategoriesCardDataIndexes = 'id' | 'CategoriesCard';
export type DataSettingIndexes =
  | 'id'
  | 'companyData'
  | 'walletIbanCompany'
  | 'offers'
  | 'myAccountData'
  | 'myOfferData';
export type InfosWhereQueryType<IndexName extends InfosIndexes> =
  IndexName extends 'id'
    ? number
    : IndexName extends 'name'
    ? string
    : IndexName extends 'firstName'
    ? string
    : IndexName extends 'solde'
    ? string
    : IndexName extends 'payin'
    ? string
    : IndexName extends 'payout'
    ? string
    : IndexName extends 'urlAvatar'
    ? string
    : never;
export type LoginWhereQueryType<IndexName extends LoginIndexes> =
  IndexName extends 'id' ? number : IndexName extends 'email' ? string : never;
export type TransactionListWhereQueryType<
  IndexName extends TransactionListIndexes,
> = IndexName extends 'id'
  ? number
  : IndexName extends 'transactionId'
  ? string
  : IndexName extends 'data'
  ? string
  : never;
export type TransactionListDetailWhereQueryType<
  IndexName extends TransactionListDetailIndexes,
> = IndexName extends 'id'
  ? number
  : IndexName extends 'transactionId'
  ? string
  : IndexName extends 'dataDetails'
  ? string
  : never;
export type CategoriesListWhereQueryType<
  IndexName extends CategoriesListIndexes,
> = IndexName extends 'id'
  ? number
  : IndexName extends 'CategoriesId'
  ? string
  : IndexName extends 'data'
  ? string
  : never;
export type AccountForStateListWhereQueryType<
  IndexName extends AccountForStateListIndexes,
> = IndexName extends 'id'
  ? number
  : IndexName extends 'accountForStateId'
  ? string
  : IndexName extends 'data'
  ? string
  : never;
export type DataStatisticListWhereQueryType<
  IndexName extends DataStatisticListIndexes,
> = IndexName extends 'id'
  ? number
  : IndexName extends 'dataCategoriesTr'
  ? string
  : never;
export type DataTableBarStatisticListWhereQueryType<
  IndexName extends DataTableBarStatisticListIndexes,
> = IndexName extends 'id'
  ? number
  : IndexName extends 'dataTableBar'
  ? string
  : never;
export type DataAccountStateWhereQueryType<
  IndexName extends DataAccountStateIndexes,
> = IndexName extends 'id'
  ? number
  : IndexName extends 'dataAccountState'
  ? string
  : never;
export type MembersDataCardsWhereQueryType<
  IndexName extends MembersDataCardsIndexes,
> = IndexName extends 'id'
  ? number
  : IndexName extends 'membresData'
  ? string
  : never;
export type CategoriesCardDataWhereQueryType<
  IndexName extends CategoriesCardDataIndexes,
> = IndexName extends 'id'
  ? number
  : IndexName extends 'CategoriesCard'
  ? string
  : never;
export type DataSettingWhereQueryType<IndexName extends DataSettingIndexes> =
  IndexName extends 'id'
    ? number
    : IndexName extends 'companyData'
    ? string
    : IndexName extends 'walletIbanCompany'
    ? string
    : IndexName extends 'offers'
    ? string
    : IndexName extends 'myAccountData'
    ? string
    : IndexName extends 'myOfferData'
    ? string
    : never;
export type InfosAddArgs = {
  name: string;
  firstName: string;
  solde: string;
  payin: string;
  payout: string;
  urlAvatar: string;
};
export type LoginAddArgs = {
  email: string;
};
export type TransactionListAddArgs = {
  transactionId: string;
  data: string;
};
export type TransactionListDetailAddArgs = {
  transactionId: string;
  dataDetails: string;
};
export type CategoriesListAddArgs = {
  CategoriesId: string;
  data: string;
};
export type AccountForStateListAddArgs = {
  accountForStateId: string;
  data: string;
};
export type DataStatisticListAddArgs = {
  dataCategoriesTr: string;
};
export type DataTableBarStatisticListAddArgs = {
  dataTableBar: string;
};
export type DataAccountStateAddArgs = {
  dataAccountState: string;
};
export type MembersDataCardsAddArgs = {
  membresData: string;
};
export type CategoriesCardDataAddArgs = {
  CategoriesCard: string;
};
export type DataSettingAddArgs = {
  companyData: string;
  walletIbanCompany: string;
  offers: string;
  myAccountData: string;
  myOfferData: string;
};
export type InfosPutArgs =
  | InfosAddArgs
  | {
      id: number;
      name: string;
      firstName: string;
      solde: string;
      payin: string;
      payout: string;
      urlAvatar: string;
    };
export type LoginPutArgs =
  | LoginAddArgs
  | {
      id: number;
      email: string;
    };
export type TransactionListPutArgs =
  | TransactionListAddArgs
  | {
      id: number;
      transactionId: string;
      data: string;
    };
export type TransactionListDetailPutArgs =
  | TransactionListDetailAddArgs
  | {
      id: number;
      transactionId: string;
      dataDetails: string;
    };
export type CategoriesListPutArgs =
  | CategoriesListAddArgs
  | {
      id: number;
      CategoriesId: string;
      data: string;
    };
export type AccountForStateListPutArgs =
  | AccountForStateListAddArgs
  | {
      id: number;
      accountForStateId: string;
      data: string;
    };
export type DataStatisticListPutArgs =
  | DataStatisticListAddArgs
  | {
      id: number;
      dataCategoriesTr: string;
    };
export type DataTableBarStatisticListPutArgs =
  | DataTableBarStatisticListAddArgs
  | {
      id: number;
      dataTableBar: string;
    };
export type DataAccountStatePutArgs =
  | DataAccountStateAddArgs
  | {
      id: number;
      dataAccountState: string;
    };
export type MembersDataCardsPutArgs =
  | MembersDataCardsAddArgs
  | {
      id: number;
      membresData: string;
    };
export type CategoriesCardDataPutArgs =
  | CategoriesCardDataAddArgs
  | {
      id: number;
      CategoriesCard: string;
    };
export type DataSettingPutArgs =
  | DataSettingAddArgs
  | {
      id: number;
      companyData: string;
      walletIbanCompany: string;
      offers: string;
      myAccountData: string;
      myOfferData: string;
    };
export type InfosDeleteArgs =
  | number
  | {
      id: number;
    };
export type LoginDeleteArgs =
  | number
  | {
      id: number;
    };
export type TransactionListDeleteArgs =
  | number
  | {
      id: number;
    };
export type TransactionListDetailDeleteArgs =
  | number
  | {
      id: number;
    };
export type CategoriesListDeleteArgs =
  | number
  | {
      id: number;
    };
export type AccountForStateListDeleteArgs =
  | number
  | {
      id: number;
    };
export type DataStatisticListDeleteArgs =
  | number
  | {
      id: number;
    };
export type DataTableBarStatisticListDeleteArgs =
  | number
  | {
      id: number;
    };
export type DataAccountStateDeleteArgs =
  | number
  | {
      id: number;
    };
export type MembersDataCardsDeleteArgs =
  | number
  | {
      id: number;
    };
export type CategoriesCardDataDeleteArgs =
  | number
  | {
      id: number;
    };
export type DataSettingDeleteArgs =
  | number
  | {
      id: number;
    };
export type InfosGetArgs =
  | number
  | {
      id: number;
    }
  | {
      name: string;
    }
  | {
      firstName: string;
    }
  | {
      solde: string;
    }
  | {
      payin: string;
    }
  | {
      payout: string;
    }
  | {
      urlAvatar: string;
    };
export type LoginGetArgs =
  | number
  | {
      id: number;
    }
  | {
      email: string;
    };
export type TransactionListGetArgs =
  | number
  | {
      id: number;
    }
  | {
      transactionId: string;
    }
  | {
      data: string;
    };
export type TransactionListDetailGetArgs =
  | number
  | {
      id: number;
    }
  | {
      transactionId: string;
    }
  | {
      dataDetails: string;
    };
export type CategoriesListGetArgs =
  | number
  | {
      id: number;
    }
  | {
      CategoriesId: string;
    }
  | {
      data: string;
    };
export type AccountForStateListGetArgs =
  | number
  | {
      id: number;
    }
  | {
      accountForStateId: string;
    }
  | {
      data: string;
    };
export type DataStatisticListGetArgs =
  | number
  | {
      id: number;
    }
  | {
      dataCategoriesTr: string;
    };
export type DataTableBarStatisticListGetArgs =
  | number
  | {
      id: number;
    }
  | {
      dataTableBar: string;
    };
export type DataAccountStateGetArgs =
  | number
  | {
      id: number;
    }
  | {
      dataAccountState: string;
    };
export type MembersDataCardsGetArgs =
  | number
  | {
      id: number;
    }
  | {
      membresData: string;
    };
export type CategoriesCardDataGetArgs =
  | number
  | {
      id: number;
    }
  | {
      CategoriesCard: string;
    };
export type DataSettingGetArgs =
  | number
  | {
      id: number;
    }
  | {
      companyData: string;
    }
  | {
      walletIbanCompany: string;
    }
  | {
      offers: string;
    }
  | {
      myAccountData: string;
    }
  | {
      myOfferData: string;
    };
const isInfosIdIndex = (
  arg?: InfosGetArgs,
): arg is {
  id: number;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'id')
  );
};
const isInfosNameIndex = (
  arg?: InfosGetArgs,
): arg is {
  name: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'name')
  );
};
const isInfosFirstNameIndex = (
  arg?: InfosGetArgs,
): arg is {
  firstName: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'firstName')
  );
};
const isInfosSoldeIndex = (
  arg?: InfosGetArgs,
): arg is {
  solde: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'solde')
  );
};
const isInfosPayinIndex = (
  arg?: InfosGetArgs,
): arg is {
  payin: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'payin')
  );
};
const isInfosPayoutIndex = (
  arg?: InfosGetArgs,
): arg is {
  payout: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'payout')
  );
};
const isInfosUrlAvatarIndex = (
  arg?: InfosGetArgs,
): arg is {
  urlAvatar: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'urlAvatar')
  );
};
const isLoginIdIndex = (
  arg?: LoginGetArgs,
): arg is {
  id: number;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'id')
  );
};
const isLoginEmailIndex = (
  arg?: LoginGetArgs,
): arg is {
  email: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'email')
  );
};
const isTransactionListIdIndex = (
  arg?: TransactionListGetArgs,
): arg is {
  id: number;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'id')
  );
};
const isTransactionListTransactionIdIndex = (
  arg?: TransactionListGetArgs,
): arg is {
  transactionId: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'transactionId')
  );
};
const isTransactionListDataIndex = (
  arg?: TransactionListGetArgs,
): arg is {
  data: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'data')
  );
};
const isTransactionListDetailIdIndex = (
  arg?: TransactionListDetailGetArgs,
): arg is {
  id: number;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'id')
  );
};
const isTransactionListDetailTransactionIdIndex = (
  arg?: TransactionListDetailGetArgs,
): arg is {
  transactionId: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'transactionId')
  );
};
const isTransactionListDetailDataDetailsIndex = (
  arg?: TransactionListDetailGetArgs,
): arg is {
  dataDetails: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'dataDetails')
  );
};
const isCategoriesListIdIndex = (
  arg?: CategoriesListGetArgs,
): arg is {
  id: number;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'id')
  );
};
const isCategoriesListCategoriesIdIndex = (
  arg?: CategoriesListGetArgs,
): arg is {
  CategoriesId: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'CategoriesId')
  );
};
const isCategoriesListDataIndex = (
  arg?: CategoriesListGetArgs,
): arg is {
  data: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'data')
  );
};
const isAccountForStateListIdIndex = (
  arg?: AccountForStateListGetArgs,
): arg is {
  id: number;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'id')
  );
};
const isAccountForStateListAccountForStateIdIndex = (
  arg?: AccountForStateListGetArgs,
): arg is {
  accountForStateId: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'accountForStateId')
  );
};
const isAccountForStateListDataIndex = (
  arg?: AccountForStateListGetArgs,
): arg is {
  data: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'data')
  );
};
const isDataStatisticListIdIndex = (
  arg?: DataStatisticListGetArgs,
): arg is {
  id: number;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'id')
  );
};
const isDataStatisticListDataCategoriesTrIndex = (
  arg?: DataStatisticListGetArgs,
): arg is {
  dataCategoriesTr: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'dataCategoriesTr')
  );
};
const isDataTableBarStatisticListIdIndex = (
  arg?: DataTableBarStatisticListGetArgs,
): arg is {
  id: number;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'id')
  );
};
const isDataTableBarStatisticListDataTableBarIndex = (
  arg?: DataTableBarStatisticListGetArgs,
): arg is {
  dataTableBar: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'dataTableBar')
  );
};
const isDataAccountStateIdIndex = (
  arg?: DataAccountStateGetArgs,
): arg is {
  id: number;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'id')
  );
};
const isDataAccountStateDataAccountStateIndex = (
  arg?: DataAccountStateGetArgs,
): arg is {
  dataAccountState: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'dataAccountState')
  );
};
const isMembersDataCardsIdIndex = (
  arg?: MembersDataCardsGetArgs,
): arg is {
  id: number;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'id')
  );
};
const isMembersDataCardsMembresDataIndex = (
  arg?: MembersDataCardsGetArgs,
): arg is {
  membresData: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'membresData')
  );
};
const isCategoriesCardDataIdIndex = (
  arg?: CategoriesCardDataGetArgs,
): arg is {
  id: number;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'id')
  );
};
const isCategoriesCardDataCategoriesCardIndex = (
  arg?: CategoriesCardDataGetArgs,
): arg is {
  CategoriesCard: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'CategoriesCard')
  );
};
const isDataSettingIdIndex = (
  arg?: DataSettingGetArgs,
): arg is {
  id: number;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'id')
  );
};
const isDataSettingCompanyDataIndex = (
  arg?: DataSettingGetArgs,
): arg is {
  companyData: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'companyData')
  );
};
const isDataSettingWalletIbanCompanyIndex = (
  arg?: DataSettingGetArgs,
): arg is {
  walletIbanCompany: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'walletIbanCompany')
  );
};
const isDataSettingOffersIndex = (
  arg?: DataSettingGetArgs,
): arg is {
  offers: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'offers')
  );
};
const isDataSettingMyAccountDataIndex = (
  arg?: DataSettingGetArgs,
): arg is {
  myAccountData: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'myAccountData')
  );
};
const isDataSettingMyOfferDataIndex = (
  arg?: DataSettingGetArgs,
): arg is {
  myOfferData: string;
} => {
  return (
    typeof arg === 'object' &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, 'myOfferData')
  );
};
type RangeQuery<ArgType, ReturnType> = {
  isGreaterThan(arg: ArgType): ReturnType;
  isGreaterThanOrEqualTo(arg: ArgType): ReturnType;
  isLessThan(arg: ArgType): ReturnType;
  isLessThanOrEqualTo(arg: ArgType): ReturnType;
  isBetween(arg: { from: ArgType; to: ArgType }): ReturnType;
  isEqualTo(arg: ArgType): ReturnType;
};
export type SubscriptionEvent<ItemType, PrimaryKeyType> =
  | {
      type: 'add' | 'put';
      data: ItemType;
    }
  | {
      type: 'delete';
      data: PrimaryKeyType;
    };
export type InfosClient = {
  subscribe(
    eventName: 'change' | 'add' | 'put' | 'delete',
    callback: (event: SubscriptionEvent<InfoItem, number>) => void,
  ): void;
  add(
    arg: InfosAddArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<InfoItem>;
  put(
    arg: InfosPutArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<InfoItem>;
  delete(
    arg: InfosDeleteArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<void>;
  get(
    arg: InfosGetArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<InfoItem>;
  where<IndexName extends InfosIndexes>(
    indexName: IndexName,
    options?: {
      transaction?: IDBTransaction;
    },
  ): RangeQuery<
    InfosWhereQueryType<IndexName>,
    Promise<ReadonlyArray<InfoItem>>
  >;
  sortBy(
    indexName: InfosIndexes,
    options?: {
      transaction?: IDBTransaction;
      count?: number;
    },
  ): Promise<ReadonlyArray<InfoItem>>;
};
export type LoginClient = {
  subscribe(
    eventName: 'change' | 'add' | 'put' | 'delete',
    callback: (event: SubscriptionEvent<LoginItem, number>) => void,
  ): void;
  add(
    arg: LoginAddArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<LoginItem>;
  put(
    arg: LoginPutArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<LoginItem>;
  delete(
    arg: LoginDeleteArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<void>;
  get(
    arg: LoginGetArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<LoginItem>;
  where<IndexName extends LoginIndexes>(
    indexName: IndexName,
    options?: {
      transaction?: IDBTransaction;
    },
  ): RangeQuery<
    LoginWhereQueryType<IndexName>,
    Promise<ReadonlyArray<LoginItem>>
  >;
  sortBy(
    indexName: LoginIndexes,
    options?: {
      transaction?: IDBTransaction;
      count?: number;
    },
  ): Promise<ReadonlyArray<LoginItem>>;
};
export type TransactionListClient = {
  subscribe(
    eventName: 'change' | 'add' | 'put' | 'delete',
    callback: (event: SubscriptionEvent<TransactionListItem, number>) => void,
  ): void;
  add(
    arg: TransactionListAddArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<TransactionListItem>;
  put(
    arg: TransactionListPutArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<TransactionListItem>;
  delete(
    arg: TransactionListDeleteArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<void>;
  get(
    arg: TransactionListGetArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<TransactionListItem>;
  where<IndexName extends TransactionListIndexes>(
    indexName: IndexName,
    options?: {
      transaction?: IDBTransaction;
    },
  ): RangeQuery<
    TransactionListWhereQueryType<IndexName>,
    Promise<ReadonlyArray<TransactionListItem>>
  >;
  sortBy(
    indexName: TransactionListIndexes,
    options?: {
      transaction?: IDBTransaction;
      count?: number;
    },
  ): Promise<ReadonlyArray<TransactionListItem>>;
};
export type TransactionListDetailClient = {
  subscribe(
    eventName: 'change' | 'add' | 'put' | 'delete',
    callback: (
      event: SubscriptionEvent<TransactionLisDetailtItem, number>,
    ) => void,
  ): void;
  add(
    arg: TransactionListDetailAddArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<TransactionLisDetailtItem>;
  put(
    arg: TransactionListDetailPutArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<TransactionLisDetailtItem>;
  delete(
    arg: TransactionListDetailDeleteArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<void>;
  get(
    arg: TransactionListDetailGetArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<TransactionLisDetailtItem>;
  where<IndexName extends TransactionListDetailIndexes>(
    indexName: IndexName,
    options?: {
      transaction?: IDBTransaction;
    },
  ): RangeQuery<
    TransactionListDetailWhereQueryType<IndexName>,
    Promise<ReadonlyArray<TransactionLisDetailtItem>>
  >;
  sortBy(
    indexName: TransactionListDetailIndexes,
    options?: {
      transaction?: IDBTransaction;
      count?: number;
    },
  ): Promise<ReadonlyArray<TransactionLisDetailtItem>>;
};
export type CategoriesListClient = {
  subscribe(
    eventName: 'change' | 'add' | 'put' | 'delete',
    callback: (event: SubscriptionEvent<CategoriesListItem, number>) => void,
  ): void;
  add(
    arg: CategoriesListAddArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<CategoriesListItem>;
  put(
    arg: CategoriesListPutArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<CategoriesListItem>;
  delete(
    arg: CategoriesListDeleteArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<void>;
  get(
    arg: CategoriesListGetArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<CategoriesListItem>;
  where<IndexName extends CategoriesListIndexes>(
    indexName: IndexName,
    options?: {
      transaction?: IDBTransaction;
    },
  ): RangeQuery<
    CategoriesListWhereQueryType<IndexName>,
    Promise<ReadonlyArray<CategoriesListItem>>
  >;
  sortBy(
    indexName: CategoriesListIndexes,
    options?: {
      transaction?: IDBTransaction;
      count?: number;
    },
  ): Promise<ReadonlyArray<CategoriesListItem>>;
};
export type AccountForStateListClient = {
  subscribe(
    eventName: 'change' | 'add' | 'put' | 'delete',
    callback: (
      event: SubscriptionEvent<accountForStateListItem, number>,
    ) => void,
  ): void;
  add(
    arg: AccountForStateListAddArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<accountForStateListItem>;
  put(
    arg: AccountForStateListPutArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<accountForStateListItem>;
  delete(
    arg: AccountForStateListDeleteArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<void>;
  get(
    arg: AccountForStateListGetArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<accountForStateListItem>;
  where<IndexName extends AccountForStateListIndexes>(
    indexName: IndexName,
    options?: {
      transaction?: IDBTransaction;
    },
  ): RangeQuery<
    AccountForStateListWhereQueryType<IndexName>,
    Promise<ReadonlyArray<accountForStateListItem>>
  >;
  sortBy(
    indexName: AccountForStateListIndexes,
    options?: {
      transaction?: IDBTransaction;
      count?: number;
    },
  ): Promise<ReadonlyArray<accountForStateListItem>>;
};
export type DataStatisticListClient = {
  subscribe(
    eventName: 'change' | 'add' | 'put' | 'delete',
    callback: (event: SubscriptionEvent<dataStatisticListItem, number>) => void,
  ): void;
  add(
    arg: DataStatisticListAddArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<dataStatisticListItem>;
  put(
    arg: DataStatisticListPutArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<dataStatisticListItem>;
  delete(
    arg: DataStatisticListDeleteArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<void>;
  get(
    arg: DataStatisticListGetArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<dataStatisticListItem>;
  where<IndexName extends DataStatisticListIndexes>(
    indexName: IndexName,
    options?: {
      transaction?: IDBTransaction;
    },
  ): RangeQuery<
    DataStatisticListWhereQueryType<IndexName>,
    Promise<ReadonlyArray<dataStatisticListItem>>
  >;
  sortBy(
    indexName: DataStatisticListIndexes,
    options?: {
      transaction?: IDBTransaction;
      count?: number;
    },
  ): Promise<ReadonlyArray<dataStatisticListItem>>;
};
export type DataTableBarStatisticListClient = {
  subscribe(
    eventName: 'change' | 'add' | 'put' | 'delete',
    callback: (
      event: SubscriptionEvent<dataTableBarStatisticListItem, number>,
    ) => void,
  ): void;
  add(
    arg: DataTableBarStatisticListAddArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<dataTableBarStatisticListItem>;
  put(
    arg: DataTableBarStatisticListPutArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<dataTableBarStatisticListItem>;
  delete(
    arg: DataTableBarStatisticListDeleteArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<void>;
  get(
    arg: DataTableBarStatisticListGetArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<dataTableBarStatisticListItem>;
  where<IndexName extends DataTableBarStatisticListIndexes>(
    indexName: IndexName,
    options?: {
      transaction?: IDBTransaction;
    },
  ): RangeQuery<
    DataTableBarStatisticListWhereQueryType<IndexName>,
    Promise<ReadonlyArray<dataTableBarStatisticListItem>>
  >;
  sortBy(
    indexName: DataTableBarStatisticListIndexes,
    options?: {
      transaction?: IDBTransaction;
      count?: number;
    },
  ): Promise<ReadonlyArray<dataTableBarStatisticListItem>>;
};
export type DataAccountStateClient = {
  subscribe(
    eventName: 'change' | 'add' | 'put' | 'delete',
    callback: (event: SubscriptionEvent<dataAccountStateItem, number>) => void,
  ): void;
  add(
    arg: DataAccountStateAddArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<dataAccountStateItem>;
  put(
    arg: DataAccountStatePutArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<dataAccountStateItem>;
  delete(
    arg: DataAccountStateDeleteArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<void>;
  get(
    arg: DataAccountStateGetArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<dataAccountStateItem>;
  where<IndexName extends DataAccountStateIndexes>(
    indexName: IndexName,
    options?: {
      transaction?: IDBTransaction;
    },
  ): RangeQuery<
    DataAccountStateWhereQueryType<IndexName>,
    Promise<ReadonlyArray<dataAccountStateItem>>
  >;
  sortBy(
    indexName: DataAccountStateIndexes,
    options?: {
      transaction?: IDBTransaction;
      count?: number;
    },
  ): Promise<ReadonlyArray<dataAccountStateItem>>;
};
export type MembersDataCardsClient = {
  subscribe(
    eventName: 'change' | 'add' | 'put' | 'delete',
    callback: (event: SubscriptionEvent<TimMembersDataCard, number>) => void,
  ): void;
  add(
    arg: MembersDataCardsAddArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<TimMembersDataCard>;
  put(
    arg: MembersDataCardsPutArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<TimMembersDataCard>;
  delete(
    arg: MembersDataCardsDeleteArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<void>;
  get(
    arg: MembersDataCardsGetArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<TimMembersDataCard>;
  where<IndexName extends MembersDataCardsIndexes>(
    indexName: IndexName,
    options?: {
      transaction?: IDBTransaction;
    },
  ): RangeQuery<
    MembersDataCardsWhereQueryType<IndexName>,
    Promise<ReadonlyArray<TimMembersDataCard>>
  >;
  sortBy(
    indexName: MembersDataCardsIndexes,
    options?: {
      transaction?: IDBTransaction;
      count?: number;
    },
  ): Promise<ReadonlyArray<TimMembersDataCard>>;
};
export type CategoriesCardDataClient = {
  subscribe(
    eventName: 'change' | 'add' | 'put' | 'delete',
    callback: (
      event: SubscriptionEvent<CategoriesCardDataItem, number>,
    ) => void,
  ): void;
  add(
    arg: CategoriesCardDataAddArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<CategoriesCardDataItem>;
  put(
    arg: CategoriesCardDataPutArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<CategoriesCardDataItem>;
  delete(
    arg: CategoriesCardDataDeleteArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<void>;
  get(
    arg: CategoriesCardDataGetArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<CategoriesCardDataItem>;
  where<IndexName extends CategoriesCardDataIndexes>(
    indexName: IndexName,
    options?: {
      transaction?: IDBTransaction;
    },
  ): RangeQuery<
    CategoriesCardDataWhereQueryType<IndexName>,
    Promise<ReadonlyArray<CategoriesCardDataItem>>
  >;
  sortBy(
    indexName: CategoriesCardDataIndexes,
    options?: {
      transaction?: IDBTransaction;
      count?: number;
    },
  ): Promise<ReadonlyArray<CategoriesCardDataItem>>;
};
export type DataSettingClient = {
  subscribe(
    eventName: 'change' | 'add' | 'put' | 'delete',
    callback: (event: SubscriptionEvent<dataSettingItem, number>) => void,
  ): void;
  add(
    arg: DataSettingAddArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<dataSettingItem>;
  put(
    arg: DataSettingPutArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<dataSettingItem>;
  delete(
    arg: DataSettingDeleteArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<void>;
  get(
    arg: DataSettingGetArgs,
    options?: {
      transaction?: IDBTransaction;
    },
  ): Promise<dataSettingItem>;
  where<IndexName extends DataSettingIndexes>(
    indexName: IndexName,
    options?: {
      transaction?: IDBTransaction;
    },
  ): RangeQuery<
    DataSettingWhereQueryType<IndexName>,
    Promise<ReadonlyArray<dataSettingItem>>
  >;
  sortBy(
    indexName: DataSettingIndexes,
    options?: {
      transaction?: IDBTransaction;
      count?: number;
    },
  ): Promise<ReadonlyArray<dataSettingItem>>;
};
export type SapheerDatabaseClient = {
  transaction: (
    storeNames: Array<
      | 'Infos'
      | 'Login'
      | 'TransactionList'
      | 'TransactionListDetail'
      | 'CategoriesList'
      | 'accountForStateList'
      | 'DataStatisticList'
      | 'DataTableBarStatisticList'
      | 'DataAccountState'
      | 'MembersDataCards'
      | 'CategoriesCardData'
      | 'DataSetting'
    >,
    mode?: IDBTransactionMode,
  ) => IDBTransaction;
  infos: InfosClient;
  login: LoginClient;
  transactionlist: TransactionListClient;
  transactionlistdetail: TransactionListDetailClient;
  categorieslist: CategoriesListClient;
  accountforstatelist: AccountForStateListClient;
  datastatisticlist: DataStatisticListClient;
  datatablebarstatisticlist: DataTableBarStatisticListClient;
  dataaccountstate: DataAccountStateClient;
  membersdatacards: MembersDataCardsClient;
  categoriescarddata: CategoriesCardDataClient;
  datasetting: DataSettingClient;
};
function createDatabaseClient(db: IDBDatabase): SapheerDatabaseClient {
  class Observable<ItemType, PrimaryKeyType> {
    #listeners: Map<
      string,
      Map<number, (event: SubscriptionEvent<ItemType, PrimaryKeyType>) => void>
    > = new Map();
    #nextId: number = 0;
    subscribe(
      eventName: 'change' | 'add' | 'put' | 'delete',
      callback: (event: SubscriptionEvent<ItemType, PrimaryKeyType>) => void,
    ): void {
      if (this.#listeners.has(eventName)) {
        (this.#listeners.get(eventName)?.set(this.#nextId, callback));
      } else {
        const innerMap: Map<
          number,
          (event: SubscriptionEvent<ItemType, PrimaryKeyType>) => void
        > = new Map();
        innerMap.set(this.#nextId, callback);
        this.#listeners.set(eventName, innerMap);
      }
      this.#nextId += 1;
    }
    _push(eventName: 'delete', data: PrimaryKeyType): void;
    _push(eventName: 'add' | 'put', data: ItemType): void;
    _push(
      eventName: 'add' | 'put' | 'delete',
      data: ItemType | PrimaryKeyType,
    ): void {
      (this.#listeners.get('change')?.forEach((callback) => {
        callback({ type: eventName, data } as SubscriptionEvent<
          ItemType,
          PrimaryKeyType
        >);
      }));
      (this.#listeners.get(eventName)?.forEach((callback) => {
        callback({ type: eventName, data } as SubscriptionEvent<
          ItemType,
          PrimaryKeyType
        >);
      }));
    }
  }
  class InfosClientImpl extends Observable<InfoItem, number> {
    static tablesForTransaction: ReadonlyArray<string> = ['Infos'];
    add(
      arg: InfosAddArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<InfoItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(['Infos'], 'readwrite');
        const store = tx.objectStore('Infos');
        const [] = await Promise.all([]);
        const DBAddRequest: IDBRequest = store.add({
          ...arg,
        });
        DBAddRequest.onerror = () => {
          if (DBAddRequest != null) {
            reject(DBAddRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBAddRequest.onsuccess = () => {
          if (DBAddRequest != null) {
            const mergedResult: InfoItem = {
              ...arg,
              id: DBAddRequest.result,
            } as InfoItem;
            this._push('add', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    put(
      arg: InfosPutArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<InfoItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(['Infos'], 'readwrite');
        const store = tx.objectStore('Infos');
        const [] = await Promise.all([]);
        const DBPutRequest: IDBRequest = store.put({
          ...arg,
        });
        DBPutRequest.onerror = () => {
          if (DBPutRequest != null) {
            reject(DBPutRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBPutRequest.onsuccess = () => {
          if (DBPutRequest != null) {
            const mergedResult: InfoItem = {
              ...arg,
              id: DBPutRequest.result,
            } as InfoItem;
            this._push('put', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    delete(
      arg: InfosDeleteArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(['Infos'], 'readwrite');
        const store = tx.objectStore('Infos');
        const idToDelete: number = isInfosIdIndex(arg) ? arg.id : arg;
        const DBDeleteRequest: IDBRequest = store.delete(idToDelete);
        if (DBDeleteRequest != null) {
          DBDeleteRequest.onerror = () => {
            if (DBDeleteRequest != null) {
              reject(DBDeleteRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBDeleteRequest.onsuccess = () => {
            this._push('delete', idToDelete);
            resolve(undefined);
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    get(
      arg: InfosGetArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<InfoItem> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(['Infos'], 'readonly');
        const store = tx.objectStore('Infos');
        let DBGetRequest: IDBRequest | null = null;
        if (isInfosIdIndex(arg)) {
          DBGetRequest = store.get(arg.id);
        } else if (isInfosNameIndex(arg)) {
          const index: IDBIndex = store.index('name');
          DBGetRequest = index.get([arg.name]);
        } else if (isInfosFirstNameIndex(arg)) {
          const index: IDBIndex = store.index('firstName');
          DBGetRequest = index.get([arg.firstName]);
        } else if (isInfosSoldeIndex(arg)) {
          const index: IDBIndex = store.index('solde');
          DBGetRequest = index.get([arg.solde]);
        } else if (isInfosPayinIndex(arg)) {
          const index: IDBIndex = store.index('payin');
          DBGetRequest = index.get([arg.payin]);
        } else if (isInfosPayoutIndex(arg)) {
          const index: IDBIndex = store.index('payout');
          DBGetRequest = index.get([arg.payout]);
        } else if (isInfosUrlAvatarIndex(arg)) {
          const index: IDBIndex = store.index('urlAvatar');
          DBGetRequest = index.get([arg.urlAvatar]);
        } else {
          DBGetRequest = store.get(arg);
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    where<IndexName extends InfosIndexes>(
      indexName: IndexName,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
      },
    ): RangeQuery<
      InfosWhereQueryType<IndexName>,
      Promise<ReadonlyArray<InfoItem>>
    > {
      const tx = options?.transaction ?? db.transaction(['Infos'], 'readonly');
      const store = tx.objectStore('Infos');
      const executeQuery = (
        target: IDBObjectStore | IDBIndex,
        range: IDBKeyRange,
      ) => {
        return new Promise<ReadonlyArray<InfoItem>>(async (resolve, reject) => {
          const DBGetRequest: IDBRequest = target.getAll(range);
          if (DBGetRequest != null) {
            DBGetRequest.onerror = () => {
              if (DBGetRequest != null) {
                reject(DBGetRequest.error);
              } else {
                reject(
                  new Error(
                    'Unknown error occurred trying to perform operation',
                  ),
                );
              }
            };
            DBGetRequest.onsuccess = () => {
              if (DBGetRequest != null && DBGetRequest.result != null) {
                resolve((DBGetRequest as IDBRequest).result);
              } else {
                reject(new Error('No result found for query'));
              }
            };
          } else {
            reject(new Error('No available index for given query'));
          }
        });
      };
      return {
        isGreaterThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, true));
            }
            case 'name': {
              return executeQuery(
                store.index('name'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            case 'firstName': {
              return executeQuery(
                store.index('firstName'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            case 'solde': {
              return executeQuery(
                store.index('solde'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            case 'payin': {
              return executeQuery(
                store.index('payin'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            case 'payout': {
              return executeQuery(
                store.index('payout'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            case 'urlAvatar': {
              return executeQuery(
                store.index('urlAvatar'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isGreaterThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, false));
            }
            case 'name': {
              return executeQuery(
                store.index('name'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            case 'firstName': {
              return executeQuery(
                store.index('firstName'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            case 'solde': {
              return executeQuery(
                store.index('solde'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            case 'payin': {
              return executeQuery(
                store.index('payin'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            case 'payout': {
              return executeQuery(
                store.index('payout'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            case 'urlAvatar': {
              return executeQuery(
                store.index('urlAvatar'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, true));
            }
            case 'name': {
              return executeQuery(
                store.index('name'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            case 'firstName': {
              return executeQuery(
                store.index('firstName'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            case 'solde': {
              return executeQuery(
                store.index('solde'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            case 'payin': {
              return executeQuery(
                store.index('payin'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            case 'payout': {
              return executeQuery(
                store.index('payout'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            case 'urlAvatar': {
              return executeQuery(
                store.index('urlAvatar'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, false));
            }
            case 'name': {
              return executeQuery(
                store.index('name'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            case 'firstName': {
              return executeQuery(
                store.index('firstName'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            case 'solde': {
              return executeQuery(
                store.index('solde'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            case 'payin': {
              return executeQuery(
                store.index('payin'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            case 'payout': {
              return executeQuery(
                store.index('payout'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            case 'urlAvatar': {
              return executeQuery(
                store.index('urlAvatar'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isBetween(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(
                store,
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'name': {
              return executeQuery(
                store.index('name'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'firstName': {
              return executeQuery(
                store.index('firstName'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'solde': {
              return executeQuery(
                store.index('solde'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'payin': {
              return executeQuery(
                store.index('payin'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'payout': {
              return executeQuery(
                store.index('payout'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'urlAvatar': {
              return executeQuery(
                store.index('urlAvatar'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.only(query));
            }
            case 'name': {
              return executeQuery(store.index('name'), IDBKeyRange.only(query));
            }
            case 'firstName': {
              return executeQuery(
                store.index('firstName'),
                IDBKeyRange.only(query),
              );
            }
            case 'solde': {
              return executeQuery(
                store.index('solde'),
                IDBKeyRange.only(query),
              );
            }
            case 'payin': {
              return executeQuery(
                store.index('payin'),
                IDBKeyRange.only(query),
              );
            }
            case 'payout': {
              return executeQuery(
                store.index('payout'),
                IDBKeyRange.only(query),
              );
            }
            case 'urlAvatar': {
              return executeQuery(
                store.index('urlAvatar'),
                IDBKeyRange.only(query),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
      };
    }
    sortBy(
      indexName: InfosIndexes,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
        count?: number;
      },
    ): Promise<ReadonlyArray<InfoItem>> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(['Infos'], 'readonly');
        const store = tx.objectStore('Infos');
        let DBGetRequest: IDBRequest | null = null;
        switch (indexName) {
          case 'id': {
            DBGetRequest = store.getAll(undefined, options?.count);
            break;
          }
          case 'name': {
            const index: IDBIndex = store.index('name');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          case 'firstName': {
            const index: IDBIndex = store.index('firstName');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          case 'solde': {
            const index: IDBIndex = store.index('solde');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          case 'payin': {
            const index: IDBIndex = store.index('payin');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          case 'payout': {
            const index: IDBIndex = store.index('payout');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          case 'urlAvatar': {
            const index: IDBIndex = store.index('urlAvatar');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          default: {
            return Promise.reject(
              new Error('Trying to run query on unknown index: ' + indexName),
            );
          }
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
  }
  const infosClient = new InfosClientImpl();
  class LoginClientImpl extends Observable<LoginItem, number> {
    static tablesForTransaction: ReadonlyArray<string> = ['Login'];
    add(
      arg: LoginAddArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<LoginItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(['Login'], 'readwrite');
        const store = tx.objectStore('Login');
        const [] = await Promise.all([]);
        const DBAddRequest: IDBRequest = store.add({
          ...arg,
        });
        DBAddRequest.onerror = () => {
          if (DBAddRequest != null) {
            reject(DBAddRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBAddRequest.onsuccess = () => {
          if (DBAddRequest != null) {
            const mergedResult: LoginItem = {
              ...arg,
              id: DBAddRequest.result,
            } as LoginItem;
            this._push('add', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    put(
      arg: LoginPutArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<LoginItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(['Login'], 'readwrite');
        const store = tx.objectStore('Login');
        const [] = await Promise.all([]);
        const DBPutRequest: IDBRequest = store.put({
          ...arg,
        });
        DBPutRequest.onerror = () => {
          if (DBPutRequest != null) {
            reject(DBPutRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBPutRequest.onsuccess = () => {
          if (DBPutRequest != null) {
            const mergedResult: LoginItem = {
              ...arg,
              id: DBPutRequest.result,
            } as LoginItem;
            this._push('put', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    delete(
      arg: LoginDeleteArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(['Login'], 'readwrite');
        const store = tx.objectStore('Login');
        const idToDelete: number = isLoginIdIndex(arg) ? arg.id : arg;
        const DBDeleteRequest: IDBRequest = store.delete(idToDelete);
        if (DBDeleteRequest != null) {
          DBDeleteRequest.onerror = () => {
            if (DBDeleteRequest != null) {
              reject(DBDeleteRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBDeleteRequest.onsuccess = () => {
            this._push('delete', idToDelete);
            resolve(undefined);
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    get(
      arg: LoginGetArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<LoginItem> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(['Login'], 'readonly');
        const store = tx.objectStore('Login');
        let DBGetRequest: IDBRequest | null = null;
        if (isLoginIdIndex(arg)) {
          DBGetRequest = store.get(arg.id);
        } else if (isLoginEmailIndex(arg)) {
          const index: IDBIndex = store.index('email');
          DBGetRequest = index.get([arg.email]);
        } else {
          DBGetRequest = store.get(arg);
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    where<IndexName extends LoginIndexes>(
      indexName: IndexName,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
      },
    ): RangeQuery<
      LoginWhereQueryType<IndexName>,
      Promise<ReadonlyArray<LoginItem>>
    > {
      const tx = options?.transaction ?? db.transaction(['Login'], 'readonly');
      const store = tx.objectStore('Login');
      const executeQuery = (
        target: IDBObjectStore | IDBIndex,
        range: IDBKeyRange,
      ) => {
        return new Promise<ReadonlyArray<LoginItem>>(
          async (resolve, reject) => {
            const DBGetRequest: IDBRequest = target.getAll(range);
            if (DBGetRequest != null) {
              DBGetRequest.onerror = () => {
                if (DBGetRequest != null) {
                  reject(DBGetRequest.error);
                } else {
                  reject(
                    new Error(
                      'Unknown error occurred trying to perform operation',
                    ),
                  );
                }
              };
              DBGetRequest.onsuccess = () => {
                if (DBGetRequest != null && DBGetRequest.result != null) {
                  resolve((DBGetRequest as IDBRequest).result);
                } else {
                  reject(new Error('No result found for query'));
                }
              };
            } else {
              reject(new Error('No available index for given query'));
            }
          },
        );
      };
      return {
        isGreaterThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, true));
            }
            case 'email': {
              return executeQuery(
                store.index('email'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isGreaterThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, false));
            }
            case 'email': {
              return executeQuery(
                store.index('email'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, true));
            }
            case 'email': {
              return executeQuery(
                store.index('email'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, false));
            }
            case 'email': {
              return executeQuery(
                store.index('email'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isBetween(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(
                store,
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'email': {
              return executeQuery(
                store.index('email'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.only(query));
            }
            case 'email': {
              return executeQuery(
                store.index('email'),
                IDBKeyRange.only(query),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
      };
    }
    sortBy(
      indexName: LoginIndexes,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
        count?: number;
      },
    ): Promise<ReadonlyArray<LoginItem>> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(['Login'], 'readonly');
        const store = tx.objectStore('Login');
        let DBGetRequest: IDBRequest | null = null;
        switch (indexName) {
          case 'id': {
            DBGetRequest = store.getAll(undefined, options?.count);
            break;
          }
          case 'email': {
            const index: IDBIndex = store.index('email');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          default: {
            return Promise.reject(
              new Error('Trying to run query on unknown index: ' + indexName),
            );
          }
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
  }
  const loginClient = new LoginClientImpl();
  class TransactionListClientImpl extends Observable<
    TransactionListItem,
    number
  > {
    static tablesForTransaction: ReadonlyArray<string> = ['TransactionList'];
    add(
      arg: TransactionListAddArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<TransactionListItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['TransactionList'], 'readwrite');
        const store = tx.objectStore('TransactionList');
        const [] = await Promise.all([]);
        const DBAddRequest: IDBRequest = store.add({
          ...arg,
        });
        DBAddRequest.onerror = () => {
          if (DBAddRequest != null) {
            reject(DBAddRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBAddRequest.onsuccess = () => {
          if (DBAddRequest != null) {
            const mergedResult: TransactionListItem = {
              ...arg,
              id: DBAddRequest.result,
            } as TransactionListItem;
            this._push('add', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    put(
      arg: TransactionListPutArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<TransactionListItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['TransactionList'], 'readwrite');
        const store = tx.objectStore('TransactionList');
        const [] = await Promise.all([]);
        const DBPutRequest: IDBRequest = store.put({
          ...arg,
        });
        DBPutRequest.onerror = () => {
          if (DBPutRequest != null) {
            reject(DBPutRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBPutRequest.onsuccess = () => {
          if (DBPutRequest != null) {
            const mergedResult: TransactionListItem = {
              ...arg,
              id: DBPutRequest.result,
            } as TransactionListItem;
            this._push('put', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    delete(
      arg: TransactionListDeleteArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['TransactionList'], 'readwrite');
        const store = tx.objectStore('TransactionList');
        const idToDelete: number = isTransactionListIdIndex(arg) ? arg.id : arg;
        const DBDeleteRequest: IDBRequest = store.delete(idToDelete);
        if (DBDeleteRequest != null) {
          DBDeleteRequest.onerror = () => {
            if (DBDeleteRequest != null) {
              reject(DBDeleteRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBDeleteRequest.onsuccess = () => {
            this._push('delete', idToDelete);
            resolve(undefined);
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    get(
      arg: TransactionListGetArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<TransactionListItem> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['TransactionList'], 'readonly');
        const store = tx.objectStore('TransactionList');
        let DBGetRequest: IDBRequest | null = null;
        if (isTransactionListIdIndex(arg)) {
          DBGetRequest = store.get(arg.id);
        } else if (isTransactionListTransactionIdIndex(arg)) {
          const index: IDBIndex = store.index('transactionId');
          DBGetRequest = index.get([arg.transactionId]);
        } else if (isTransactionListDataIndex(arg)) {
          const index: IDBIndex = store.index('data');
          DBGetRequest = index.get([arg.data]);
        } else {
          DBGetRequest = store.get(arg);
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    where<IndexName extends TransactionListIndexes>(
      indexName: IndexName,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
      },
    ): RangeQuery<
      TransactionListWhereQueryType<IndexName>,
      Promise<ReadonlyArray<TransactionListItem>>
    > {
      const tx =
        options?.transaction ?? db.transaction(['TransactionList'], 'readonly');
      const store = tx.objectStore('TransactionList');
      const executeQuery = (
        target: IDBObjectStore | IDBIndex,
        range: IDBKeyRange,
      ) => {
        return new Promise<ReadonlyArray<TransactionListItem>>(
          async (resolve, reject) => {
            const DBGetRequest: IDBRequest = target.getAll(range);
            if (DBGetRequest != null) {
              DBGetRequest.onerror = () => {
                if (DBGetRequest != null) {
                  reject(DBGetRequest.error);
                } else {
                  reject(
                    new Error(
                      'Unknown error occurred trying to perform operation',
                    ),
                  );
                }
              };
              DBGetRequest.onsuccess = () => {
                if (DBGetRequest != null && DBGetRequest.result != null) {
                  resolve((DBGetRequest as IDBRequest).result);
                } else {
                  reject(new Error('No result found for query'));
                }
              };
            } else {
              reject(new Error('No available index for given query'));
            }
          },
        );
      };
      return {
        isGreaterThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, true));
            }
            case 'transactionId': {
              return executeQuery(
                store.index('transactionId'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            case 'data': {
              return executeQuery(
                store.index('data'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isGreaterThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, false));
            }
            case 'transactionId': {
              return executeQuery(
                store.index('transactionId'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            case 'data': {
              return executeQuery(
                store.index('data'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, true));
            }
            case 'transactionId': {
              return executeQuery(
                store.index('transactionId'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            case 'data': {
              return executeQuery(
                store.index('data'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, false));
            }
            case 'transactionId': {
              return executeQuery(
                store.index('transactionId'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            case 'data': {
              return executeQuery(
                store.index('data'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isBetween(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(
                store,
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'transactionId': {
              return executeQuery(
                store.index('transactionId'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'data': {
              return executeQuery(
                store.index('data'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.only(query));
            }
            case 'transactionId': {
              return executeQuery(
                store.index('transactionId'),
                IDBKeyRange.only(query),
              );
            }
            case 'data': {
              return executeQuery(store.index('data'), IDBKeyRange.only(query));
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
      };
    }
    sortBy(
      indexName: TransactionListIndexes,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
        count?: number;
      },
    ): Promise<ReadonlyArray<TransactionListItem>> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['TransactionList'], 'readonly');
        const store = tx.objectStore('TransactionList');
        let DBGetRequest: IDBRequest | null = null;
        switch (indexName) {
          case 'id': {
            DBGetRequest = store.getAll(undefined, options?.count);
            break;
          }
          case 'transactionId': {
            const index: IDBIndex = store.index('transactionId');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          case 'data': {
            const index: IDBIndex = store.index('data');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          default: {
            return Promise.reject(
              new Error('Trying to run query on unknown index: ' + indexName),
            );
          }
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
  }
  const transactionlistClient = new TransactionListClientImpl();
  class TransactionListDetailClientImpl extends Observable<
    TransactionLisDetailtItem,
    number
  > {
    static tablesForTransaction: ReadonlyArray<string> = [
      'TransactionListDetail',
    ];
    add(
      arg: TransactionListDetailAddArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<TransactionLisDetailtItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['TransactionListDetail'], 'readwrite');
        const store = tx.objectStore('TransactionListDetail');
        const [] = await Promise.all([]);
        const DBAddRequest: IDBRequest = store.add({
          ...arg,
        });
        DBAddRequest.onerror = () => {
          if (DBAddRequest != null) {
            reject(DBAddRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBAddRequest.onsuccess = () => {
          if (DBAddRequest != null) {
            const mergedResult: TransactionLisDetailtItem = {
              ...arg,
              id: DBAddRequest.result,
            } as TransactionLisDetailtItem;
            this._push('add', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    put(
      arg: TransactionListDetailPutArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<TransactionLisDetailtItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['TransactionListDetail'], 'readwrite');
        const store = tx.objectStore('TransactionListDetail');
        const [] = await Promise.all([]);
        const DBPutRequest: IDBRequest = store.put({
          ...arg,
        });
        DBPutRequest.onerror = () => {
          if (DBPutRequest != null) {
            reject(DBPutRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBPutRequest.onsuccess = () => {
          if (DBPutRequest != null) {
            const mergedResult: TransactionLisDetailtItem = {
              ...arg,
              id: DBPutRequest.result,
            } as TransactionLisDetailtItem;
            this._push('put', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    delete(
      arg: TransactionListDetailDeleteArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['TransactionListDetail'], 'readwrite');
        const store = tx.objectStore('TransactionListDetail');
        const idToDelete: number = isTransactionListDetailIdIndex(arg)
          ? arg.id
          : arg;
        const DBDeleteRequest: IDBRequest = store.delete(idToDelete);
        if (DBDeleteRequest != null) {
          DBDeleteRequest.onerror = () => {
            if (DBDeleteRequest != null) {
              reject(DBDeleteRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBDeleteRequest.onsuccess = () => {
            this._push('delete', idToDelete);
            resolve(undefined);
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    get(
      arg: TransactionListDetailGetArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<TransactionLisDetailtItem> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['TransactionListDetail'], 'readonly');
        const store = tx.objectStore('TransactionListDetail');
        let DBGetRequest: IDBRequest | null = null;
        if (isTransactionListDetailIdIndex(arg)) {
          DBGetRequest = store.get(arg.id);
        } else if (isTransactionListDetailTransactionIdIndex(arg)) {
          const index: IDBIndex = store.index('transactionId');
          DBGetRequest = index.get([arg.transactionId]);
        } else if (isTransactionListDetailDataDetailsIndex(arg)) {
          const index: IDBIndex = store.index('dataDetails');
          DBGetRequest = index.get([arg.dataDetails]);
        } else {
          DBGetRequest = store.get(arg);
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    where<IndexName extends TransactionListDetailIndexes>(
      indexName: IndexName,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
      },
    ): RangeQuery<
      TransactionListDetailWhereQueryType<IndexName>,
      Promise<ReadonlyArray<TransactionLisDetailtItem>>
    > {
      const tx =
        options?.transaction ??
        db.transaction(['TransactionListDetail'], 'readonly');
      const store = tx.objectStore('TransactionListDetail');
      const executeQuery = (
        target: IDBObjectStore | IDBIndex,
        range: IDBKeyRange,
      ) => {
        return new Promise<ReadonlyArray<TransactionLisDetailtItem>>(
          async (resolve, reject) => {
            const DBGetRequest: IDBRequest = target.getAll(range);
            if (DBGetRequest != null) {
              DBGetRequest.onerror = () => {
                if (DBGetRequest != null) {
                  reject(DBGetRequest.error);
                } else {
                  reject(
                    new Error(
                      'Unknown error occurred trying to perform operation',
                    ),
                  );
                }
              };
              DBGetRequest.onsuccess = () => {
                if (DBGetRequest != null && DBGetRequest.result != null) {
                  resolve((DBGetRequest as IDBRequest).result);
                } else {
                  reject(new Error('No result found for query'));
                }
              };
            } else {
              reject(new Error('No available index for given query'));
            }
          },
        );
      };
      return {
        isGreaterThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, true));
            }
            case 'transactionId': {
              return executeQuery(
                store.index('transactionId'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            case 'dataDetails': {
              return executeQuery(
                store.index('dataDetails'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isGreaterThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, false));
            }
            case 'transactionId': {
              return executeQuery(
                store.index('transactionId'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            case 'dataDetails': {
              return executeQuery(
                store.index('dataDetails'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, true));
            }
            case 'transactionId': {
              return executeQuery(
                store.index('transactionId'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            case 'dataDetails': {
              return executeQuery(
                store.index('dataDetails'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, false));
            }
            case 'transactionId': {
              return executeQuery(
                store.index('transactionId'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            case 'dataDetails': {
              return executeQuery(
                store.index('dataDetails'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isBetween(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(
                store,
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'transactionId': {
              return executeQuery(
                store.index('transactionId'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'dataDetails': {
              return executeQuery(
                store.index('dataDetails'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.only(query));
            }
            case 'transactionId': {
              return executeQuery(
                store.index('transactionId'),
                IDBKeyRange.only(query),
              );
            }
            case 'dataDetails': {
              return executeQuery(
                store.index('dataDetails'),
                IDBKeyRange.only(query),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
      };
    }
    sortBy(
      indexName: TransactionListDetailIndexes,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
        count?: number;
      },
    ): Promise<ReadonlyArray<TransactionLisDetailtItem>> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['TransactionListDetail'], 'readonly');
        const store = tx.objectStore('TransactionListDetail');
        let DBGetRequest: IDBRequest | null = null;
        switch (indexName) {
          case 'id': {
            DBGetRequest = store.getAll(undefined, options?.count);
            break;
          }
          case 'transactionId': {
            const index: IDBIndex = store.index('transactionId');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          case 'dataDetails': {
            const index: IDBIndex = store.index('dataDetails');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          default: {
            return Promise.reject(
              new Error('Trying to run query on unknown index: ' + indexName),
            );
          }
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
  }
  const transactionlistdetailClient = new TransactionListDetailClientImpl();
  class CategoriesListClientImpl extends Observable<
    CategoriesListItem,
    number
  > {
    static tablesForTransaction: ReadonlyArray<string> = ['CategoriesList'];
    add(
      arg: CategoriesListAddArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<CategoriesListItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['CategoriesList'], 'readwrite');
        const store = tx.objectStore('CategoriesList');
        const [] = await Promise.all([]);
        const DBAddRequest: IDBRequest = store.add({
          ...arg,
        });
        DBAddRequest.onerror = () => {
          if (DBAddRequest != null) {
            reject(DBAddRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBAddRequest.onsuccess = () => {
          if (DBAddRequest != null) {
            const mergedResult: CategoriesListItem = {
              ...arg,
              id: DBAddRequest.result,
            } as CategoriesListItem;
            this._push('add', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    put(
      arg: CategoriesListPutArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<CategoriesListItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['CategoriesList'], 'readwrite');
        const store = tx.objectStore('CategoriesList');
        const [] = await Promise.all([]);
        const DBPutRequest: IDBRequest = store.put({
          ...arg,
        });
        DBPutRequest.onerror = () => {
          if (DBPutRequest != null) {
            reject(DBPutRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBPutRequest.onsuccess = () => {
          if (DBPutRequest != null) {
            const mergedResult: CategoriesListItem = {
              ...arg,
              id: DBPutRequest.result,
            } as CategoriesListItem;
            this._push('put', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    delete(
      arg: CategoriesListDeleteArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['CategoriesList'], 'readwrite');
        const store = tx.objectStore('CategoriesList');
        const idToDelete: number = isCategoriesListIdIndex(arg) ? arg.id : arg;
        const DBDeleteRequest: IDBRequest = store.delete(idToDelete);
        if (DBDeleteRequest != null) {
          DBDeleteRequest.onerror = () => {
            if (DBDeleteRequest != null) {
              reject(DBDeleteRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBDeleteRequest.onsuccess = () => {
            this._push('delete', idToDelete);
            resolve(undefined);
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    get(
      arg: CategoriesListGetArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<CategoriesListItem> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['CategoriesList'], 'readonly');
        const store = tx.objectStore('CategoriesList');
        let DBGetRequest: IDBRequest | null = null;
        if (isCategoriesListIdIndex(arg)) {
          DBGetRequest = store.get(arg.id);
        } else if (isCategoriesListCategoriesIdIndex(arg)) {
          const index: IDBIndex = store.index('CategoriesId');
          DBGetRequest = index.get([arg.CategoriesId]);
        } else if (isCategoriesListDataIndex(arg)) {
          const index: IDBIndex = store.index('data');
          DBGetRequest = index.get([arg.data]);
        } else {
          DBGetRequest = store.get(arg);
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    where<IndexName extends CategoriesListIndexes>(
      indexName: IndexName,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
      },
    ): RangeQuery<
      CategoriesListWhereQueryType<IndexName>,
      Promise<ReadonlyArray<CategoriesListItem>>
    > {
      const tx =
        options?.transaction ?? db.transaction(['CategoriesList'], 'readonly');
      const store = tx.objectStore('CategoriesList');
      const executeQuery = (
        target: IDBObjectStore | IDBIndex,
        range: IDBKeyRange,
      ) => {
        return new Promise<ReadonlyArray<CategoriesListItem>>(
          async (resolve, reject) => {
            const DBGetRequest: IDBRequest = target.getAll(range);
            if (DBGetRequest != null) {
              DBGetRequest.onerror = () => {
                if (DBGetRequest != null) {
                  reject(DBGetRequest.error);
                } else {
                  reject(
                    new Error(
                      'Unknown error occurred trying to perform operation',
                    ),
                  );
                }
              };
              DBGetRequest.onsuccess = () => {
                if (DBGetRequest != null && DBGetRequest.result != null) {
                  resolve((DBGetRequest as IDBRequest).result);
                } else {
                  reject(new Error('No result found for query'));
                }
              };
            } else {
              reject(new Error('No available index for given query'));
            }
          },
        );
      };
      return {
        isGreaterThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, true));
            }
            case 'CategoriesId': {
              return executeQuery(
                store.index('CategoriesId'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            case 'data': {
              return executeQuery(
                store.index('data'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isGreaterThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, false));
            }
            case 'CategoriesId': {
              return executeQuery(
                store.index('CategoriesId'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            case 'data': {
              return executeQuery(
                store.index('data'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, true));
            }
            case 'CategoriesId': {
              return executeQuery(
                store.index('CategoriesId'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            case 'data': {
              return executeQuery(
                store.index('data'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, false));
            }
            case 'CategoriesId': {
              return executeQuery(
                store.index('CategoriesId'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            case 'data': {
              return executeQuery(
                store.index('data'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isBetween(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(
                store,
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'CategoriesId': {
              return executeQuery(
                store.index('CategoriesId'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'data': {
              return executeQuery(
                store.index('data'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.only(query));
            }
            case 'CategoriesId': {
              return executeQuery(
                store.index('CategoriesId'),
                IDBKeyRange.only(query),
              );
            }
            case 'data': {
              return executeQuery(store.index('data'), IDBKeyRange.only(query));
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
      };
    }
    sortBy(
      indexName: CategoriesListIndexes,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
        count?: number;
      },
    ): Promise<ReadonlyArray<CategoriesListItem>> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['CategoriesList'], 'readonly');
        const store = tx.objectStore('CategoriesList');
        let DBGetRequest: IDBRequest | null = null;
        switch (indexName) {
          case 'id': {
            DBGetRequest = store.getAll(undefined, options?.count);
            break;
          }
          case 'CategoriesId': {
            const index: IDBIndex = store.index('CategoriesId');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          case 'data': {
            const index: IDBIndex = store.index('data');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          default: {
            return Promise.reject(
              new Error('Trying to run query on unknown index: ' + indexName),
            );
          }
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
  }
  const categorieslistClient = new CategoriesListClientImpl();
  class AccountForStateListClientImpl extends Observable<
    accountForStateListItem,
    number
  > {
    static tablesForTransaction: ReadonlyArray<string> = [
      'accountForStateList',
    ];
    add(
      arg: AccountForStateListAddArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<accountForStateListItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['accountForStateList'], 'readwrite');
        const store = tx.objectStore('accountForStateList');
        const [] = await Promise.all([]);
        const DBAddRequest: IDBRequest = store.add({
          ...arg,
        });
        DBAddRequest.onerror = () => {
          if (DBAddRequest != null) {
            reject(DBAddRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBAddRequest.onsuccess = () => {
          if (DBAddRequest != null) {
            const mergedResult: accountForStateListItem = {
              ...arg,
              id: DBAddRequest.result,
            } as accountForStateListItem;
            this._push('add', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    put(
      arg: AccountForStateListPutArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<accountForStateListItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['accountForStateList'], 'readwrite');
        const store = tx.objectStore('accountForStateList');
        const [] = await Promise.all([]);
        const DBPutRequest: IDBRequest = store.put({
          ...arg,
        });
        DBPutRequest.onerror = () => {
          if (DBPutRequest != null) {
            reject(DBPutRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBPutRequest.onsuccess = () => {
          if (DBPutRequest != null) {
            const mergedResult: accountForStateListItem = {
              ...arg,
              id: DBPutRequest.result,
            } as accountForStateListItem;
            this._push('put', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    delete(
      arg: AccountForStateListDeleteArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['accountForStateList'], 'readwrite');
        const store = tx.objectStore('accountForStateList');
        const idToDelete: number = isAccountForStateListIdIndex(arg)
          ? arg.id
          : arg;
        const DBDeleteRequest: IDBRequest = store.delete(idToDelete);
        if (DBDeleteRequest != null) {
          DBDeleteRequest.onerror = () => {
            if (DBDeleteRequest != null) {
              reject(DBDeleteRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBDeleteRequest.onsuccess = () => {
            this._push('delete', idToDelete);
            resolve(undefined);
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    get(
      arg: AccountForStateListGetArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<accountForStateListItem> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['accountForStateList'], 'readonly');
        const store = tx.objectStore('accountForStateList');
        let DBGetRequest: IDBRequest | null = null;
        if (isAccountForStateListIdIndex(arg)) {
          DBGetRequest = store.get(arg.id);
        } else if (isAccountForStateListAccountForStateIdIndex(arg)) {
          const index: IDBIndex = store.index('accountForStateId');
          DBGetRequest = index.get([arg.accountForStateId]);
        } else if (isAccountForStateListDataIndex(arg)) {
          const index: IDBIndex = store.index('data');
          DBGetRequest = index.get([arg.data]);
        } else {
          DBGetRequest = store.get(arg);
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    where<IndexName extends AccountForStateListIndexes>(
      indexName: IndexName,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
      },
    ): RangeQuery<
      AccountForStateListWhereQueryType<IndexName>,
      Promise<ReadonlyArray<accountForStateListItem>>
    > {
      const tx =
        options?.transaction ??
        db.transaction(['accountForStateList'], 'readonly');
      const store = tx.objectStore('accountForStateList');
      const executeQuery = (
        target: IDBObjectStore | IDBIndex,
        range: IDBKeyRange,
      ) => {
        return new Promise<ReadonlyArray<accountForStateListItem>>(
          async (resolve, reject) => {
            const DBGetRequest: IDBRequest = target.getAll(range);
            if (DBGetRequest != null) {
              DBGetRequest.onerror = () => {
                if (DBGetRequest != null) {
                  reject(DBGetRequest.error);
                } else {
                  reject(
                    new Error(
                      'Unknown error occurred trying to perform operation',
                    ),
                  );
                }
              };
              DBGetRequest.onsuccess = () => {
                if (DBGetRequest != null && DBGetRequest.result != null) {
                  resolve((DBGetRequest as IDBRequest).result);
                } else {
                  reject(new Error('No result found for query'));
                }
              };
            } else {
              reject(new Error('No available index for given query'));
            }
          },
        );
      };
      return {
        isGreaterThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, true));
            }
            case 'accountForStateId': {
              return executeQuery(
                store.index('accountForStateId'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            case 'data': {
              return executeQuery(
                store.index('data'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isGreaterThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, false));
            }
            case 'accountForStateId': {
              return executeQuery(
                store.index('accountForStateId'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            case 'data': {
              return executeQuery(
                store.index('data'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, true));
            }
            case 'accountForStateId': {
              return executeQuery(
                store.index('accountForStateId'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            case 'data': {
              return executeQuery(
                store.index('data'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, false));
            }
            case 'accountForStateId': {
              return executeQuery(
                store.index('accountForStateId'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            case 'data': {
              return executeQuery(
                store.index('data'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isBetween(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(
                store,
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'accountForStateId': {
              return executeQuery(
                store.index('accountForStateId'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'data': {
              return executeQuery(
                store.index('data'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.only(query));
            }
            case 'accountForStateId': {
              return executeQuery(
                store.index('accountForStateId'),
                IDBKeyRange.only(query),
              );
            }
            case 'data': {
              return executeQuery(store.index('data'), IDBKeyRange.only(query));
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
      };
    }
    sortBy(
      indexName: AccountForStateListIndexes,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
        count?: number;
      },
    ): Promise<ReadonlyArray<accountForStateListItem>> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['accountForStateList'], 'readonly');
        const store = tx.objectStore('accountForStateList');
        let DBGetRequest: IDBRequest | null = null;
        switch (indexName) {
          case 'id': {
            DBGetRequest = store.getAll(undefined, options?.count);
            break;
          }
          case 'accountForStateId': {
            const index: IDBIndex = store.index('accountForStateId');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          case 'data': {
            const index: IDBIndex = store.index('data');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          default: {
            return Promise.reject(
              new Error('Trying to run query on unknown index: ' + indexName),
            );
          }
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
  }
  const accountforstatelistClient = new AccountForStateListClientImpl();
  class DataStatisticListClientImpl extends Observable<
    dataStatisticListItem,
    number
  > {
    static tablesForTransaction: ReadonlyArray<string> = ['DataStatisticList'];
    add(
      arg: DataStatisticListAddArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<dataStatisticListItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['DataStatisticList'], 'readwrite');
        const store = tx.objectStore('DataStatisticList');
        const [] = await Promise.all([]);
        const DBAddRequest: IDBRequest = store.add({
          ...arg,
        });
        DBAddRequest.onerror = () => {
          if (DBAddRequest != null) {
            reject(DBAddRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBAddRequest.onsuccess = () => {
          if (DBAddRequest != null) {
            const mergedResult: dataStatisticListItem = {
              ...arg,
              id: DBAddRequest.result,
            } as dataStatisticListItem;
            this._push('add', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    put(
      arg: DataStatisticListPutArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<dataStatisticListItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['DataStatisticList'], 'readwrite');
        const store = tx.objectStore('DataStatisticList');
        const [] = await Promise.all([]);
        const DBPutRequest: IDBRequest = store.put({
          ...arg,
        });
        DBPutRequest.onerror = () => {
          if (DBPutRequest != null) {
            reject(DBPutRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBPutRequest.onsuccess = () => {
          if (DBPutRequest != null) {
            const mergedResult: dataStatisticListItem = {
              ...arg,
              id: DBPutRequest.result,
            } as dataStatisticListItem;
            this._push('put', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    delete(
      arg: DataStatisticListDeleteArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['DataStatisticList'], 'readwrite');
        const store = tx.objectStore('DataStatisticList');
        const idToDelete: number = isDataStatisticListIdIndex(arg)
          ? arg.id
          : arg;
        const DBDeleteRequest: IDBRequest = store.delete(idToDelete);
        if (DBDeleteRequest != null) {
          DBDeleteRequest.onerror = () => {
            if (DBDeleteRequest != null) {
              reject(DBDeleteRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBDeleteRequest.onsuccess = () => {
            this._push('delete', idToDelete);
            resolve(undefined);
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    get(
      arg: DataStatisticListGetArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<dataStatisticListItem> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['DataStatisticList'], 'readonly');
        const store = tx.objectStore('DataStatisticList');
        let DBGetRequest: IDBRequest | null = null;
        if (isDataStatisticListIdIndex(arg)) {
          DBGetRequest = store.get(arg.id);
        } else if (isDataStatisticListDataCategoriesTrIndex(arg)) {
          const index: IDBIndex = store.index('dataCategoriesTr');
          DBGetRequest = index.get([arg.dataCategoriesTr]);
        } else {
          DBGetRequest = store.get(arg);
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    where<IndexName extends DataStatisticListIndexes>(
      indexName: IndexName,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
      },
    ): RangeQuery<
      DataStatisticListWhereQueryType<IndexName>,
      Promise<ReadonlyArray<dataStatisticListItem>>
    > {
      const tx =
        options?.transaction ??
        db.transaction(['DataStatisticList'], 'readonly');
      const store = tx.objectStore('DataStatisticList');
      const executeQuery = (
        target: IDBObjectStore | IDBIndex,
        range: IDBKeyRange,
      ) => {
        return new Promise<ReadonlyArray<dataStatisticListItem>>(
          async (resolve, reject) => {
            const DBGetRequest: IDBRequest = target.getAll(range);
            if (DBGetRequest != null) {
              DBGetRequest.onerror = () => {
                if (DBGetRequest != null) {
                  reject(DBGetRequest.error);
                } else {
                  reject(
                    new Error(
                      'Unknown error occurred trying to perform operation',
                    ),
                  );
                }
              };
              DBGetRequest.onsuccess = () => {
                if (DBGetRequest != null && DBGetRequest.result != null) {
                  resolve((DBGetRequest as IDBRequest).result);
                } else {
                  reject(new Error('No result found for query'));
                }
              };
            } else {
              reject(new Error('No available index for given query'));
            }
          },
        );
      };
      return {
        isGreaterThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, true));
            }
            case 'dataCategoriesTr': {
              return executeQuery(
                store.index('dataCategoriesTr'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isGreaterThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, false));
            }
            case 'dataCategoriesTr': {
              return executeQuery(
                store.index('dataCategoriesTr'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, true));
            }
            case 'dataCategoriesTr': {
              return executeQuery(
                store.index('dataCategoriesTr'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, false));
            }
            case 'dataCategoriesTr': {
              return executeQuery(
                store.index('dataCategoriesTr'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isBetween(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(
                store,
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'dataCategoriesTr': {
              return executeQuery(
                store.index('dataCategoriesTr'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.only(query));
            }
            case 'dataCategoriesTr': {
              return executeQuery(
                store.index('dataCategoriesTr'),
                IDBKeyRange.only(query),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
      };
    }
    sortBy(
      indexName: DataStatisticListIndexes,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
        count?: number;
      },
    ): Promise<ReadonlyArray<dataStatisticListItem>> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['DataStatisticList'], 'readonly');
        const store = tx.objectStore('DataStatisticList');
        let DBGetRequest: IDBRequest | null = null;
        switch (indexName) {
          case 'id': {
            DBGetRequest = store.getAll(undefined, options?.count);
            break;
          }
          case 'dataCategoriesTr': {
            const index: IDBIndex = store.index('dataCategoriesTr');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          default: {
            return Promise.reject(
              new Error('Trying to run query on unknown index: ' + indexName),
            );
          }
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
  }
  const datastatisticlistClient = new DataStatisticListClientImpl();
  class DataTableBarStatisticListClientImpl extends Observable<
    dataTableBarStatisticListItem,
    number
  > {
    static tablesForTransaction: ReadonlyArray<string> = [
      'DataTableBarStatisticList',
    ];
    add(
      arg: DataTableBarStatisticListAddArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<dataTableBarStatisticListItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['DataTableBarStatisticList'], 'readwrite');
        const store = tx.objectStore('DataTableBarStatisticList');
        const [] = await Promise.all([]);
        const DBAddRequest: IDBRequest = store.add({
          ...arg,
        });
        DBAddRequest.onerror = () => {
          if (DBAddRequest != null) {
            reject(DBAddRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBAddRequest.onsuccess = () => {
          if (DBAddRequest != null) {
            const mergedResult: dataTableBarStatisticListItem = {
              ...arg,
              id: DBAddRequest.result,
            } as dataTableBarStatisticListItem;
            this._push('add', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    put(
      arg: DataTableBarStatisticListPutArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<dataTableBarStatisticListItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['DataTableBarStatisticList'], 'readwrite');
        const store = tx.objectStore('DataTableBarStatisticList');
        const [] = await Promise.all([]);
        const DBPutRequest: IDBRequest = store.put({
          ...arg,
        });
        DBPutRequest.onerror = () => {
          if (DBPutRequest != null) {
            reject(DBPutRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBPutRequest.onsuccess = () => {
          if (DBPutRequest != null) {
            const mergedResult: dataTableBarStatisticListItem = {
              ...arg,
              id: DBPutRequest.result,
            } as dataTableBarStatisticListItem;
            this._push('put', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    delete(
      arg: DataTableBarStatisticListDeleteArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['DataTableBarStatisticList'], 'readwrite');
        const store = tx.objectStore('DataTableBarStatisticList');
        const idToDelete: number = isDataTableBarStatisticListIdIndex(arg)
          ? arg.id
          : arg;
        const DBDeleteRequest: IDBRequest = store.delete(idToDelete);
        if (DBDeleteRequest != null) {
          DBDeleteRequest.onerror = () => {
            if (DBDeleteRequest != null) {
              reject(DBDeleteRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBDeleteRequest.onsuccess = () => {
            this._push('delete', idToDelete);
            resolve(undefined);
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    get(
      arg: DataTableBarStatisticListGetArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<dataTableBarStatisticListItem> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['DataTableBarStatisticList'], 'readonly');
        const store = tx.objectStore('DataTableBarStatisticList');
        let DBGetRequest: IDBRequest | null = null;
        if (isDataTableBarStatisticListIdIndex(arg)) {
          DBGetRequest = store.get(arg.id);
        } else if (isDataTableBarStatisticListDataTableBarIndex(arg)) {
          const index: IDBIndex = store.index('dataTableBar');
          DBGetRequest = index.get([arg.dataTableBar]);
        } else {
          DBGetRequest = store.get(arg);
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    where<IndexName extends DataTableBarStatisticListIndexes>(
      indexName: IndexName,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
      },
    ): RangeQuery<
      DataTableBarStatisticListWhereQueryType<IndexName>,
      Promise<ReadonlyArray<dataTableBarStatisticListItem>>
    > {
      const tx =
        options?.transaction ??
        db.transaction(['DataTableBarStatisticList'], 'readonly');
      const store = tx.objectStore('DataTableBarStatisticList');
      const executeQuery = (
        target: IDBObjectStore | IDBIndex,
        range: IDBKeyRange,
      ) => {
        return new Promise<ReadonlyArray<dataTableBarStatisticListItem>>(
          async (resolve, reject) => {
            const DBGetRequest: IDBRequest = target.getAll(range);
            if (DBGetRequest != null) {
              DBGetRequest.onerror = () => {
                if (DBGetRequest != null) {
                  reject(DBGetRequest.error);
                } else {
                  reject(
                    new Error(
                      'Unknown error occurred trying to perform operation',
                    ),
                  );
                }
              };
              DBGetRequest.onsuccess = () => {
                if (DBGetRequest != null && DBGetRequest.result != null) {
                  resolve((DBGetRequest as IDBRequest).result);
                } else {
                  reject(new Error('No result found for query'));
                }
              };
            } else {
              reject(new Error('No available index for given query'));
            }
          },
        );
      };
      return {
        isGreaterThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, true));
            }
            case 'dataTableBar': {
              return executeQuery(
                store.index('dataTableBar'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isGreaterThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, false));
            }
            case 'dataTableBar': {
              return executeQuery(
                store.index('dataTableBar'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, true));
            }
            case 'dataTableBar': {
              return executeQuery(
                store.index('dataTableBar'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, false));
            }
            case 'dataTableBar': {
              return executeQuery(
                store.index('dataTableBar'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isBetween(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(
                store,
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'dataTableBar': {
              return executeQuery(
                store.index('dataTableBar'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.only(query));
            }
            case 'dataTableBar': {
              return executeQuery(
                store.index('dataTableBar'),
                IDBKeyRange.only(query),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
      };
    }
    sortBy(
      indexName: DataTableBarStatisticListIndexes,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
        count?: number;
      },
    ): Promise<ReadonlyArray<dataTableBarStatisticListItem>> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['DataTableBarStatisticList'], 'readonly');
        const store = tx.objectStore('DataTableBarStatisticList');
        let DBGetRequest: IDBRequest | null = null;
        switch (indexName) {
          case 'id': {
            DBGetRequest = store.getAll(undefined, options?.count);
            break;
          }
          case 'dataTableBar': {
            const index: IDBIndex = store.index('dataTableBar');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          default: {
            return Promise.reject(
              new Error('Trying to run query on unknown index: ' + indexName),
            );
          }
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
  }
  const datatablebarstatisticlistClient =
    new DataTableBarStatisticListClientImpl();
  class DataAccountStateClientImpl extends Observable<
    dataAccountStateItem,
    number
  > {
    static tablesForTransaction: ReadonlyArray<string> = ['DataAccountState'];
    add(
      arg: DataAccountStateAddArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<dataAccountStateItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['DataAccountState'], 'readwrite');
        const store = tx.objectStore('DataAccountState');
        const [] = await Promise.all([]);
        const DBAddRequest: IDBRequest = store.add({
          ...arg,
        });
        DBAddRequest.onerror = () => {
          if (DBAddRequest != null) {
            reject(DBAddRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBAddRequest.onsuccess = () => {
          if (DBAddRequest != null) {
            const mergedResult: dataAccountStateItem = {
              ...arg,
              id: DBAddRequest.result,
            } as dataAccountStateItem;
            this._push('add', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    put(
      arg: DataAccountStatePutArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<dataAccountStateItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['DataAccountState'], 'readwrite');
        const store = tx.objectStore('DataAccountState');
        const [] = await Promise.all([]);
        const DBPutRequest: IDBRequest = store.put({
          ...arg,
        });
        DBPutRequest.onerror = () => {
          if (DBPutRequest != null) {
            reject(DBPutRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBPutRequest.onsuccess = () => {
          if (DBPutRequest != null) {
            const mergedResult: dataAccountStateItem = {
              ...arg,
              id: DBPutRequest.result,
            } as dataAccountStateItem;
            this._push('put', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    delete(
      arg: DataAccountStateDeleteArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['DataAccountState'], 'readwrite');
        const store = tx.objectStore('DataAccountState');
        const idToDelete: number = isDataAccountStateIdIndex(arg)
          ? arg.id
          : arg;
        const DBDeleteRequest: IDBRequest = store.delete(idToDelete);
        if (DBDeleteRequest != null) {
          DBDeleteRequest.onerror = () => {
            if (DBDeleteRequest != null) {
              reject(DBDeleteRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBDeleteRequest.onsuccess = () => {
            this._push('delete', idToDelete);
            resolve(undefined);
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    get(
      arg: DataAccountStateGetArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<dataAccountStateItem> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['DataAccountState'], 'readonly');
        const store = tx.objectStore('DataAccountState');
        let DBGetRequest: IDBRequest | null = null;
        if (isDataAccountStateIdIndex(arg)) {
          DBGetRequest = store.get(arg.id);
        } else if (isDataAccountStateDataAccountStateIndex(arg)) {
          const index: IDBIndex = store.index('dataAccountState');
          DBGetRequest = index.get([arg.dataAccountState]);
        } else {
          DBGetRequest = store.get(arg);
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    where<IndexName extends DataAccountStateIndexes>(
      indexName: IndexName,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
      },
    ): RangeQuery<
      DataAccountStateWhereQueryType<IndexName>,
      Promise<ReadonlyArray<dataAccountStateItem>>
    > {
      const tx =
        options?.transaction ??
        db.transaction(['DataAccountState'], 'readonly');
      const store = tx.objectStore('DataAccountState');
      const executeQuery = (
        target: IDBObjectStore | IDBIndex,
        range: IDBKeyRange,
      ) => {
        return new Promise<ReadonlyArray<dataAccountStateItem>>(
          async (resolve, reject) => {
            const DBGetRequest: IDBRequest = target.getAll(range);
            if (DBGetRequest != null) {
              DBGetRequest.onerror = () => {
                if (DBGetRequest != null) {
                  reject(DBGetRequest.error);
                } else {
                  reject(
                    new Error(
                      'Unknown error occurred trying to perform operation',
                    ),
                  );
                }
              };
              DBGetRequest.onsuccess = () => {
                if (DBGetRequest != null && DBGetRequest.result != null) {
                  resolve((DBGetRequest as IDBRequest).result);
                } else {
                  reject(new Error('No result found for query'));
                }
              };
            } else {
              reject(new Error('No available index for given query'));
            }
          },
        );
      };
      return {
        isGreaterThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, true));
            }
            case 'dataAccountState': {
              return executeQuery(
                store.index('dataAccountState'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isGreaterThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, false));
            }
            case 'dataAccountState': {
              return executeQuery(
                store.index('dataAccountState'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, true));
            }
            case 'dataAccountState': {
              return executeQuery(
                store.index('dataAccountState'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, false));
            }
            case 'dataAccountState': {
              return executeQuery(
                store.index('dataAccountState'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isBetween(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(
                store,
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'dataAccountState': {
              return executeQuery(
                store.index('dataAccountState'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.only(query));
            }
            case 'dataAccountState': {
              return executeQuery(
                store.index('dataAccountState'),
                IDBKeyRange.only(query),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
      };
    }
    sortBy(
      indexName: DataAccountStateIndexes,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
        count?: number;
      },
    ): Promise<ReadonlyArray<dataAccountStateItem>> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['DataAccountState'], 'readonly');
        const store = tx.objectStore('DataAccountState');
        let DBGetRequest: IDBRequest | null = null;
        switch (indexName) {
          case 'id': {
            DBGetRequest = store.getAll(undefined, options?.count);
            break;
          }
          case 'dataAccountState': {
            const index: IDBIndex = store.index('dataAccountState');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          default: {
            return Promise.reject(
              new Error('Trying to run query on unknown index: ' + indexName),
            );
          }
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
  }
  const dataaccountstateClient = new DataAccountStateClientImpl();
  class MembersDataCardsClientImpl extends Observable<
    TimMembersDataCard,
    number
  > {
    static tablesForTransaction: ReadonlyArray<string> = ['MembersDataCards'];
    add(
      arg: MembersDataCardsAddArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<TimMembersDataCard> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['MembersDataCards'], 'readwrite');
        const store = tx.objectStore('MembersDataCards');
        const [] = await Promise.all([]);
        const DBAddRequest: IDBRequest = store.add({
          ...arg,
        });
        DBAddRequest.onerror = () => {
          if (DBAddRequest != null) {
            reject(DBAddRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBAddRequest.onsuccess = () => {
          if (DBAddRequest != null) {
            const mergedResult: TimMembersDataCard = {
              ...arg,
              id: DBAddRequest.result,
            } as TimMembersDataCard;
            this._push('add', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    put(
      arg: MembersDataCardsPutArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<TimMembersDataCard> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['MembersDataCards'], 'readwrite');
        const store = tx.objectStore('MembersDataCards');
        const [] = await Promise.all([]);
        const DBPutRequest: IDBRequest = store.put({
          ...arg,
        });
        DBPutRequest.onerror = () => {
          if (DBPutRequest != null) {
            reject(DBPutRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBPutRequest.onsuccess = () => {
          if (DBPutRequest != null) {
            const mergedResult: TimMembersDataCard = {
              ...arg,
              id: DBPutRequest.result,
            } as TimMembersDataCard;
            this._push('put', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    delete(
      arg: MembersDataCardsDeleteArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['MembersDataCards'], 'readwrite');
        const store = tx.objectStore('MembersDataCards');
        const idToDelete: number = isMembersDataCardsIdIndex(arg)
          ? arg.id
          : arg;
        const DBDeleteRequest: IDBRequest = store.delete(idToDelete);
        if (DBDeleteRequest != null) {
          DBDeleteRequest.onerror = () => {
            if (DBDeleteRequest != null) {
              reject(DBDeleteRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBDeleteRequest.onsuccess = () => {
            this._push('delete', idToDelete);
            resolve(undefined);
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    get(
      arg: MembersDataCardsGetArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<TimMembersDataCard> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['MembersDataCards'], 'readonly');
        const store = tx.objectStore('MembersDataCards');
        let DBGetRequest: IDBRequest | null = null;
        if (isMembersDataCardsIdIndex(arg)) {
          DBGetRequest = store.get(arg.id);
        } else if (isMembersDataCardsMembresDataIndex(arg)) {
          const index: IDBIndex = store.index('membresData');
          DBGetRequest = index.get([arg.membresData]);
        } else {
          DBGetRequest = store.get(arg);
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    where<IndexName extends MembersDataCardsIndexes>(
      indexName: IndexName,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
      },
    ): RangeQuery<
      MembersDataCardsWhereQueryType<IndexName>,
      Promise<ReadonlyArray<TimMembersDataCard>>
    > {
      const tx =
        options?.transaction ??
        db.transaction(['MembersDataCards'], 'readonly');
      const store = tx.objectStore('MembersDataCards');
      const executeQuery = (
        target: IDBObjectStore | IDBIndex,
        range: IDBKeyRange,
      ) => {
        return new Promise<ReadonlyArray<TimMembersDataCard>>(
          async (resolve, reject) => {
            const DBGetRequest: IDBRequest = target.getAll(range);
            if (DBGetRequest != null) {
              DBGetRequest.onerror = () => {
                if (DBGetRequest != null) {
                  reject(DBGetRequest.error);
                } else {
                  reject(
                    new Error(
                      'Unknown error occurred trying to perform operation',
                    ),
                  );
                }
              };
              DBGetRequest.onsuccess = () => {
                if (DBGetRequest != null && DBGetRequest.result != null) {
                  resolve((DBGetRequest as IDBRequest).result);
                } else {
                  reject(new Error('No result found for query'));
                }
              };
            } else {
              reject(new Error('No available index for given query'));
            }
          },
        );
      };
      return {
        isGreaterThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, true));
            }
            case 'membresData': {
              return executeQuery(
                store.index('membresData'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isGreaterThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, false));
            }
            case 'membresData': {
              return executeQuery(
                store.index('membresData'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, true));
            }
            case 'membresData': {
              return executeQuery(
                store.index('membresData'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, false));
            }
            case 'membresData': {
              return executeQuery(
                store.index('membresData'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isBetween(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(
                store,
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'membresData': {
              return executeQuery(
                store.index('membresData'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.only(query));
            }
            case 'membresData': {
              return executeQuery(
                store.index('membresData'),
                IDBKeyRange.only(query),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
      };
    }
    sortBy(
      indexName: MembersDataCardsIndexes,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
        count?: number;
      },
    ): Promise<ReadonlyArray<TimMembersDataCard>> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['MembersDataCards'], 'readonly');
        const store = tx.objectStore('MembersDataCards');
        let DBGetRequest: IDBRequest | null = null;
        switch (indexName) {
          case 'id': {
            DBGetRequest = store.getAll(undefined, options?.count);
            break;
          }
          case 'membresData': {
            const index: IDBIndex = store.index('membresData');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          default: {
            return Promise.reject(
              new Error('Trying to run query on unknown index: ' + indexName),
            );
          }
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
  }
  const membersdatacardsClient = new MembersDataCardsClientImpl();
  class CategoriesCardDataClientImpl extends Observable<
    CategoriesCardDataItem,
    number
  > {
    static tablesForTransaction: ReadonlyArray<string> = ['CategoriesCardData'];
    add(
      arg: CategoriesCardDataAddArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<CategoriesCardDataItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['CategoriesCardData'], 'readwrite');
        const store = tx.objectStore('CategoriesCardData');
        const [] = await Promise.all([]);
        const DBAddRequest: IDBRequest = store.add({
          ...arg,
        });
        DBAddRequest.onerror = () => {
          if (DBAddRequest != null) {
            reject(DBAddRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBAddRequest.onsuccess = () => {
          if (DBAddRequest != null) {
            const mergedResult: CategoriesCardDataItem = {
              ...arg,
              id: DBAddRequest.result,
            } as CategoriesCardDataItem;
            this._push('add', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    put(
      arg: CategoriesCardDataPutArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<CategoriesCardDataItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['CategoriesCardData'], 'readwrite');
        const store = tx.objectStore('CategoriesCardData');
        const [] = await Promise.all([]);
        const DBPutRequest: IDBRequest = store.put({
          ...arg,
        });
        DBPutRequest.onerror = () => {
          if (DBPutRequest != null) {
            reject(DBPutRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBPutRequest.onsuccess = () => {
          if (DBPutRequest != null) {
            const mergedResult: CategoriesCardDataItem = {
              ...arg,
              id: DBPutRequest.result,
            } as CategoriesCardDataItem;
            this._push('put', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    delete(
      arg: CategoriesCardDataDeleteArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['CategoriesCardData'], 'readwrite');
        const store = tx.objectStore('CategoriesCardData');
        const idToDelete: number = isCategoriesCardDataIdIndex(arg)
          ? arg.id
          : arg;
        const DBDeleteRequest: IDBRequest = store.delete(idToDelete);
        if (DBDeleteRequest != null) {
          DBDeleteRequest.onerror = () => {
            if (DBDeleteRequest != null) {
              reject(DBDeleteRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBDeleteRequest.onsuccess = () => {
            this._push('delete', idToDelete);
            resolve(undefined);
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    get(
      arg: CategoriesCardDataGetArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<CategoriesCardDataItem> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['CategoriesCardData'], 'readonly');
        const store = tx.objectStore('CategoriesCardData');
        let DBGetRequest: IDBRequest | null = null;
        if (isCategoriesCardDataIdIndex(arg)) {
          DBGetRequest = store.get(arg.id);
        } else if (isCategoriesCardDataCategoriesCardIndex(arg)) {
          const index: IDBIndex = store.index('CategoriesCard');
          DBGetRequest = index.get([arg.CategoriesCard]);
        } else {
          DBGetRequest = store.get(arg);
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    where<IndexName extends CategoriesCardDataIndexes>(
      indexName: IndexName,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
      },
    ): RangeQuery<
      CategoriesCardDataWhereQueryType<IndexName>,
      Promise<ReadonlyArray<CategoriesCardDataItem>>
    > {
      const tx =
        options?.transaction ??
        db.transaction(['CategoriesCardData'], 'readonly');
      const store = tx.objectStore('CategoriesCardData');
      const executeQuery = (
        target: IDBObjectStore | IDBIndex,
        range: IDBKeyRange,
      ) => {
        return new Promise<ReadonlyArray<CategoriesCardDataItem>>(
          async (resolve, reject) => {
            const DBGetRequest: IDBRequest = target.getAll(range);
            if (DBGetRequest != null) {
              DBGetRequest.onerror = () => {
                if (DBGetRequest != null) {
                  reject(DBGetRequest.error);
                } else {
                  reject(
                    new Error(
                      'Unknown error occurred trying to perform operation',
                    ),
                  );
                }
              };
              DBGetRequest.onsuccess = () => {
                if (DBGetRequest != null && DBGetRequest.result != null) {
                  resolve((DBGetRequest as IDBRequest).result);
                } else {
                  reject(new Error('No result found for query'));
                }
              };
            } else {
              reject(new Error('No available index for given query'));
            }
          },
        );
      };
      return {
        isGreaterThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, true));
            }
            case 'CategoriesCard': {
              return executeQuery(
                store.index('CategoriesCard'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isGreaterThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, false));
            }
            case 'CategoriesCard': {
              return executeQuery(
                store.index('CategoriesCard'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, true));
            }
            case 'CategoriesCard': {
              return executeQuery(
                store.index('CategoriesCard'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, false));
            }
            case 'CategoriesCard': {
              return executeQuery(
                store.index('CategoriesCard'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isBetween(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(
                store,
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'CategoriesCard': {
              return executeQuery(
                store.index('CategoriesCard'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.only(query));
            }
            case 'CategoriesCard': {
              return executeQuery(
                store.index('CategoriesCard'),
                IDBKeyRange.only(query),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
      };
    }
    sortBy(
      indexName: CategoriesCardDataIndexes,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
        count?: number;
      },
    ): Promise<ReadonlyArray<CategoriesCardDataItem>> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ??
          db.transaction(['CategoriesCardData'], 'readonly');
        const store = tx.objectStore('CategoriesCardData');
        let DBGetRequest: IDBRequest | null = null;
        switch (indexName) {
          case 'id': {
            DBGetRequest = store.getAll(undefined, options?.count);
            break;
          }
          case 'CategoriesCard': {
            const index: IDBIndex = store.index('CategoriesCard');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          default: {
            return Promise.reject(
              new Error('Trying to run query on unknown index: ' + indexName),
            );
          }
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
  }
  const categoriescarddataClient = new CategoriesCardDataClientImpl();
  class DataSettingClientImpl extends Observable<dataSettingItem, number> {
    static tablesForTransaction: ReadonlyArray<string> = ['DataSetting'];
    add(
      arg: DataSettingAddArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<dataSettingItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(['DataSetting'], 'readwrite');
        const store = tx.objectStore('DataSetting');
        const [] = await Promise.all([]);
        const DBAddRequest: IDBRequest = store.add({
          ...arg,
        });
        DBAddRequest.onerror = () => {
          if (DBAddRequest != null) {
            reject(DBAddRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBAddRequest.onsuccess = () => {
          if (DBAddRequest != null) {
            const mergedResult: dataSettingItem = {
              ...arg,
              id: DBAddRequest.result,
            } as dataSettingItem;
            this._push('add', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    put(
      arg: DataSettingPutArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<dataSettingItem> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(['DataSetting'], 'readwrite');
        const store = tx.objectStore('DataSetting');
        const [] = await Promise.all([]);
        const DBPutRequest: IDBRequest = store.put({
          ...arg,
        });
        DBPutRequest.onerror = () => {
          if (DBPutRequest != null) {
            reject(DBPutRequest.error);
          } else {
            reject(
              new Error('Unknown error occurred trying to perform operation'),
            );
          }
        };
        DBPutRequest.onsuccess = () => {
          if (DBPutRequest != null) {
            const mergedResult: dataSettingItem = {
              ...arg,
              id: DBPutRequest.result,
            } as dataSettingItem;
            this._push('put', mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error('Operation produced a null result'));
          }
        };
      });
    }
    delete(
      arg: DataSettingDeleteArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(['DataSetting'], 'readwrite');
        const store = tx.objectStore('DataSetting');
        const idToDelete: number = isDataSettingIdIndex(arg) ? arg.id : arg;
        const DBDeleteRequest: IDBRequest = store.delete(idToDelete);
        if (DBDeleteRequest != null) {
          DBDeleteRequest.onerror = () => {
            if (DBDeleteRequest != null) {
              reject(DBDeleteRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBDeleteRequest.onsuccess = () => {
            this._push('delete', idToDelete);
            resolve(undefined);
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    get(
      arg: DataSettingGetArgs,
      options?: {
        transaction?: IDBTransaction;
      },
    ): Promise<dataSettingItem> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(['DataSetting'], 'readonly');
        const store = tx.objectStore('DataSetting');
        let DBGetRequest: IDBRequest | null = null;
        if (isDataSettingIdIndex(arg)) {
          DBGetRequest = store.get(arg.id);
        } else if (isDataSettingCompanyDataIndex(arg)) {
          const index: IDBIndex = store.index('companyData');
          DBGetRequest = index.get([arg.companyData]);
        } else if (isDataSettingWalletIbanCompanyIndex(arg)) {
          const index: IDBIndex = store.index('walletIbanCompany');
          DBGetRequest = index.get([arg.walletIbanCompany]);
        } else if (isDataSettingOffersIndex(arg)) {
          const index: IDBIndex = store.index('offers');
          DBGetRequest = index.get([arg.offers]);
        } else if (isDataSettingMyAccountDataIndex(arg)) {
          const index: IDBIndex = store.index('myAccountData');
          DBGetRequest = index.get([arg.myAccountData]);
        } else if (isDataSettingMyOfferDataIndex(arg)) {
          const index: IDBIndex = store.index('myOfferData');
          DBGetRequest = index.get([arg.myOfferData]);
        } else {
          DBGetRequest = store.get(arg);
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
    where<IndexName extends DataSettingIndexes>(
      indexName: IndexName,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
      },
    ): RangeQuery<
      DataSettingWhereQueryType<IndexName>,
      Promise<ReadonlyArray<dataSettingItem>>
    > {
      const tx =
        options?.transaction ?? db.transaction(['DataSetting'], 'readonly');
      const store = tx.objectStore('DataSetting');
      const executeQuery = (
        target: IDBObjectStore | IDBIndex,
        range: IDBKeyRange,
      ) => {
        return new Promise<ReadonlyArray<dataSettingItem>>(
          async (resolve, reject) => {
            const DBGetRequest: IDBRequest = target.getAll(range);
            if (DBGetRequest != null) {
              DBGetRequest.onerror = () => {
                if (DBGetRequest != null) {
                  reject(DBGetRequest.error);
                } else {
                  reject(
                    new Error(
                      'Unknown error occurred trying to perform operation',
                    ),
                  );
                }
              };
              DBGetRequest.onsuccess = () => {
                if (DBGetRequest != null && DBGetRequest.result != null) {
                  resolve((DBGetRequest as IDBRequest).result);
                } else {
                  reject(new Error('No result found for query'));
                }
              };
            } else {
              reject(new Error('No available index for given query'));
            }
          },
        );
      };
      return {
        isGreaterThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, true));
            }
            case 'companyData': {
              return executeQuery(
                store.index('companyData'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            case 'walletIbanCompany': {
              return executeQuery(
                store.index('walletIbanCompany'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            case 'offers': {
              return executeQuery(
                store.index('offers'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            case 'myAccountData': {
              return executeQuery(
                store.index('myAccountData'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            case 'myOfferData': {
              return executeQuery(
                store.index('myOfferData'),
                IDBKeyRange.lowerBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isGreaterThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.lowerBound(query, false));
            }
            case 'companyData': {
              return executeQuery(
                store.index('companyData'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            case 'walletIbanCompany': {
              return executeQuery(
                store.index('walletIbanCompany'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            case 'offers': {
              return executeQuery(
                store.index('offers'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            case 'myAccountData': {
              return executeQuery(
                store.index('myAccountData'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            case 'myOfferData': {
              return executeQuery(
                store.index('myOfferData'),
                IDBKeyRange.lowerBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThan(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, true));
            }
            case 'companyData': {
              return executeQuery(
                store.index('companyData'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            case 'walletIbanCompany': {
              return executeQuery(
                store.index('walletIbanCompany'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            case 'offers': {
              return executeQuery(
                store.index('offers'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            case 'myAccountData': {
              return executeQuery(
                store.index('myAccountData'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            case 'myOfferData': {
              return executeQuery(
                store.index('myOfferData'),
                IDBKeyRange.upperBound(query, true),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isLessThanOrEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.upperBound(query, false));
            }
            case 'companyData': {
              return executeQuery(
                store.index('companyData'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            case 'walletIbanCompany': {
              return executeQuery(
                store.index('walletIbanCompany'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            case 'offers': {
              return executeQuery(
                store.index('offers'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            case 'myAccountData': {
              return executeQuery(
                store.index('myAccountData'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            case 'myOfferData': {
              return executeQuery(
                store.index('myOfferData'),
                IDBKeyRange.upperBound(query, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isBetween(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(
                store,
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'companyData': {
              return executeQuery(
                store.index('companyData'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'walletIbanCompany': {
              return executeQuery(
                store.index('walletIbanCompany'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'offers': {
              return executeQuery(
                store.index('offers'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'myAccountData': {
              return executeQuery(
                store.index('myAccountData'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            case 'myOfferData': {
              return executeQuery(
                store.index('myOfferData'),
                IDBKeyRange.bound(query.from, query.to, false, false),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
        isEqualTo(query) {
          switch (indexName) {
            case 'id': {
              return executeQuery(store, IDBKeyRange.only(query));
            }
            case 'companyData': {
              return executeQuery(
                store.index('companyData'),
                IDBKeyRange.only(query),
              );
            }
            case 'walletIbanCompany': {
              return executeQuery(
                store.index('walletIbanCompany'),
                IDBKeyRange.only(query),
              );
            }
            case 'offers': {
              return executeQuery(
                store.index('offers'),
                IDBKeyRange.only(query),
              );
            }
            case 'myAccountData': {
              return executeQuery(
                store.index('myAccountData'),
                IDBKeyRange.only(query),
              );
            }
            case 'myOfferData': {
              return executeQuery(
                store.index('myOfferData'),
                IDBKeyRange.only(query),
              );
            }
            default: {
              return Promise.reject(
                new Error('Trying to run query on unknown index: ' + indexName),
              );
            }
          }
        },
      };
    }
    sortBy(
      indexName: DataSettingIndexes,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
        count?: number;
      },
    ): Promise<ReadonlyArray<dataSettingItem>> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(['DataSetting'], 'readonly');
        const store = tx.objectStore('DataSetting');
        let DBGetRequest: IDBRequest | null = null;
        switch (indexName) {
          case 'id': {
            DBGetRequest = store.getAll(undefined, options?.count);
            break;
          }
          case 'companyData': {
            const index: IDBIndex = store.index('companyData');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          case 'walletIbanCompany': {
            const index: IDBIndex = store.index('walletIbanCompany');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          case 'offers': {
            const index: IDBIndex = store.index('offers');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          case 'myAccountData': {
            const index: IDBIndex = store.index('myAccountData');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          case 'myOfferData': {
            const index: IDBIndex = store.index('myOfferData');
            DBGetRequest = index.getAll(undefined, options?.count);
            break;
          }
          default: {
            return Promise.reject(
              new Error('Trying to run query on unknown index: ' + indexName),
            );
          }
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error('Unknown error occurred trying to perform operation'),
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error('No result found for query'));
            }
          };
        } else {
          reject(new Error('No available index for given query'));
        }
      });
    }
  }
  const datasettingClient = new DataSettingClientImpl();
  return {
    transaction: (
      storeNames: Array<
        | 'Infos'
        | 'Login'
        | 'TransactionList'
        | 'TransactionListDetail'
        | 'CategoriesList'
        | 'accountForStateList'
        | 'DataStatisticList'
        | 'DataTableBarStatisticList'
        | 'DataAccountState'
        | 'MembersDataCards'
        | 'CategoriesCardData'
        | 'DataSetting'
      >,
      mode?: IDBTransactionMode,
    ): IDBTransaction => {
      return db.transaction(storeNames, mode);
    },
    infos: infosClient,
    login: loginClient,
    transactionlist: transactionlistClient,
    transactionlistdetail: transactionlistdetailClient,
    categorieslist: categorieslistClient,
    accountforstatelist: accountforstatelistClient,
    datastatisticlist: datastatisticlistClient,
    datatablebarstatisticlist: datatablebarstatisticlistClient,
    dataaccountstate: dataaccountstateClient,
    membersdatacards: membersdatacardsClient,
    categoriescarddata: categoriescarddataClient,
    datasetting: datasettingClient,
  };
}
export function init(): Promise<SapheerDatabaseClient> {
  return new Promise((resolve, reject) => {
    function createObjectStore(
      db: IDBDatabase,
      storeName: string,
      options: IDBObjectStoreParameters,
    ): IDBObjectStore {
      if (DBOpenRequest.transaction == null) {
        throw new Error(
          'Error opening database. Open request transaction is null.',
        );
      }
      if (db.objectStoreNames.contains(storeName)) {
        return DBOpenRequest.transaction.objectStore(storeName);
      } else {
        return db.createObjectStore(storeName, options);
      }
    }
    function createIndex(
      store: IDBObjectStore,
      indexName: string,
      keyPath: Array<string>,
      options: IDBIndexParameters,
    ): void {
      if (store.indexNames.contains(indexName)) {
        return;
      } else {
        store.createIndex(indexName, keyPath, options);
      }
    }
    function removeUnusedIndexes(
      store: IDBObjectStore,
      indexNames: ReadonlyArray<string>,
    ): void {
      for (const indexName of Array.from(store.indexNames)) {
        if (indexNames.includes(indexName) === false) {
          store.deleteIndex(indexName);
        }
      }
    }
    const DBOpenRequest = globalThis.indexedDB.open('SapheerDatabase', 3);
    DBOpenRequest.onerror = () => {
      reject(new Error('Error opening database: SapheerDatabase'));
    };
    DBOpenRequest.onsuccess = () => {
      const db = DBOpenRequest.result;
      resolve(createDatabaseClient(db));
    };
    DBOpenRequest.onupgradeneeded = () => {
      const db = DBOpenRequest.result;
      const InfosStore = createObjectStore(db, 'Infos', {
        keyPath: 'id',
        autoIncrement: true,
      });
      const LoginStore = createObjectStore(db, 'Login', {
        keyPath: 'id',
        autoIncrement: true,
      });
      const TransactionListStore = createObjectStore(db, 'TransactionList', {
        keyPath: 'id',
        autoIncrement: true,
      });
      const TransactionListDetailStore = createObjectStore(
        db,
        'TransactionListDetail',
        { keyPath: 'id', autoIncrement: true },
      );
      const CategoriesListStore = createObjectStore(db, 'CategoriesList', {
        keyPath: 'id',
        autoIncrement: true,
      });
      const accountForStateListStore = createObjectStore(
        db,
        'accountForStateList',
        { keyPath: 'id', autoIncrement: true },
      );
      const DataStatisticListStore = createObjectStore(
        db,
        'DataStatisticList',
        { keyPath: 'id', autoIncrement: true },
      );
      const DataTableBarStatisticListStore = createObjectStore(
        db,
        'DataTableBarStatisticList',
        { keyPath: 'id', autoIncrement: true },
      );
      const DataAccountStateStore = createObjectStore(db, 'DataAccountState', {
        keyPath: 'id',
        autoIncrement: true,
      });
      const MembersDataCardsStore = createObjectStore(db, 'MembersDataCards', {
        keyPath: 'id',
        autoIncrement: true,
      });
      const CategoriesCardDataStore = createObjectStore(
        db,
        'CategoriesCardData',
        { keyPath: 'id', autoIncrement: true },
      );
      const DataSettingStore = createObjectStore(db, 'DataSetting', {
        keyPath: 'id',
        autoIncrement: true,
      });
      removeUnusedIndexes(InfosStore, [
        'name',
        'firstName',
        'solde',
        'payin',
        'payout',
        'urlAvatar',
      ]);
      removeUnusedIndexes(LoginStore, ['email']);
      removeUnusedIndexes(TransactionListStore, ['transactionId', 'data']);
      removeUnusedIndexes(TransactionListDetailStore, [
        'transactionId',
        'dataDetails',
      ]);
      removeUnusedIndexes(CategoriesListStore, ['CategoriesId', 'data']);
      removeUnusedIndexes(accountForStateListStore, [
        'accountForStateId',
        'data',
      ]);
      removeUnusedIndexes(DataStatisticListStore, ['dataCategoriesTr']);
      removeUnusedIndexes(DataTableBarStatisticListStore, ['dataTableBar']);
      removeUnusedIndexes(DataAccountStateStore, ['dataAccountState']);
      removeUnusedIndexes(MembersDataCardsStore, ['membresData']);
      removeUnusedIndexes(CategoriesCardDataStore, ['CategoriesCard']);
      removeUnusedIndexes(DataSettingStore, [
        'companyData',
        'walletIbanCompany',
        'offers',
        'myAccountData',
        'myOfferData',
      ]);
      createIndex(InfosStore, 'name', ['name'], { unique: false });
      createIndex(InfosStore, 'firstName', ['firstName'], { unique: false });
      createIndex(InfosStore, 'solde', ['solde'], { unique: false });
      createIndex(InfosStore, 'payin', ['payin'], { unique: false });
      createIndex(InfosStore, 'payout', ['payout'], { unique: false });
      createIndex(InfosStore, 'urlAvatar', ['urlAvatar'], { unique: false });
      createIndex(LoginStore, 'email', ['email'], { unique: false });
      createIndex(TransactionListStore, 'transactionId', ['transactionId'], {
        unique: false,
      });
      createIndex(TransactionListStore, 'data', ['data'], { unique: false });
      createIndex(
        TransactionListDetailStore,
        'transactionId',
        ['transactionId'],
        { unique: false },
      );
      createIndex(TransactionListDetailStore, 'dataDetails', ['dataDetails'], {
        unique: false,
      });
      createIndex(CategoriesListStore, 'CategoriesId', ['CategoriesId'], {
        unique: false,
      });
      createIndex(CategoriesListStore, 'data', ['data'], { unique: false });
      createIndex(
        accountForStateListStore,
        'accountForStateId',
        ['accountForStateId'],
        { unique: false },
      );
      createIndex(accountForStateListStore, 'data', ['data'], {
        unique: false,
      });
      createIndex(
        DataStatisticListStore,
        'dataCategoriesTr',
        ['dataCategoriesTr'],
        { unique: false },
      );
      createIndex(
        DataTableBarStatisticListStore,
        'dataTableBar',
        ['dataTableBar'],
        { unique: false },
      );
      createIndex(
        DataAccountStateStore,
        'dataAccountState',
        ['dataAccountState'],
        { unique: false },
      );
      createIndex(MembersDataCardsStore, 'membresData', ['membresData'], {
        unique: false,
      });
      createIndex(
        CategoriesCardDataStore,
        'CategoriesCard',
        ['CategoriesCard'],
        { unique: false },
      );
      createIndex(DataSettingStore, 'companyData', ['companyData'], {
        unique: false,
      });
      createIndex(
        DataSettingStore,
        'walletIbanCompany',
        ['walletIbanCompany'],
        { unique: false },
      );
      createIndex(DataSettingStore, 'offers', ['offers'], { unique: false });
      createIndex(DataSettingStore, 'myAccountData', ['myAccountData'], {
        unique: false,
      });
      createIndex(DataSettingStore, 'myOfferData', ['myOfferData'], {
        unique: false,
      });
    };
  });
}
