import { fetcherWEB } from '../../../apolloClient';
import { CheckConnection } from '../../../graphql/graphql_account/checkConnection/types/CheckConnection';
import { CHECK_CONNECTION } from '../../../graphql/graphql_account/checkConnection/query';

export const useCheckConnection = (api: string | undefined) => {
  const checkConnectionApi = async () => {
    if (api) {
      return fetcherWEB<null, CheckConnection>(
        CHECK_CONNECTION,
        null,
        api,
      ).catch((error) => {
        throw error;
      });
    }
    return;
  };

  const checkConnection = async () => {
    return await checkConnectionApi();
  };

  return { checkConnection };
};
