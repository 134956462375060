import gql from 'graphql-tag';

const WALLET_BALANCE_FRAGMENT = gql`
  fragment WalletBalanceInfo on WalletBalance {
    currentBalance
    authorizedBalance
    transactionOut
    transactionIn
    calculationDate
  }
`;

const WALLET_RECURRENCE_FRAGMENT = gql`
  fragment WalletRecurrenceInfo on Wallet {
    id
  }
`;

export { WALLET_BALANCE_FRAGMENT, WALLET_RECURRENCE_FRAGMENT };
