import { useState } from 'react';
import { useAppDispatch } from '../../redux/hook';
import { ApolloError } from 'apollo-client';
import { fetcherWEB } from '../../apolloClient';
import { API_ACCOUNT } from '../../config';
import { DashboardItem, _dashboards, _teams, _time } from '../../indexDB/data';
import { GenerateToken } from '../../graphql/graphql_account/signin/types/GenerateToken';
import { GENERATE_TOKEN } from '../../graphql/graphql_account/signin/mutation';
import { setToken } from '../../redux/auth/loginSlice';
import { setAccessToken } from '../../services/LocalStorage';
import { useLogout } from '../Auth/Logout/Logout';

const useGenerateToken = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { logout } = useLogout();

  const generateTokenApi = async () => {
    setLoading(true);
    return await fetcherWEB<null, GenerateToken>(
      GENERATE_TOKEN,
      null,
      API_ACCOUNT,
    )
      .then(async (data) => {
        setLoading(false);
        if (data?.generateToken) {
          const userAccessToken = data.generateToken;
          const _email = await _dashboards.getItem(DashboardItem.MY_EMAIL);
          dispatch(
            setToken({
              token: userAccessToken,
              email: (_email as string) || '',
            }),
          );
          await _dashboards.setItem(DashboardItem.MY_ID, userAccessToken);
          setAccessToken(userAccessToken);
        } else {
          await logout();
        }
      })
      .catch(async (error: ApolloError) => {
        setLoading(false);
        await logout();
      });
  };

  const generateToken = async () => {
    return generateTokenApi() as any;
  };

  return { loading, generateToken };
};
export default useGenerateToken;
