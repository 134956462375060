export default {
  URL_EMPTY: `Erreur lors du téléchargement de l'avatar`,
  MEMBER_LIMIT_EXCEEDED: `Vous avez atteint le nombre limit de membre pour votre offre.`,
  EMAIL_EXIST: `Cette adresse mail existe déjà.`,
  ACCOUNT_NOT_FOUND: `Cette compte n'existe pas dans le serveur`,
  ACCOUNT_DISABLED: `Votre compte est désactivé ou n'a pas encore été validé.Veuillez contacter votre administrateur.`,
  SELF_TRANSFERT_TRANSACTION: `Le transfert vers le même compte n'est pas autorisé, veuillez vérifier l'IBAN.`,
  BENEFICIARY_ALREADY_EXIST: `Ce bénéficiaire existe déjà.`,
  REQUIRED_INPUTS_ERRORS: 'Veuillez remplir toutes les données requis',
  TRANSACTION_CANNOT_CANCELED: `Cette transaction ne peut plus être annulée car le délai est dépassé.`,
  USER_CANT_CREATE_TRANSACTION: `Vous n'avez pas l'autorisation de créer une transaction.`,
  USER_CANT_VALID_TRANSACTION: `Vous n'avez pas l'autorisation de valider une transaction.`,
  ACCEPT_INVITATION_AGAIN: `Vous avez déjà accepté l'invitation.`,
  STANDARD_CARD_ONLY_FOR_START_OFFER: `La carte standart est seulement pour l'offre start`,

  ACTIVATION_CARD_ERROR: `Erreur lors de l'activation de la carte`,
  CARD_3DS_ERRORS: 'Erreur lors de la mise en place du 3DS',
  TREEZOR_CREATION_CARD_ERROR: 'Erreur lors de la création de la carte',
  ACCOUNT_NEED_TO_VALIDATE: `Votre compte doit être validé`,
  TREEZOR_CONVERSION_CARD_ERROR: `Erreur lors de la création de la carte physique à partir de la carte virtuel`,

  TREEZOR_CARD_NOT_FOUND: `Impossible de trouver la carte chez le fournisseur`,
  CARD_ALREADY_ACTIVATED: `La carte est déjà activée`,
  LOCK_UNBLOCK_CARD_EROR: `Erreur lors du changement d'option activation du carte`,
  TREEZOR_UPDATE_CARD_OPTION_ERROR: `Erreur lors du changement d'option du carte`,

  PHONE_NUMBER_REQUIRED: `Le numéro téléphone est requis`,
  CODE_INVALID: `Votre code n'est pas valide`,
  SETTING_PIN_ERROR: `Erreur lors de la mise en place du code pin`,
  CARD_NOT_FOUND: `La carte n'existe pas dans le serveur`,
  BENEFICIARY_IBAN_ERROR: `Erreur lors de l'iban du bénéficiaire`,
  BIG_AMOUNT_MUST_HAVE_AN_ATTACHMENT: `La pièce justificative est obligatoire pour un montant > 10.000€`,
  PIN_NOT_MATCH: `Le code pin ne correspond pas à votre code pin actuel`,
};
