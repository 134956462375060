import { useApolloClient, useSubscription } from '@apollo/client';
import React from 'react';
import { ON_TRANSACTION_CANCELED } from '../../../../graphql/graphql_subscription/transaction';
import {
  OnTransactionCancel,
  OnTransactionCancelVariables,
} from '../../../../graphql/graphql_subscription/types/OnTransactionCancel';
import { useCreateOrUpdateTransactionCache } from '../../../customHooks/transaction/tools';

interface TransactionCanceledPropsInterface {
  companyId: string;
  size: number | null;
}

const TransactionCanceled = (props: TransactionCanceledPropsInterface) => {
  const { companyId } = props;
  const client = useApolloClient();
  const { removeInvalid } = useCreateOrUpdateTransactionCache();
  useSubscription<OnTransactionCancel, OnTransactionCancelVariables>(
    ON_TRANSACTION_CANCELED,
    {
      variables: { companyId },
      client,
      onSubscriptionData: async ({ subscriptionData: { data } }) => {
        if (data?.onTransactionCancel?.id) {
          // update list transaction invalidated
          await removeInvalid(data.onTransactionCancel.id);
        }
      },
    },
  );
  return <></>;
};
export default TransactionCanceled;
