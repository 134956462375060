import { START_OFFER_PRICE } from '../../../../utils/constant';

export default {
  'error.pinNotComplex':
    'Veuillez saisir un code PIN comportant 4 chiffres non consécutifs et non répétitifs.',
  'error.getLeaderShareholders': `Erreur lors de la récupération des informations du chef d'entreprise et des actionnaires`,
  'error.getMembers': `Erreur lors de la récupération des informations du chef d'entreprise et des membres`,
  'text.cmcStep1.commandMasterCard': `Commandez votre carte Sapheer dès maintenant !`,

  'text.cmcStep1.deliverMasterCard': ` Pour des raisons de lutte anti-fraude, nous mettons en place des vérifications de paiement afin d’assurer la sécurité des comptes de nos clients. 
  Dans ce cadre, nous demandons à nos chers clients de régler par carte le premier mois afin de commander leur carte Sapheer Premium :`,
  'text.cmcStep1.offerMasterCard': "Smart(9.99€ HT), \n Premium(19.99€ HT), \n Corporate(199.99€ HT), \n Start(19.99€ HT) \n",

  'text.cmcStep1.payOnlineAsStore': `Payez en ligne comme en magasin`,
  'text.cmcStep1.masterCardAcceptedInFrance': `Votre Mastercard Sapheer est acceptée partout en France, en ligne ou en magasin.`,
  'text.cmcStep1.manageRealtime': `Gérez tout en temps réel !`,

  'text.cmcStep1.paymentCaps': `Ajustez vos plafonds de paiement, en ligne ou de retrait.`,
  "text.cmcStep1.paymentCaps2": `Activez ou désactivez votre carte et même le sans contact.`,

  'text.cmcStep2.whereToShip': `Où souhaitez-vous expédier votre carte?`,
  'text.cmcStep2.address': `Où souhaitez vous expédier votre carte ?`,
  'text.cmcStep2.addressToShip': ` Sélectionnez l'adresse à laquelle vous souhaitiez recevoir votre carte bancaire gratuite. Vous recevrez votre carte sous 5 jours ouvrés après la vérification de votre compte.`,
  'text.cmcStep3.setPin': `Définissez le code PIN de votre carte bancaire`,
  'text.cmcStep3.rememberPin': `Souvenez-vous bien de ce code PIN. Il vous sera demandé pour tout paiement par carte avec code, ou pour retirer de l'argent aux distributeurs.`,
  'text.cmcStep3.4digitPin': `Code PIN à 4 chiffres`,
  'text.cmcStep3.confirmPin': `Confirmez code PIN`,
  'text.cmcStep4.commandRegistered': `Votre commande est enregistrée !`,

  'text.cmcStep4.receiveAfter3days': `Vous recevrez normalement votre carte dans un délai de 2 à 5 jours suivant votre localisation.`,

  'text.cmcTypeCard.title': 'Paiement de votre carte',
  'text.cmcTypeCard.paragraph':
    'Payez mensuellement ou en une fois votre carte Standard. ',
  'text.cmcStep3.card': 'Votre carte',
  'error.cmcStep5.passwordNotMatch': 'Les codes ne correspondent pas.',
  'command.card.error': 'Erreur lors de la commande de carte',
  "command.card.payment.title": "Votre Carte",

  'command.card.payment.subTitle': 'Formulaire de paiement',
  'command.card.payment.img': 'Aperçu de votre carte',
  'command.card.noTitular': 'Veuillez choisir un titulaire',


  'text.command.title': `Pour des raisons de lutte anti-fraude, nous mettons en place des vérifications de paiement afin d’assurer la sécurité des comptes de nos clients. 
    Dans ce cadre, nous demandons à nos chers clients de régler par carte le premier mois afin de commander leur carte physique Sapheer :`,
  'text.command.offer.start': `Start ( Gratuit )`,
  'text.command.offer.smart': `Smart (9.99€ HT)`,
  'text.command.offer.premium': `Premium (19.99€ HT)`,
  'text.command.offer.corporate': `Corporate (199.99€ HT)`,
  'text.command.subttext': 'Pour l’offre Start, la carte physique Sapheer Standard  étant optionnelle.',
  'text.command.subtitle1': `Payez partout très facilement !`,
  'text.command.subtitle1.detail': `Votre carte Sapheer Business Mastercard est accepté partout en Europe en ligne ou en magasin`,
  'text.command.subtitle2': `Gérez tout en temps réel !`,
  'text.command.subtitle2.detail1': `Activez ou désactivez votre carte ou le sans contact`,
  'text.command.subtitle2.detail2': `Ajustez vos plafonds de paiements ou de retraits`,
  'text.command.subtitle2.detail3': `Bloquez les paiements à l’étranger`,
};
