import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Theme, withStyles } from '@material-ui/core/styles';
import React from 'react';
import CustomDialogTitle from './CustomDialogTitle';

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

interface CustomDialogPropsInterface {
  dialogTitle: any;
  children: React.ReactNode;
  open: boolean;
  closable?: boolean;
  handleClose(): void;
  disableMaxWidth?: boolean;
}

const CustomDialog = (props: CustomDialogPropsInterface & DialogProps) => {
  const {
    dialogTitle,
    disableMaxWidth,
    children,
    open,
    closable,
    handleClose,
  } = props;

  return (
    <Dialog
      maxWidth={disableMaxWidth ? false : 'md'}
      onClose={handleClose}
      aria-labelledby="contrat-sapheer"
      {...props}
      open={open}
    >
      <CustomDialogTitle
        closable={closable || false}
        onClose={handleClose}
        dialogTitle={dialogTitle}
      />
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
