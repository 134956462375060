import Button from '@material-ui/core/Button';
import { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

const styles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(2),
    '&:last-of-type:not(:only-of-type), &:only-of-type': {
      marginRight: 0,
    },
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  isLoading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}));

interface IButton {
  confirm?: boolean;
  children: React.ReactNode;
  isLoading?: boolean;
}

export default function(props: ButtonBaseProps & IButton & any) {
  const classes = styles();
  const { children, confirm } = props;
  const isLoading = props.isLoading ? props.isLoading : false;

  return (
    <Button
      className={classNames(classes.button, confirm ? classes.confirm : '')}
      variant="contained"
      tabindex={1}
      {...props}
    >
      {isLoading && (
        <CircularProgress size={20} className={classes.isLoading} />
      )}
      {children}
    </Button>
  );
}
