import gql from 'graphql-tag';

export const OFFER_FRAGMENT = gql`
  fragment OfferInfo on Offer {
    id
    name
    virtualCard
    physicalCard
    payins
    payouts
    cheque
    withdrawal
    smartAccess
    servicePlus
    price
    limitPaymentMonth
    limitAtmWeek
    cardTransactions
    smartDashboard
    categorisationAuto
    liveCard
  }
`;
