export default {
  HAVE_OWN_CARDS: 'Créer sa carte',
  VALIDATE_REQUEST: 'Valider des demandes de transaction',
  EDIT_TRANSACTION_DETAILS: "Éditer les détails d'une transaction",
  VIEW_HIS_TRANSACTION: 'Voir ses propres transactions',
  VIEW_EXPORT_ALL_TRANSACTIONS: 'Voir et exporter toutes les transactions',
  VIEW_STC: 'Voir le solde du compte',
  INIT_TRANSFER: 'Initier des virements',
  MANAGE_ALL_CARDS: 'Gérer les cartes',
  MANAGE_TEAM: "Gérer l'équipe",
  EXECUTE_REQUEST: 'Effectuer des demandes de transaction',
};
