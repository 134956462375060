export default {
  'dashboard.recentTransaction': 'Transactions récentes',
  'dashboard.statistic': 'Statistiques',
  'dashboard.card': 'Paiements récents',
  'dashboard.graph': 'État du compte',
  'dashboard.filter.period': 'Période',
  'dashboard.filter.name': 'Nom',
  'dashboard.filter.type': 'Type',
  'dashoard.statistic.title': 'Dépenses de tous les membres',
  'dashoard.statistic.details': 'Détails',
  'dashboard.statistic.filter.title': 'Filtres',
  'dashboard.statistic.filter.spending': 'Sortant',
  'dashboard.statistic.filter.income': 'Entrant',
  'dashboard.error.getMember':
    'Erreur lors de la récupération de la liste des membres.',
  'dashboard.error.getCategory':
    'Erreur lors de la récupération de la liste des catégories.',
  'dashboard.error.getMemberStatistic': `Erreur lors de la récupération des statistiques des membres.`,
  'dashboard.error.noTransactionYet': `Aucune transaction pour l'instant`,
  'dashboard.label.memberExpenses': 'Dépenses de tous les membres',
  'dashboard.label.categoriesExpensesRecipes':
    'Dépenses et recettes toutes catégories',
  'dashboard.label.fluxRecipes': 'Recettes tous Flux',
  'dashboard.label.fluxExpenses': 'Dépenses tous Flux',
  'dashboard.error.noStat': 'Aucune données à calculer pour ces critères.',
  'dashboard.error.getTransactionCard':
    'Erreur lors de la récupération des dernières transactions par carte.',
  'dashboard.text.noTransactionCardYet': `Aucun paiement par carte pour l'instant.`,
  'dashboard.balance': `Solde`,
};
