import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ISetSetting{
    companyData?:string;
    walletIbanCompany?:string
    offers?:string;
    myAccountData?:string;
    myOfferData?:string;
}

export const settingSlice = createSlice({
    name:"setting",
    initialState:{
        header:{
            companyData:"",
            walletIbanCompany:"",
            offers:"",
            myAccountData:"",
            myOfferData:"",
        }
    },
    reducers:{
        setSetting:(state,action:PayloadAction<ISetSetting>)=>{
            let newObject ={};
            Object.assign(newObject,action.payload);
            state.header= {...state.header,...newObject}
        },
    }
})

export const {setSetting}=settingSlice.actions
export default settingSlice.reducer