import { GetSignedUrlVariables } from '../../../graphql/graphql_account/file/types/GetSignedUrl';
import { DashboardItem, _dashboards } from '../../../indexDB/data';
import { setUrlAvatar } from '../../../redux/account/accountSlice';
import { useAppDispatch } from '../../../redux/hook';
import useGetUrl from '../file/url';

type Avatar = {
  uuid: string;
  filename: string;
  url: string;
};
const useGetAvatar = (synchronise?: boolean) => {
  const dispatch = useAppDispatch();
  const { loading, getUrl } = useGetUrl();

  const getAvatar = async (variables: GetSignedUrlVariables) => {
    const { uuid, filename } = variables;
    if (uuid && filename) {
      const file = (await _dashboards.getItem(
        DashboardItem.URL_AVATAR,
      )) as Avatar;
      if (!file || synchronise) {
        const urlNew = await getUrl(variables);
        if (urlNew) {
          const _file = {
            uuid,
            filename,
            url: urlNew,
          };
          await _dashboards.setItem(DashboardItem.URL_AVATAR, _file);
          dispatch(setUrlAvatar(urlNew));
          return urlNew;
        }
      } else {
        dispatch(setUrlAvatar(file.url));
        return file.url;
      }
    }
    return '';
  };

  return { loading, getAvatar };
};
export default useGetAvatar;
