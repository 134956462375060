import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LastTransaction_lastTransaction } from '../../graphql/graphql_transaction/types/LastTransaction';
import { AllTransaction_allTransaction_data } from '../../graphql/graphql_transaction/types/AllTransaction';
import { TransactionInfo_transactionInfo } from '../../graphql/graphql_transaction/types/TransactionInfo';
import { TransactionByCard_transactionByCard } from '../../graphql/graphql_transaction/types/TransactionByCard';
import { ListBeneficiary_listBeneficiary } from '../../graphql/graphql_transaction/types/ListBeneficiary';
import { ListCategory_listCategory } from '../../graphql/graphql_transaction/types/ListCategory';

export interface ISetDetailsTransaction {
  transactionId: string;
  dataDetails: string;
}
export const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {
    transactionInformation: null as TransactionInfo_transactionInfo | null,
    canCreateTransaction: false,
    lastTransaction: [] as (LastTransaction_lastTransaction | null)[] | null,
    txInvalidated: [] as (LastTransaction_lastTransaction | null)[] | null,
    lastCardTransaction: [] as
      | (LastTransaction_lastTransaction | null)[]
      | null,
    beneficiaries: [] as (ListBeneficiary_listBeneficiary | null)[],
    transactions: [] as Array<AllTransaction_allTransaction_data | null>,
    transactionByCard: [] as Array<TransactionByCard_transactionByCard | null>,
    categories: [] as (ListCategory_listCategory | null)[],
  },
  reducers: {
    setBeneficiaries: (
      state,
      action: PayloadAction<(ListBeneficiary_listBeneficiary | null)[]>,
    ) => {
      state.beneficiaries = action.payload;
    },
    setCanCreateTransaction: (state, action: PayloadAction<boolean>) => {
      state.canCreateTransaction = action.payload;
    },
    setSelectedTransaction: (
      state,
      action: PayloadAction<TransactionInfo_transactionInfo | null>,
    ) => {
      state.transactionInformation = action.payload;
    },
    setLastTransaction: (
      state,
      action: PayloadAction<(LastTransaction_lastTransaction | null)[] | null>,
    ) => {
      state.lastTransaction = action.payload;
    },
    setLastCardTransaction: (
      state,
      action: PayloadAction<(LastTransaction_lastTransaction | null)[] | null>,
    ) => {
      state.lastCardTransaction = action.payload;
    },
    setTxInvalidated: (
      state,
      action: PayloadAction<(LastTransaction_lastTransaction | null)[] | null>,
    ) => {
      state.txInvalidated = action.payload;
    },
    setTransactions: (
      state,
      action: PayloadAction<Array<AllTransaction_allTransaction_data | null>>,
    ) => {
      state.transactions = action.payload;
    },
    setTransactionsByCard: (
      state,
      action: PayloadAction<Array<TransactionByCard_transactionByCard | null>>,
    ) => {
      state.transactionByCard = action.payload;
    },
    setCategories: (
      state,
      action: PayloadAction<(ListCategory_listCategory | null)[]>,
    ) => {
      state.categories = action.payload;
    },
  },
});

export const {
  setBeneficiaries,
  setCanCreateTransaction,
  setSelectedTransaction,
  setLastTransaction,
  setLastCardTransaction,
  setTxInvalidated,
  setTransactions,
  setTransactionsByCard,
  setCategories,
} = transactionSlice.actions;

export default transactionSlice.reducer;
