import gql from 'graphql-tag';
import {
  LAST_TRANSACTION_CARD_FRAGMENT,
  TRANSACTION_COMPANY_FRAGMENT,
  COMPANY_LAST_TRANSACTIONS_FRAGMENT,
} from './fragment';

export const ON_CARD_TRANSACTION_CREATE = gql`
  subscription OnCardTransactionCreated($companyId: ID!) {
    onCardTransactionCreate(companyId: $companyId) {
      ...LastTransactionCardInfo
    }
  }
  ${LAST_TRANSACTION_CARD_FRAGMENT}
`;

export const ON_PAYOUT_CREATE = gql`
  subscription OnPayoutCreated($companyId: ID!) {
    onPayoutCreate(companyId: $companyId) {
      ...TransactionCompanyInfo
    }
  }
  ${TRANSACTION_COMPANY_FRAGMENT}
`;

export const ON_PAYOUT_UPDATE = gql`
  subscription OnPayoutUpdated($companyId: ID!) {
    onPayoutUpdate(companyId: $companyId) {
      ...TransactionCompanyInfo
    }
  }
  ${TRANSACTION_COMPANY_FRAGMENT}
`;

export const ON_TRANSFERT_CREATED = gql`
  subscription OnTransferCreated($companyId: ID!) {
    onTransferCreate(companyId: $companyId) {
      ...TransactionCompanyInfo
    }
  }
  ${TRANSACTION_COMPANY_FRAGMENT}
`;

export const ON_TRANSFERT_VALIDATED = gql`
  subscription OnTransferValidated($companyId: ID!) {
    onTransferValidate(companyId: $companyId) {
      ...CompanyLastTransactionsInfo
    }
  }
  ${COMPANY_LAST_TRANSACTIONS_FRAGMENT}
`;

export const ON_TRANSACTION_CANCELED = gql`
  subscription OnTransactionCancel($companyId: ID!) {
    onTransactionCancel(companyId: $companyId) {
      id
    }
  }
`;

export const ON_TRANSACTION_SEND = gql`
  subscription OnTransactionSend($companyId: ID!) {
    onTransactionSend(companyId: $companyId) {
      id
      transactionState
    }
  }
`;
export const ON_TRANSACTION_CONFIRM_BY_MOBILE = gql`
  subscription OnTransactionConfirmByMobile {
    onTransactionConfirmByMobile
  }
`;

export const ON_DOWNLOAD_STATEMENT = gql`
  subscription OnDownloadStatement {
    onDownloadStatement {
      url
      filename
    }
  }
`;

export const ON_ERROR_SUB = gql`
  subscription OnErrorSub {
    onErrorSub
  }
`;
