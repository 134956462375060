import gql from 'graphql-tag';

const CATEGORY_FRAGMENT = gql`
  fragment CategoryInfo on Category {
    id
    name
    constant_code
  }
`;

const CATEGORY_TRANSACTION_FRAGMENT = gql`
  fragment CategoryTransactionInfo on Category {
    id
    name
    constant_code
  }
`;
export { CATEGORY_FRAGMENT, CATEGORY_TRANSACTION_FRAGMENT };
