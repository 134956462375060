import { useApolloClient, useSubscription } from '@apollo/client';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { ON_ERROR_SUB } from '../../../../graphql/transaction/subscription';
import { OnErrorSub } from '../../../../graphql/transaction/types/OnErrorSub';
import { MESSAGE_TYPE } from '../../../../utils/constant';
import { displaySnackBar } from '../../../../utils/snackBarUtils';

const ErrorSub: FC = (props) => {
  const intl = useIntl();
  const client = useApolloClient();

  useSubscription<OnErrorSub>(ON_ERROR_SUB, {
    client,
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data?.onErrorSub) {
        let message = 'transaction.error.downloadStatement';
        if (data.onErrorSub.includes('USER_CANT_GENERATE_STATEMENT')) {
          message = 'account.can.t.generate.statement';
        }
        displaySnackBar(client, {
          type: MESSAGE_TYPE.ERROR,
          message: intl.formatMessage({
            id: message,
          }),
          isOpen: true,
        });
      }
    },
  });

  return <></>;
};

export default ErrorSub;
