import { Badge, Box, Typography } from '@material-ui/core';
import classnames from 'classnames';
import React from 'react';
import styles from './style';

interface IMenuItem {
  icon: JSX.Element;
  title: string;
  badgeNumber?: number;
  active?: boolean;
}
export default (props: IMenuItem) => {
  const classes = styles();
  const { icon, title, active, badgeNumber } = props;

  return (
    <Box
      className={classnames(classes.menu, active ? classes.menuActive : '')}
      textAlign="center"
      marginBottom={0.5}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom={1}
        className={classes.menuItem}
      >
        {!!badgeNumber ? (
          <Badge color="secondary" badgeContent={badgeNumber}>
            {icon}
          </Badge>
        ) : (
          icon
        )}
      </Box>
      <Typography variant="body2">
        <strong>{title}</strong>
      </Typography>
    </Box>
  );
};
