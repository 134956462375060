import { ApolloClient, ApolloError } from '@apollo/client';
import { IntlShape } from 'react-intl';
import SnackVariableInterface from '../interface/SnackVariableInterface';
import { MESSAGE_TYPE } from './constant';
import ErrorCode from '../locales/messages/fr-FR/errorCode';
import { GET_SNACKBAR_STATE } from '../graphql/Common/snackbar/query';

export const displaySnackBar = (
  client: ApolloClient<any>,
  data: SnackVariableInterface,
) => {
  client.writeQuery({
    query: GET_SNACKBAR_STATE,
    data: {
      snackBar: { ...data, __typename: 'SnackBar', isLogin: !!data.isLogin },
    },
  });
};

export const defaultErrorHandler = (
  client: ApolloClient<any>,
  error: ApolloError,
  intl: IntlShape,
  defaultErrorId?: string,
) => {
  let messageId = '';
  let defaultId = defaultErrorId ? defaultErrorId : 'error.unknownError';
  const { graphQLErrors, networkError } = error;
  if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
    const graphqlErrorConst = graphQLErrors[0].message;
    // Check if graphql error constant is listed into ErrorCode locales
    messageId =
      Object.keys(ErrorCode).indexOf(graphqlErrorConst) !== -1
        ? graphqlErrorConst
        : defaultId;
  } else if (networkError) {
    messageId = 'error.networkError';
  } else {
    messageId = defaultId;
  }
  if (!networkError) {
    displaySnackBar(client, {
      type: MESSAGE_TYPE.ERROR,
      message: intl.formatMessage({ id: messageId }),
      isOpen: true,
    });
  }
};
