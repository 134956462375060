import { makeStyles } from "@material-ui/core";
import { mode } from "../../App";
import { VIEW } from "../../utils/constant";

const styles = makeStyles((theme) => ({
  notificationLayout: {
    width: 900,
    height: 450,
    display: "flex",
    "& img, & video": {
      maxHeight: "100%",
    },
  },
  icon: {
    color: mode === VIEW.LIGHT ? "#6B718B" : "#FFF",
  },
  notifEntries: {
    flex: "1 1 auto",
    marginRight: theme.spacing(1),
    padding: theme.spacing(1, 0),
  },
  entries: {
    height: "calc(100% - 28px)",
    padding: theme.spacing(1),
    overflowY: "scroll",
  },
  notifContent: {
    display: "flex",
    flexBasis: 500,
    padding: theme.spacing(2),
    border: "1px solid #DFDFDF",
    borderRadius: 6,
    overflowY: "scroll",
  },
  title: {
    fontWeight: 600,
  },
  emptyContent: {
    width: 200,
    margin: "auto",
    textAlign: "center",
  },
  contentTitle: {
    fontSize: 14,
    fontWeight: "bold",
  },
  motherContent: {
    display: "flex",
    height: "90%",
    width: "90%",

    paddingBottom: theme.spacing(1),
  },
  imageContent: {
    height: "100%",
    width: "100%",
  },
  textContent: {
    overflowWrap: "break-word",
    paddingTop: theme.spacing(1),
    height: "calc(100%-30px)",
    width: "calc(100%-30px)",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default styles;
