import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const Functionality = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g
        id="Groupe_12302"
        data-name="Groupe 12302"
        transform="translate(12488 4729)"
      >
        <rect
          id="Rectangle_17793"
          data-name="Rectangle 17793"
          width="31.998"
          height="32"
          transform="translate(-12488 -4729)"
          fill="none"
        />
        <g
          id="Groupe_10730"
          data-name="Groupe 10730"
          transform="translate(-12487.997 -4729)"
        >
          <path
            id="Tracé_172"
            data-name="Tracé 172"
            d="M186.468,174.473l-11.732,11.733-.754-.754,11.732-11.733Zm0,0"
            transform="translate(-164.225 -163.963)"
          />
          <path
            id="Tracé_173"
            data-name="Tracé 173"
            d="M53.543,399.094a.533.533,0,0,0-.457-.259h-3.2a.533.533,0,0,0-.457.259l-1.6,2.667a.533.533,0,0,0,0,.549l1.6,2.667a.533.533,0,0,0,.457.259h3.2a.534.534,0,0,0,.457-.259l1.6-2.667a.533.533,0,0,0,0-.549Zm-.759,5.074h-2.6l-1.28-2.133,1.28-2.133h2.6l1.28,2.133Zm0,0"
            transform="translate(-45.087 -376.435)"
          />
          <path
            id="Tracé_174"
            data-name="Tracé 174"
            d="M25.871,12.8a6.378,6.378,0,0,0,6.2-7.946.533.533,0,0,0-.894-.249l-3.32,3.318-2.634-.878-.878-2.633,3.32-3.321A.533.533,0,0,0,27.418.2a6.38,6.38,0,0,0-7.946,6.2,6.261,6.261,0,0,0,.13,1.25L7.922,19.33a6.291,6.291,0,0,0-1.249-.13,6.4,6.4,0,1,0,6.4,6.4,6.276,6.276,0,0,0-.13-1.25l11.679-11.68a6.275,6.275,0,0,0,1.249.13Zm-1.793-1.092-12.1,12.1a.534.534,0,0,0-.141.507,5.368,5.368,0,1,1-3.878-3.878.538.538,0,0,0,.507-.141L20.564,8.2a.533.533,0,0,0,.14-.507,5.307,5.307,0,0,1,5.47-6.613L23.361,3.891a.533.533,0,0,0-.129.545l1.066,3.2a.534.534,0,0,0,.338.337l3.2,1.067a.533.533,0,0,0,.545-.129L31.194,6.1c.006.1.01.2.01.3a5.305,5.305,0,0,1-6.622,5.167.534.534,0,0,0-.507.14h0Zm0,0"
            transform="translate(-0.274 0)"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Functionality;
