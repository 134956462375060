export default {
  'emptylayout.paragraph1': 'You have not yet deposited a check.',
  'emptylayout.paragraph2': `Begin depositing your check by clicking on the button at the top left "Deposit check".`,
  'emptyPanel.title': 'Cash a payment by check',
  'emptyPanel.list1': '1 - Complete the information concerning your check.',
  'emptyPanel.p1':
    'As with a traditional remittance slip, enter the amount, the issuer, etc.',
  'emptyPanel.list2': '2- Send us your check by post.',
  'emptyPanel.p2':
    'The exact address will be communicated to you at the end of the deposit process.',
  'emptyPanel.list3': '3- Once received, your check will be checked.',
  'emptyPanel.p3':
    'After validation, its amount will be credited to your account after a period of 15 working days.',
  'emptyPanel.notice1':
    'Collections are limited to EUR 5,000 by check and EUR 10,000 per month (over 30 rolling days).',
  'emptyPanel.notice2':
    'The check must mention the name of your company to be cashed.',
  'table.head.chequeIssuer': 'Cheque Issuer',
  'table.head.amount': 'Amount',
  'table.head.date': 'Date',
  'table.head.state': 'State',
  'table.search.noResult': '',
  'grid.noData': '',
};
