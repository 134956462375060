import { MutationUpdaterFn } from '@apollo/client';
import { ACCOUNT_NOTIFICATIONS } from '../../graphql/notification/query';
import {
  AccountNotifications,
  AccountNotificationsVariables,
  AccountNotifications_getAccountNotifications,
  AccountNotifications_getAccountNotifications_notification,
} from '../../graphql/notification/types/AccountNotifications';
import { MarkNotification } from '../../graphql/notification/types/MarkNotification';
import { NOTIFICATION_TYPE } from '../../utils/constant';

export const getNotificationIntlId = (constantCode: string): string => {
  switch (constantCode) {
    case NOTIFICATION_TYPE.FONCTIONNALITE:
      return 'notification.title.functionalities';
    case NOTIFICATION_TYPE.MESSAGE:
      return 'notification.title.messages';
    default:
      return 'notification.title.servicesPlus';
  }
};

export const getNotificationById = (
  notifications: Array<AccountNotifications_getAccountNotifications | null>,
  selectedId: string,
): AccountNotifications_getAccountNotifications_notification | null => {
  const accountNotifs = notifications.filter(
    (notif: AccountNotifications_getAccountNotifications | null) => {
      if (notif && notif.notification && notif.notification.id) {
        return notif.notification.id === selectedId;
      }
    },
  );
  const notif = (!!accountNotifs.length && accountNotifs[0]) || null;
  return (notif && notif.notification) || null;
};

export const getAccountNotificationByNotifId = (
  accountNotifications: Array<AccountNotifications_getAccountNotifications | null>,
  selectedId: string,
): AccountNotifications_getAccountNotifications | null => {
  const accountNotifs = accountNotifications.filter(
    (accountNotif: AccountNotifications_getAccountNotifications | null) => {
      if (accountNotif && accountNotif) {
        const { notification } = accountNotif;
        if (notification && notification.id) {
          return notification.id === selectedId;
        }
        return false;
      }
      return false;
    },
  );
  return !!accountNotifs.length ? accountNotifs[0] : null;
};

export const getNotViewedAccountNotifNumber = (
  accountNotifications: Array<AccountNotifications_getAccountNotifications | null>,
): number => {
  let notViewedNb = 0;
  if (accountNotifications) {
    accountNotifications.forEach(
      (accountNotif: AccountNotifications_getAccountNotifications | null) => {
        if (accountNotif && accountNotif.viewed !== true) {
          notViewedNb++;
        }
      },
    );
  }
  return notViewedNb;
};

export const updateCacheAfterMarkNotification: MutationUpdaterFn<
  MarkNotification
> = (cache, { data }) => {
  if (cache) {
    if (
      data &&
      data.markNotificationAsRead &&
      data.markNotificationAsRead &&
      data.markNotificationAsRead.id
    ) {
      const updatedAccountNotification = data.markNotificationAsRead;
      const accountNotifs = cache.readQuery<
        AccountNotifications,
        AccountNotificationsVariables
      >({ query: ACCOUNT_NOTIFICATIONS });
      if (accountNotifs && accountNotifs.getAccountNotifications) {
        const updatedAccountNotifs = accountNotifs.getAccountNotifications.map(
          (accountNotif) => {
            if (accountNotif && accountNotif.id && accountNotif.notification) {
              return accountNotif.id === updatedAccountNotification.id
                ? { ...accountNotif, viewed: true }
                : accountNotif;
            }
            return null;
          },
        );
        cache.writeQuery<AccountNotifications, AccountNotificationsVariables>({
          query: ACCOUNT_NOTIFICATIONS,
          data: {
            getAccountNotifications: updatedAccountNotifs,
          },
        });
      }
    }
  }
};
