import Box from "@material-ui/core/Box";
import React, { FC, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { mode } from "../../../App";
import { colorDark, colorLight } from "../../../theme";
import { VIEW } from "../../../utils/constant";
import { useLazyQuery } from "@apollo/client";
import {
  ACCESS_TOKEN_KEY,
  clearLocalStorage,
  getConnexionID,
} from "../../../services/LocalStorage";
import UserInactivity from "./UserInactivity";
import { INVITE_URL_EXPIRATION } from "../../../graphql/team/query";
import {
  InviteUrlExpiration,
  InviteUrlExpirationVariables,
} from "../../../graphql/team/types/InviteUrlExpiration";
import AppBar from "./AppBar/AppBarProvider";
import SideBar from "./Menu/MenuProvider";
import useCheckToken from "../CheckToken";
import CustomProgress from "../CustomProgress/CustomProgress";
import { useAppSelector } from "../../../redux/hook";
import { useSetTeamData } from "../../Team/DataTeam/TeamData";
import { useSetData } from "../../Card/DataCard/DataCard";
import useAddConnexion from "../../Auth/SignIn/addConnexion";
import useTimerToken from "../../customHooks/timer";
import useGetMyCompanyInfo from "../../customHooks/company/myCompanyInfo";
import useGetMyOffer from "../../customHooks/offer/myOffer";

const Layout: FC = () => {
  const { addConnexion } = useAddConnexion();
  useSetTeamData(true);
  useSetData(true);
  const { loading } = useGetMyCompanyInfo();
  const { loading: loadingMyOffer } = useGetMyOffer();
  useCheckToken();
  useTimerToken();
  const account = useAppSelector(
    (state) => state.account.myCompany?.myAccount?.account,
  );

  // check token if valid
  const [checkToken] = useLazyQuery<
    InviteUrlExpiration,
    InviteUrlExpirationVariables
  >(INVITE_URL_EXPIRATION, {
    onCompleted: (data) => {
      if (data && !data.inviteUrlExpiration) {
        clearLocalStorage();
        window.location.href = "/";
      }
    },
  });

  useEffect(() => {
    getEmailData();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (token) {
      checkToken({
        variables: {
          token,
        },
      });
    }
  }, []);
  // if has not connection

  const getEmailData = async () => {
    if (account?.email) {
      insertConnexionByEmail(account.email);
    }
  };

  const insertConnexionByEmail = async (email: string) => {
    const connId = getConnexionID();
    if (!connId) {
      await addConnexion({ email, isSignin: true });
    }
  };

  if (loading || loadingMyOffer) {
    return <CustomProgress />;
  }
  return (
    <Box>
      <UserInactivity>
        <Box display="flex" height="100vh">
          {
            <Box>
              <AppBar />
              <SideBar />
            </Box>
          }
          <Box
            flex={1}
            bgcolor={
              mode === VIEW.LIGHT
                ? colorLight.appBackground
                : colorDark.appBackground
            }
            height="100vh"
          ></Box>
        </Box>
        <Outlet />
      </UserInactivity>
    </Box>
  );
};
export default Layout;
