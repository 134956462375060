import gql from 'graphql-tag';

export const BENEFICIARY_TRANSACTION_FRAGMENT = gql`
  fragment BeneficiaryTransactionInfo on Beneficiary {
    id
    name
    treezorUserId
    iban
    bic
    ics
    mandateId
    deleted
  }
`;
