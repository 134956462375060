export default {
  'company.label.companyActivity': `Company's activity`,
  'company.legalName.empty': `Please set company's legal name.`,
  'company.legalForm.empty': `Please set company's legal form.`,
  'company.legalRegistrationNumber.empty': 'Please set the SIRET.',
  'company.address.empty': `Please set company's address.`,
  'company.postCode.empty': `Please set company's post code.`,
  'company.city.empty': `Please set company's city.`,
  'company.getInfo.error': 'Error while getting company informations.',
  'company.updateInfo.error': 'Error while updating company informations.',
  'company.update.success': 'Information company updated successfully.',
  'company.getAccountInfo.error':
    'Error while getting your personal informations.',
  'company.label.beingCreated': 'Your company is being created.',
  'company.label.noSIRET': 'You do not have a SIRET number',
  'setting.iban': 'IBAN',
  'setting.bic': 'BIC',
  'setting.actualOffer': 'Actual offer',
  'setting.getOfferError': `Error while getting your offer's informations.`,
  'setting.change.email': 'Modify',
  'setting.offer.upgrade': 'Upgrade',

  'setting.facture.title': `Your Invoices`,
  'setting.facture.link': `All download`,
  'facture.dialogue': `Invoices`,
  'setting.download.facture.error': 'Error when downloading invoices',

  'setting.download.rib': 'RIB',
  'setting.download.rib.error': 'Error when downloading RIB',
  'setting.offer.actual': 'Actual',
  'setting.offers': 'Offers',
  'setting.offers.details': 'View details',
  'setting.offer.details': 'More',
  'setting.dialog.email.title': `Change my email address`,
  'setting.dialog.email.text': 'You need to insert your password',
  'password.not.match': 'Please insert the right password',

  'setting.text.changeMail': 'Insert your new email address',
  'setting.email.empty': `Your email address is required`,
  'setting.email.notValid': `Email not valid`,

  'setting.dialog.offer.title': 'Offers details',
  'setting.import.image': `Please hover over the frame to add the logo`,

  'offer.free': 'Free',

  'offer.servicePlus': 'Service Plus',
  'offer.liveCard': 'Live Card',
  'offer.smartDashboard': 'Smart Dashboard',
  'offer.categorisationAuto': 'Automatic Categorization',

  'offer.fonctionality': 'Fonctionality',
  'offer.users': 'Users',
  'offer.smartAccess': 'Smart Access',
  'offer.encaissement': 'collections',
  'offer.cheque': 'Cheque',
  'offer.quantity': 'Quantity',
  'offer.withdrawal': 'Withdrawal',
  'offer.payinsAndpayouts': 'Payins / Payout',
  'offer.cardTransaction': 'Card transaction',
  'offer.virtual': 'Virtual',
  'offer.physical': 'Physical',
  'offer.businnesMastercard': 'Business Mastercard',
  'offer.noLimit': 'No limit',
  'offer.limitAtmWeek': 'Withdrawal limit / week',
  'offer.limitPaymentMonth': 'Payment limit / month',
  'offer.autorized': 'Authorized',

  'setting.email.validationText':
    'Please enter the security code that was sent to you in your email.',
  'setting.dialog.edit.title': 'Change company information',

  'setting.edit.identity': 'Identity',
  'setting.edit.coordinate': 'Coordinate',
  'setting.edit.informations': 'Informations',
  'img.deposit1': 'drag and drop',
  'img.deposit2': 'as part of',

  'request.email.error': `Error when verifying the email address`,
  'change.email.error': `Error when updating the email address`,
  'change.avatar.error': `Error while updating the avatar`,

  'setting.company.cgs': `General conditions of services - Sapheer`,
  'setting.company.cgu': `General conditions of services of use - Card`,
  'setting.company.payment': `General conditions of services - Payment services`,
  'setting.company.confidentiality': `Privacy Policy`,

  'setting.title.contract': `Contract`,
  'setting.title.confidentiality': `Personal data`,
  'setting.company.Pcc': `Privacy Policy Contact`,

  'setting.company.footer': `Spark, a company registered with the Nanterre Trade and Companies Registry under number 853 100 527, operating under the trade name Sapheer, is registered by the Autorité de Contrôle Prudentiel et de Résolution ("ACPR") under number 85319, which can be consulted in the Register of Financial Agents (www.regafi.fr) as a payment services agent of the electronic money institution Treezor (CIB 16798), whose registered office is located at 41 rue de Prony 75017 Paris.`,

  'setting.company.contact': 'Join us',
};
