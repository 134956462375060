export default {
  'toolbar.button.add': 'Deposit cheque',
  'toolbar.input.search': 'Search cheque issuer',
  'dialog.create.headerTitle': 'Deposit cheque',
  'dialog.create.title': 'Complete the information',
  'dialog.create.button': 'Submit',
  'dialog.filter.headerTitle': 'Cheque filter',
  'dialog.filter.title': 'Filter your check',
  'dialog.filter.paragraph':
    'View your checks according to your criteria and preferences.',
  'dialog.filter.fields.status': 'Status',
  'cheque.input.debitor.empty': 'Please enter the name of the debtor',
  'cheque.input.amount.empty': 'Please enter an amount',
  'cheque.input.amount.max':
    'You have entered an amount greater than €10,000  ',
  'cheque.input.info.empty': 'Please enter the cheque information',
  'cheque.create.error': 'Error while creating the cheque.',
  'cheque.get.error': '  Error while retrieving cheque information',
  'cheque.error.lists': 'Error when collecting cheques lists',
  'cheque.input.info.cmc7length': 'Please check the cheque information',
};
