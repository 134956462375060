import gql from 'graphql-tag';
import { ROLES_FRAGMENT } from './fragment_role';
import { RIGHT_FRAGMENT } from './fragment_right';
import { TEAM_FRAGMENT } from './fragment_team';

export const MEMBERS_FRAGMENT = gql`
  fragment MembersInfo on Person {
    id
    type
    title
    name
    firstName
    nationality
    birthday
    placeOfBirth
    birthCountry
    birthPostCode
    address
    postCode
    city
    capital
    account {
      id
      email
      phone
      isInvitationAccepted
      avatarUuid
      avatarFilename
      accountToSapheerbankAccounts {
        id
        isActivated
        rights {
          ...RightInfo
        }
        role {
          ...RoleInfo
        }
      }
      teams {
        ...teamInfo
      }
    }
  }
  ${RIGHT_FRAGMENT}
  ${ROLES_FRAGMENT}
  ${TEAM_FRAGMENT}
`;
