import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const Dashboard = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g
        id="Groupe_11737"
        data-name="Groupe 11737"
        transform="translate(11990 -11773)"
      >
        <rect
          id="Rectangle_2490"
          data-name="Rectangle 2490"
          width="25"
          height="25"
          transform="translate(-11990 11773)"
          fill="none"
        />
        <path
          id="ios-keypad"
          d="M85.415,81.956a3.459,3.459,0,1,0,3.459,3.459A3.458,3.458,0,0,0,85.415,81.956Zm-8.978,0A3.459,3.459,0,1,0,79.9,85.415,3.458,3.458,0,0,0,76.437,81.956Zm-8.978,0a3.459,3.459,0,1,0,3.459,3.459A3.458,3.458,0,0,0,67.459,81.956Zm17.956-8.978a3.459,3.459,0,1,0,3.459,3.459A3.458,3.458,0,0,0,85.415,72.978Zm-8.978,0A3.459,3.459,0,1,0,79.9,76.437,3.458,3.458,0,0,0,76.437,72.978Zm-8.978,0a3.459,3.459,0,1,0,3.459,3.459A3.458,3.458,0,0,0,67.459,72.978ZM85.415,64a3.459,3.459,0,1,0,3.459,3.459A3.458,3.458,0,0,0,85.415,64Zm-8.978,0A3.459,3.459,0,1,0,79.9,67.459,3.458,3.458,0,0,0,76.437,64Zm-8.978,0a3.459,3.459,0,1,0,3.459,3.459A3.458,3.458,0,0,0,67.459,64Z"
          transform="translate(-12053.957 11709.043)"
        />
      </g>
    </SvgIcon>
  );
};

export default Dashboard;
