import gql from 'graphql-tag';
import { LAST_TRANSACTION_CARD_FRAGMENT } from './fragments/fragment';
import { BENEFICIARY_TRANSACTION_FRAGMENT } from './fragments/fragment_beneficiary';
import { CATEGORY_TRANSACTION_FRAGMENT } from './fragments/fragment_category';

export const TRANSACTION_INFO = gql`
  query TransactionInfo($id: ID!) {
    transactionInfo(id: $id) {
      ...LastTransactionCardInfo
    }
  }
  ${LAST_TRANSACTION_CARD_FRAGMENT}
`;

export const LAST_TRANSACTION = gql`
  query LastTransaction(
    $transactionState: [String]
    $transactionModality: [Int]
    $size: Int
  ) {
    lastTransaction(
      transactionState: $transactionState
      transactionModality: $transactionModality
      size: $size
    ) {
      ...LastTransactionCardInfo
    }
  }
  ${LAST_TRANSACTION_CARD_FRAGMENT}
`;

export const TRANSACTION_INVALIDATED = gql`
  query TransactionInvalidated(
    $transactionState: [String]
    $transactionModality: [Int]
    $transactionTreezorId: Boolean
  ) {
    transactionInvalidated(
      transactionState: $transactionState
      transactionModality: $transactionModality
      transactionTreezorId: $transactionTreezorId
    ) {
      ...LastTransactionCardInfo
    }
  }
  ${LAST_TRANSACTION_CARD_FRAGMENT}
`;

export const ALL_TRANSACTION_PAGINATION = gql`
  query AllTransaction(
    $transactionState: [String]
    $transactionModality: [Int]
    $page: Int
    $size: Int
    $categoryId: ID
    $modalityType: Int
    $totalDay: Int
    $beneficiarySearch: String
  ) {
    allTransaction(
      transactionState: $transactionState
      page: $page
      size: $size
      transactionModality: $transactionModality
      categoryId: $categoryId
      modalityType: $modalityType
      totalDay: $totalDay
      beneficiarySearch: $beneficiarySearch
    ) {
      data {
        ...LastTransactionCardInfo
      }
      page
      size
      sumTotal
      totalPage
    }
  }
  ${LAST_TRANSACTION_CARD_FRAGMENT}
`;

export const TRANSACTION_BY_CARD = gql`
  query TransactionByCard(
    $cardId: Int!
    $transactionState: [String]
    $transactionModality: [Int]
  ) {
    transactionByCard(
      transactionState: $transactionState
      transactionModality: $transactionModality
      cardId: $cardId
    ) {
      ...LastTransactionCardInfo
    }
  }
  ${LAST_TRANSACTION_CARD_FRAGMENT}
`;

export const CHECK_USER_INIT_TRANSFER = gql`
  query CheckUserInitTransfer {
    checkUserInitTransfer
  }
`;

export const WALLET_BALANCE = gql`
  query WalletBalance {
    walletBalance {
      authorizedBalance
      calculationDate
      currency
      currentBalance
      transactionIn
      transactionOut
      treezorWalletId
    }
  }
`;

export const BENEFICIARY_INFO = gql`
  query BeneficiaryInfo($id: ID!) {
    beneficiaryInfo(id: $id) {
      ...BeneficiaryTransactionInfo
    }
  }
  ${BENEFICIARY_TRANSACTION_FRAGMENT}
`;

export const LIST_BENEFICIARY = gql`
  query ListBeneficiary {
    listBeneficiary {
      ...BeneficiaryTransactionInfo
    }
  }
  ${BENEFICIARY_TRANSACTION_FRAGMENT}
`;

export const LIST_CATEGORY = gql`
  query ListCategory {
    listCategory {
      ...CategoryTransactionInfo
    }
  }
  ${CATEGORY_TRANSACTION_FRAGMENT}
`;
