export default {
  'emptylayout.paragraph1': "Vous n'avez pas encore déposé de chèque.",
  'emptylayout.paragraph2': `Commencer le dépôt de votre chèque en cliquant sur le bouton en haut à gauche "Déposer chèque".`,
  'emptyPanel.title': 'Encaisser un paiement par chèque',
  'emptyPanel.list1': '1 - Complétez les informations concernant votre chèque.',
  'emptyPanel.p1':
    "Comme pour un bordereau de remise traditionnel, renseignez le montant, l'émetteur, etc.",
  'emptyPanel.list2': '2- Envoyez-nous votre chèque par voie postale.',
  'emptyPanel.p2':
    "L'adresse exacte vous sera communiquée à la fin du processus de dépôt.",
  'emptyPanel.list3': '3- Une fois reçu, votre chèque sera contrôlé.',
  'emptyPanel.p3':
    'Après validation, son montant sera crédité sur votre compte après un délai de 15 jours ouvrés.',
  'emptyPanel.notice1':
    'Les encaissements sont limités à 5000 EUR par chèque et 10000 EUR par mois (sur 30 jours glissants).',
  'emptyPanel.notice2':
    'Le chèque doit mentionner la raison sociale de votre entreprise pour être encaissé.',
  'table.head.chequeIssuer': 'Emétteur',
  'table.head.amount': 'Montant',
  'table.head.date': 'Date',
  'table.head.state': 'Statut',
  'table.search.noResult': '',
  'grid.noData': '',
};
