import gql from 'graphql-tag';

export const NOTIF_TYPE_FRAGMENT = gql`
  fragment NotifTypeInfo on NotificationType {
    id
    name
    constantCode
  }
`;

export const NOTIF_ATTACHEMENT_FRAGMENT = gql`
  fragment NotifAttachmentInfo on NotifAttachment {
    id
    uuid
    filename
    filetype
  }
`;

export const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationInfo on Notification {
    id
    notificationType {
      ...NotifTypeInfo
    }
    title
    content
    notifAttachments {
      ...NotifAttachmentInfo
    }
  }
  ${NOTIF_TYPE_FRAGMENT}
  ${NOTIF_ATTACHEMENT_FRAGMENT}
`;

export const ACCOUNT_NOTIF_FRAGMENT = gql`
  fragment AccountNotificationInfo on AccountNotification {
    id
    viewed
    date
    notification {
      ...NotificationInfo
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;
