import { OFFER_FRAGMENT } from '../offer/fragments/fragment_offer';
import { COMPANY_FRAGMENT } from './fragments/fragment_company';
import { gql } from 'graphql-tag';
import { MEMBERS_FRAGMENT } from '../members/fragments/fragment_member';
import { WALLET_FRAGMENT } from './fragments/fragment_Wallet';
import { COMPANY_SETTING_FRAGMENT } from './fragments/fragment_myCompanySetting';

export const MY_COMPANY_INFO = gql`
  query MyCompany {
    myCompany {
      company {
        ...CompanyInfo
      }
      members {
        ...MembersInfo
      }
      leader {
        ...MembersInfo
      }
      myAccount {
        ...MembersInfo
      }
      offer {
        ...OfferInfo
      }
      wallet {
        ...WalletInfo
      }
    }
  }
  ${COMPANY_FRAGMENT}
  ${MEMBERS_FRAGMENT}
  ${OFFER_FRAGMENT}
  ${WALLET_FRAGMENT}
`;

export const MY_COMPANY_SETTING = gql`
  query MyCompanySetting {
    myCompanySetting {
      ...CompanySettingInfo
    }
  }
  ${COMPANY_SETTING_FRAGMENT}
`;
