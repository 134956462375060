import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const Card = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        id="ios-card"
        d="M56,97.714A1.719,1.719,0,0,0,54.286,96H33.714A1.719,1.719,0,0,0,32,97.714v2.571H56ZM32,111.429a1.719,1.719,0,0,0,1.714,1.714H54.286A1.719,1.719,0,0,0,56,111.429v-8.571H32Zm7.607-2.571h8.786a.75.75,0,1,1,0,1.5H39.607a.75.75,0,1,1,0-1.5Zm-3.857,0h.643a.75.75,0,1,1,0,1.5H35.75a.75.75,0,0,1,0-1.5Z"
        transform="translate(-32 -96)"
      />
    </SvgIcon>
  );
};

export default Card;
