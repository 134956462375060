import { useApolloClient, useSubscription } from '@apollo/client';
import React from 'react';
import {
  ON_TRANSFERT_CREATED,
  ON_TRANSFERT_VALIDATED,
} from '../../../../graphql/graphql_subscription/transaction';
import {
  OnTransferCreated,
  OnTransferCreatedVariables,
} from '../../../../graphql/graphql_subscription/types/OnTransferCreated';
import {
  OnTransferValidated,
  OnTransferValidatedVariables,
} from '../../../../graphql/graphql_subscription/types/OnTransferValidated';
import { useCreateOrUpdateTransactionCache } from '../../../customHooks/transaction/tools';

interface TransfertSubPropsInterface {
  companyId: string;
  size: number | null;
}

const TransfertSub = (props: TransfertSubPropsInterface) => {
  const { companyId } = props;
  const client = useApolloClient();
  const { updateOrCreate } = useCreateOrUpdateTransactionCache();
  useSubscription<OnTransferCreated, OnTransferCreatedVariables>(
    ON_TRANSFERT_CREATED,
    {
      variables: {
        companyId,
      },
      client,
      onSubscriptionData: async ({ subscriptionData: { data } }) => {
        if (data?.onTransferCreate) {
          // Update invalidated transaction list
          await updateOrCreate(data.onTransferCreate);
        }
      },
    },
  );

  useSubscription<OnTransferValidated, OnTransferValidatedVariables>(
    ON_TRANSFERT_VALIDATED,
    {
      variables: { companyId },
      client,
      onSubscriptionData: async ({ subscriptionData: { data } }) => {
        if (data?.onTransferValidate) {
          await updateOrCreate(data.onTransferValidate);
        }
      },
    },
  );

  return <></>;
};
export default TransfertSub;
