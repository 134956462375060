import { useApolloClient, useSubscription } from '@apollo/client';
import React from 'react';
import {
  OnPayoutCreated,
  OnPayoutCreatedVariables,
} from '../../../../graphql/graphql_subscription/types/OnPayoutCreated';
import {
  ON_PAYOUT_CREATE,
  ON_PAYOUT_UPDATE,
} from '../../../../graphql/graphql_subscription/transaction';
import {
  OnPayoutUpdated,
  OnPayoutUpdatedVariables,
} from '../../../../graphql/graphql_subscription/types/OnPayoutUpdated';
import { useCreateOrUpdateTransactionCache } from '../../../customHooks/transaction/tools';

interface PayoutSubPropsInterface {
  companyId: string;
  size: number | null;
}

const PayoutSub = (props: PayoutSubPropsInterface) => {
  const { companyId } = props;
  const client = useApolloClient();

  const { updateOrCreate } = useCreateOrUpdateTransactionCache();
  useSubscription<OnPayoutCreated, OnPayoutCreatedVariables>(ON_PAYOUT_CREATE, {
    variables: { companyId },
    client,
    onSubscriptionData: async ({ subscriptionData: { data } }) => {
      if (data?.onPayoutCreate) {
        // update list transaction invalidated
        await updateOrCreate(data.onPayoutCreate);
      }
    },
  });

  useSubscription<OnPayoutUpdated, OnPayoutUpdatedVariables>(ON_PAYOUT_UPDATE, {
    variables: { companyId },
    client,
    onSubscriptionData: async ({ subscriptionData: { data } }) => {
      if (data?.onPayoutUpdate) {
        // update list transaction invalidated
        await updateOrCreate(data.onPayoutUpdate);
      }
    },
  });

  return <></>;
};

export default PayoutSub;
