export default {
  'teamMemberDialog.label.role': `Role`,
  'teamMemberDialog.label.cardInfo': `A member can have cards and see his own transactions.`,
  'teamMemberDialog.label.informations': `Informations`,
  'teamMemberDialog.label.authorizations': `Authorizations`,
  'teamMemberDialog.label.createAndInvite': `Create and Invite`,
  'teamMemberDialog.label.deleteUser': 'Delete user',
  'teamMemberDialog.label.adminDescription': `
  An administrator occupies a highly strategic role in running a business. Appointed by the shareholders, he is part of the board of directors and votes the most important decisions for the life of his company. It is the face of the latter with its employees, service providers, partners and sometimes the general public.`,
  'teamMemberDialog.label.collaboratorDescription': `An employee is a valuable help for a manager to achieve his goals.`,
  'teamMemberDialog.label.accountantDescription': `An accountant is responsible for keeping the accounts. Not to mention that it establishes and closes the tax links.`,
  'teamMemberDialog.error.noRoleSelected': `Please select a role.`,
  'teamMemberDialog.error.noTeamSelected': `Please select a team.`,
  'teamMemberDialog.error.invalidAddressEmail': `Please set a  valid email address.`,
  'teamMemberDialog.error.nameNotSet': `Please set member's name.`,
  'teamMemberDialog.error.firstNameNotSet': `Please set member's firstName.`,
};
