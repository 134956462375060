import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  menu: {
    '& > div': {
      border: `4px solid transparent`,
      borderRadius: 10,
    },
    '& p': {
      transform: 'translateY(-15px)',
      color: theme.palette.common.white,
    },
  },
  menuItem: {
    width: 60,
    height: 60,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  menuActive: {
    '& > div': {
      borderColor: `#FFF`,
    },
    '& p': {
      transform: 'none',
      color: theme.palette.common.white,
    },
  },
}));
