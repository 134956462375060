import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const Transaction = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g
        id="Groupe_12372"
        data-name="Groupe 12372"
        transform="translate(12062.883 -11773)"
      >
        <rect
          id="Rectangle_2488"
          data-name="Rectangle 2488"
          width="25"
          height="25"
          transform="translate(-12062.883 11773)"
          fill="none"
        />
        <g
          id="Groupe_12441"
          data-name="Groupe 12441"
          transform="translate(-12056.521 11773)"
        >
          <g
            id="Groupe_12441-2"
            data-name="Groupe 12441"
            transform="translate(0.011 0)"
          >
            <path
              id="Tracé_919"
              data-name="Tracé 919"
              d="M60.693,18.951v5.794a1.4,1.4,0,0,1-1.383,1.386,1.339,1.339,0,0,1-1.36-1.36V22.26l-6.382,6.382a1.378,1.378,0,0,1-1.949-1.949L56,20.311H53.49a1.385,1.385,0,0,1,0-2.769l5.82.026A1.377,1.377,0,0,1,60.693,18.951Z"
              transform="translate(-49.214 -17.542)"
            />
          </g>
          <path
            id="Tracé_920"
            data-name="Tracé 920"
            d="M60.312,22.117a1.381,1.381,0,0,1,0,1.949l-6.386,6.382H56.44a1.4,1.4,0,0,1,1.357,1.36,1.381,1.381,0,0,1-.409.974,1.259,1.259,0,0,1-.974.409H50.568a1.333,1.333,0,0,1-1.357-1.357V25.988A1.38,1.38,0,0,1,50.594,24.6a1.447,1.447,0,0,1,1.383,1.386V28.5l6.386-6.382A1.376,1.376,0,0,1,60.312,22.117Z"
            transform="translate(-49.211 -8.077)"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Transaction;
