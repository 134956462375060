import { useApolloClient, useMutation } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Notifications from '@material-ui/icons/Notifications';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MARK_NOTIFICATION_AS_READ } from '../../graphql/notification/mutation';
import { AccountNotifications_getAccountNotifications_notification } from '../../graphql/notification/types/AccountNotifications';
import {
  MarkNotification,
  MarkNotificationVariables,
} from '../../graphql/notification/types/MarkNotification';
import { defaultErrorHandler } from '../../utils/snackBarUtils';
import CustomDialog from '../Common/CustomDialog';
import NotificationDetails from './NotificationContent/NotificationDetails';
import NotificationList from './NotificationContent/NotificationList';
import {
  getAccountNotificationByNotifId,
  getNotificationById,
  getNotViewedAccountNotifNumber,
  updateCacheAfterMarkNotification,
} from './NotificationLogic';
import styles from './style';
import { useGetNotification } from '../customHooks/notification/listNotification';
import useGetUrl from '../customHooks/file/url';
import { BUCKET_TYPE_ENUM } from '../../enums/file';

interface NotificationPropsInterface {}

const Notification = (props: NotificationPropsInterface) => {
  const classes = styles();
  const intl = useIntl();
  const client = useApolloClient();
  const [fileInfo, setFileInfo] = useState({
    uuid: '',
    filename: '',
    filetype: '',
  });
  const [urls, setUrls] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);

  const [signedUrl, setSignedUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedNotif, setSelectedNotif] =
    useState<AccountNotifications_getAccountNotifications_notification | null>(
      null,
    );
  const handleClick = () => setOpen((prev) => !prev);

  const { data: accountNotifications } = useGetNotification();
  const { loading: getFileUrlLoading, getUrl: getSignedUrl } = useGetUrl();

  const [markNotif] = useMutation<MarkNotification, MarkNotificationVariables>(
    MARK_NOTIFICATION_AS_READ,
    {
      update: updateCacheAfterMarkNotification,
    },
  );

  const handleDisplayDetails = async (notificationId: string) => {
    const notification = getNotificationById(
      accountNotifications,
      notificationId,
    );
    if (notification) {
      setSelectedNotif(notification);
      const accountNotification = getAccountNotificationByNotifId(
        accountNotifications,
        notificationId,
      );

      if (
        accountNotification &&
        accountNotification.id &&
        accountNotification.viewed === false
      ) {
        markNotif({ variables: { id: accountNotification.id } });
      }

      if (notification?.notifAttachments?.length) {
        setLoading(true);
        const { notifAttachments } = notification;
        if (notifAttachments?.length > 1) {
          let _urls: Array<string> = [];
          for (let k = 0; k < notification.notifAttachments.length; k++) {
            const attachement = notification.notifAttachments[k];
            if (attachement && attachement.uuid && attachement.filename) {
              const { filename, uuid } = attachement;
              const _result = await getSignedUrl({
                uuid,
                filename,
                type: BUCKET_TYPE_ENUM.NOTIFICATION,
              });
              if (_result) {
                _urls = [..._urls, _result];
              }
            }
          }
          setUrls(_urls);
        } else {
          const { uuid, filename, filetype } =
            notification.notifAttachments[0] || {};
          if (!!uuid && !!filename && !!filetype) {
            setFileInfo({
              uuid,
              filename,
              filetype,
            });
            await getSignedUrl({
              uuid,
              filename,
              type: BUCKET_TYPE_ENUM.NOTIFICATION,
            })
              .then((url) => {
                if (url) setSignedUrl(url);
              })
              .catch((error) => {
                defaultErrorHandler(
                  client,
                  error,
                  intl,
                  'notification.error.cannotGetMedia',
                );
              });
          }
        }
        setLoading(false);
      } else {
        setFileInfo({
          uuid: '',
          filename: '',
          filetype: '',
        });
        setSignedUrl('');
      }
    }
  };

  return (
    <>
      <IconButton
        aria-label="notification"
        onClick={handleClick}
        disabled={getFileUrlLoading}
      >
        <Badge
          badgeContent={getNotViewedAccountNotifNumber(accountNotifications)}
          color="primary"
        >
          <Notifications className={classes.icon} />
        </Badge>
      </IconButton>
      <CustomDialog
        dialogTitle="Notifications"
        open={open}
        handleClose={handleClick}
        closable={true}
      >
        <Box className={classes.notificationLayout}>
          <Box className={classes.notifEntries}>
            <Typography variant="h3" color="primary" gutterBottom>
              <FormattedMessage id="notification.text.notificationList" />
            </Typography>
            <Box className={classes.entries}>
              <NotificationList
                accountNotifications={accountNotifications}
                handleDisplayDetails={handleDisplayDetails}
              />
            </Box>
          </Box>
          <Box className={classes.notifContent}>
            <NotificationDetails
              loading={getFileUrlLoading || loading}
              notification={selectedNotif}
              fileSignedUrl={signedUrl}
              filetype={fileInfo.filetype}
              urlsProvider={urls}
            />
          </Box>
        </Box>
      </CustomDialog>
    </>
  );
};

export default Notification;
