import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.common.white,
      backgroundImage: "linear-gradient(to right, #5E71FF 30%, #00E5FF)",
      height: 100,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      color: theme.palette.common.white,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  dialogTitle: any;
  closable: boolean;
  onClose: () => void;
}

const CustomDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { classes, onClose, dialogTitle, closable } = props;
  return (
    <MuiDialogTitle disableTypography={true} className={classes.dialogTitle}>
      <Typography variant="h6" style={{ fontSize: 20, fontWeight: 500 }}>
        {dialogTitle}
      </Typography>
      {closable ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          tabIndex={2}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default CustomDialogTitle;
