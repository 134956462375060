import gql from 'graphql-tag';
import { CHEQUE_FRAGMENT } from './fragment';

export const ON_CHEQUE_UPDATE = gql`
  subscription OnChequeUpdate($companyId: ID!) {
    onChequeUpdate(companyId: $companyId) {
      ...ChequeInfo
    }
  }
  ${CHEQUE_FRAGMENT}
`;
