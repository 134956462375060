import { useApolloClient, useSubscription } from '@apollo/client';
import React from 'react';
import {
  OnCardTransactionCreated,
  OnCardTransactionCreatedVariables,
} from '../../../../graphql/graphql_subscription/types/OnCardTransactionCreated';
import { ON_CARD_TRANSACTION_CREATE } from '../../../../graphql/graphql_subscription/transaction';
import { useCreateOrUpdateTransactionCache } from '../../../customHooks/transaction/tools';

interface CardTransactionSubPropsInterface {
  companyId: string;
}

const CardTransactionSub = (props: CardTransactionSubPropsInterface) => {
  const { companyId } = props;
  const client = useApolloClient();
  const { updateOrCreate } = useCreateOrUpdateTransactionCache();
  useSubscription<OnCardTransactionCreated, OnCardTransactionCreatedVariables>(
    ON_CARD_TRANSACTION_CREATE,
    {
      variables: { companyId },
      client,
      onSubscriptionData: async ({ subscriptionData: { data } }) => {
        if (data?.onCardTransactionCreate) {
          await updateOrCreate(data.onCardTransactionCreate);
        }
      },
    },
  );

  return <></>;
};

export default CardTransactionSub;
