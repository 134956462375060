import gql from 'graphql-tag';
import { WALLET_RECURRENCE_FRAGMENT } from '../wallet/fragment';
import { BENEFICIARY_RECURRENCE_FRAGMENT } from '../beneficiary/fragment';

const RECURRENCE_FRAGMENT = gql`
  fragment RecurrenceInfo on Recurrence {
    id
    wallet {
      ...WalletRecurrenceInfo
    }
    beneficiary {
      ...BeneficiaryRecurrenceInfo
    }
  }
  ${WALLET_RECURRENCE_FRAGMENT}
  ${BENEFICIARY_RECURRENCE_FRAGMENT}
`;

const RECURRENCE_TRANSACTION_FRAGMENT = gql`
  fragment RecurrenceTransactionInfo on Recurrence {
    id
    dateDebut
    dateFinale
    nextExecutionDate
    state
    amount
    type
  }
`;
export { RECURRENCE_FRAGMENT, RECURRENCE_TRANSACTION_FRAGMENT };
