import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const Logout = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g id="logout" transform="translate(0 -0.008)">
        <path
          id="Tracé_177"
          data-name="Tracé 177"
          d="M285.016,209.133h-7.122a.562.562,0,0,1,0-1.125h7.122a.562.562,0,0,1,0,1.125Zm0,0"
          transform="translate(-267.587 -200.691)"
        />
        <path
          id="Tracé_178"
          data-name="Tracé 178"
          d="M400.566,134.718a.563.563,0,0,1-.4-.96l2.414-2.414-2.414-2.414a.562.562,0,1,1,.8-.8l2.811,2.811a.562.562,0,0,1,0,.8l-2.811,2.811A.559.559,0,0,1,400.566,134.718Zm0,0"
          transform="translate(-385.948 -123.465)"
        />
        <path
          id="Tracé_179"
          data-name="Tracé 179"
          d="M6,18a1.545,1.545,0,0,1-.465-.07l-4.512-1.5A1.513,1.513,0,0,1,0,15V1.507A1.5,1.5,0,0,1,1.5.008a1.546,1.546,0,0,1,.465.07l4.511,1.5A1.513,1.513,0,0,1,7.5,3.007V16.5A1.5,1.5,0,0,1,6,18ZM1.5,1.133a.376.376,0,0,0-.375.375V15a.39.39,0,0,0,.26.361l4.49,1.5A.407.407,0,0,0,6,16.876a.376.376,0,0,0,.375-.375V3.007a.39.39,0,0,0-.26-.361l-4.49-1.5A.407.407,0,0,0,1.5,1.133Zm0,0"
          transform="translate(0 0)"
        />
        <path
          id="Tracé_180"
          data-name="Tracé 180"
          d="M37.163,6.005a.562.562,0,0,1-.562-.562V2.07a.938.938,0,0,0-.937-.937H27.23a.562.562,0,1,1,0-1.125h8.434A2.063,2.063,0,0,1,37.725,2.07V5.443A.562.562,0,0,1,37.163,6.005Zm0,0"
          transform="translate(-25.731)"
        />
        <path
          id="Tracé_181"
          data-name="Tracé 181"
          d="M184.893,283.337h-3a.562.562,0,0,1,0-1.125h3a.938.938,0,0,0,.937-.937V277.9a.562.562,0,0,1,1.125,0v3.374A2.063,2.063,0,0,1,184.893,283.337Zm0,0"
          transform="translate(-174.96 -267.586)"
        />
      </g>
    </SvgIcon>
  );
};

export default Logout;
