export default {
  'donut.total': 'Total',
  'dialog.createTransaction': 'Création de virement ou de prélèvement',
  'label.transaction.category': 'Catégorie',
  'label.transaction.modality': 'Modalité',
  'dialog.create.transaction.ref': 'Référence',
  'label.transaction.amount': 'Montant',
  'label.transaction.beneficiary': 'Contrepartie',
  'label.transaction.dateTransaction': 'Date',
  'label.title': 'Intitulé :',
  'label.ref': 'Ref :',
  'transaction.bic.notValid': 'Le BIC saisi est incorrect',
  'transaction.iban.notValid': "L'IBAN saisi est incorrect",
  'transaction.recurrence.weekly': 'Hebdomadaire',
  'transaction.recurrence.monthly': 'Mensuel',
  'transaction.recurrence.yearly': 'Annuel',
  'transaction.label.card.out': 'Retrait',
  'transaction.label.card.payment': 'Paiement',
  'transaction.label.card.refund': 'Remboursement',
  'transaction.modality.transfer': 'Transfert',
  'transaction.modality.payin': 'Virement',
  'transaction.modality.card': 'Carte',
  'transaction.modality.abonnement': 'Forfait',
  'transaction.modality.frais': 'Frais',
  'transaction.modality.payout': 'Prélèvement',
  'transaction.modality.check': 'Chèque',
  'transaction.grid.noData': `Aucune transaction pour l'instant`,
  'transaction.validate.noData': 'En attente transaction à valider',
  'transaction.virement.create.success':
    'Nouvelle transaction ajoutée avec succès.',
  'transaction.success.update': 'Transaction mise à jour avec succès.',
  'transaction.success.payin.update': 'Virement mise à jour avec succès.',
  'transaction.success.payout.update': 'Prélèvement mise à jour avec succès.',
  'transaction.success.card.update':
    'Transaction par carte mise à jour avec succès.',
  'transaction.error.getLastTransaction':
    'Erreur lors de la récupération des dernières transactions.',
  'transaction.virement.create.error':
    'Erreur lors de la création du virement.',
  'transaction.error.getTransactionState': `Erreur lors de la récupération de l'état du compte.`,
  'transaction.error.getTransactionCategoriesStat':
    'Erreur lors de la récupération des statistiques des transactions par catégories.',
  'transaction.error.getTransactionFluxStat':
    'Erreur lors de la récupération des statistiques des transactions par flux.',
  'transaction.error.getValidatedTransaction':
    'Erreur lors de la récupération des transactions validées.',
  'transaction.error.getInValidatedTransaction':
    'Erreur lors de la récupération des transactions non validées.',
  'transaction.error.getBalance':
    'Erreur lors de la récupération de la balance pour les transactions.',
  sender: 'Expéditeur :',
  receiver: 'Récépteur',
  'transaction.text.noLevy': `Aucun prélèvement pour l'instant.`,
  'transaction.error.getDetails':
    'Erreur lors de la récupération  des détails de la transaction.',
  'transaction.success.virement.treezor': `Virement effectué avec succès.`,
  'transaction.error.virement.treezor': `Erreur lors de l'opération du transfert. Veuillez réessayer ultérieurement.`,
  'dialogue.title': 'CODE DE VALIDATION',
  'dialogue.content': `Un code de validation vous a été envoyé par mail, veuillez récupérer le code pour pouvoir effectuer le virement. Si vous n'avez pas encore reçu de code, veuillez`,
  'account.code.send':
    'Veuillez vérifier votre adresse email et copier ici votre code',
  'dialogue.button.send': 'Envoyer',
  'dialogue.button.send.click': 'cliquez ici',
  'dialogue.form.valid': 'Valider',
  'dialogue.code.send': 'Veuillez vérifier votre e-mail et insérer le code.',
  'transaction.none': 'Une seule fois',
  'transaction.modality.cheque': 'Chèque',
  'transaction.modality.facture': 'Facture',

  'popup.amount.empty': 'Veuillez saisir un montant valide',
  'transaction.amount.nan': 'Le montant doit être un chiffre',

  'transaction.payout.type': 'Frais',
  'transaction.payout.abonnement': 'Forfait',
  'transaction.payout.topup': 'TopUp',
  'transaction.card.refund': 'Remboursement ',
  'transaction.card.payment': 'Paiement',
  'transaction.card.withdrawal': 'Retrait',
  'transaction.card.topup': 'Topup',
  'transaction.card.command': 'Commande carte',
  'transaction.card.deposit': 'Dépôt de capital',
  'transaction.label.transactionProof': 'Preuve virement',
  'transaction.error.transactionProofs':
    'Erreur lors du téléchargement de la preuve de virement',
  'prelevement.canceled.success': 'Prélèvement annulée avec succès',
  'canceled.prelevement': 'Annuler Prélèvements',
  'create.beneficiary.error':
    'Veuillez vérifier les informations bénéficiaires',
};
