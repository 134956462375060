import gql from 'graphql-tag';
import { ACCOUNT_NOTIF_FRAGMENT } from './fragment';

export const ACCOUNT_NOTIFICATIONS = gql`
  query AccountNotifications($page: Int, $size: Int) {
    getAccountNotifications(page: $page, size: $size) {
      ...AccountNotificationInfo
    }
  }
  ${ACCOUNT_NOTIF_FRAGMENT}
`;
