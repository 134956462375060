import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import { ApolloProvider } from "@apollo/client";
import React, {
  FC,
  useState,
  createContext,
  useContext,
  Suspense,
} from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";

import { getLocale } from "./services/Locale";
import GlobalSnackBar from "./components/Common/GlobalSnackBar/GlobalSnackBar";
import GlobalSubListener from "./components/Common/GlobalSubListner";
import Routes from "./route";
import customTheme from "./theme";
import { VIEW } from "./utils/constant";

import messages from "./locales/messages";
import {
  setThemeTypeStorage,
  getThemeTypeStorage,
} from "./services/LocalStorage";
import { PaletteType } from "@material-ui/core";
import CustomProgress from "./components/Common/CustomProgress/CustomProgress";
import { IntlProvider } from "react-intl";
import { initializeClient } from "./apolloClient";
import { ACCESS_TOKEN_KEY } from "./services/LocalStorage";
import { useAppSelector } from "./redux/hook";
import { init } from "./indexDB/codegen/sapheer";
let mode: PaletteType = VIEW.LIGHT;

// toggle theme from app bar
const ThemeToggleContext = createContext({
  toggle: () => {},
});

const App: FC = () => {
  const tokenRedux = useAppSelector((state) => state.login.token);
  const themeStorage = getThemeTypeStorage();
  const [themeType, setThemeType] = useState(themeStorage);
  setThemeTypeStorage(themeType);
  const toggleTheme = () => {
    themeType === VIEW.LIGHT
      ? setThemeType(VIEW.DARK)
      : setThemeType(VIEW.LIGHT);
    setThemeTypeStorage(themeType);
  };
  const { mode: modeGlobal, theme } = customTheme(themeType);
  mode = modeGlobal;

  const locale = getLocale();
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  const client = initializeClient(token || tokenRedux);
  // --- maj apple-app-site-association
  return (
    <ApolloProvider client={client}>
      <IntlProvider locale={locale} messages={(messages as any)[locale]}>
        <ThemeToggleContext.Provider value={{ toggle: toggleTheme }}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalSnackBar />
            <GlobalSubListener />
            <Suspense fallback={<CustomProgress />}>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </Suspense>
          </ThemeProvider>
        </ThemeToggleContext.Provider>
      </IntlProvider>
    </ApolloProvider>
  );
};
export const DB = init();
export { mode };
export const useTheme = () => useContext(ThemeToggleContext);
export default App;
