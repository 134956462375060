import gql from 'graphql-tag';

export const COMPANY_FRAGMENT = gql`
  fragment CompanyInfo on Company {
    id
    createdAt
    legalName
    legalForm
    legalFormLabel
    legalRegistrationNumber
    legalRegistrationDate
    legalShareCapital
    address
    address2
    postCode
    city
    country
    canCreateCard
    readOnly
    logoUrl
    activity
    slogan
  }
`;
