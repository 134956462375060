import gql from 'graphql-tag';
import { COMPANY_FRAGMENT } from './fragment_company';
import { WALLET_FRAGMENT } from './fragment_Wallet';
import { PERSON_FRAGMENT } from './fragment_person';
import { ACCOUNT_FRAGMENT } from './fragment_account';
import { RIGHT_FRAGMENT } from '../../members/fragments/fragment_right';
import { ROLES_FRAGMENT } from '../../members/fragments/fragment_role';
import { TEAM_FRAGMENT } from '../../members/fragments/fragment_team';

export const COMPANY_SETTING_FRAGMENT = gql`
  fragment CompanySettingInfo on MyCompanySetting {
    company {
      ...CompanyInfo
    }
    account {
      ...AccountInfo
    }
    team {
      ...teamInfo
    }
    rights {
      ...RightInfo
    }
    role {
      ...RoleInfo
    }
    wallet {
      ...WalletInfo
    }
    person {
      ...PersonInfo
    }
  }
  ${COMPANY_FRAGMENT}
  ${RIGHT_FRAGMENT}
  ${ROLES_FRAGMENT}
  ${TEAM_FRAGMENT}
  ${WALLET_FRAGMENT}
  ${PERSON_FRAGMENT}
  ${ACCOUNT_FRAGMENT}
`;
