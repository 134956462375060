import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";


export const accountStateSlice = createSlice({
    name: "accountState",
    initialState:{
        dataAccountState:"",
    },
    reducers:{
        setDataAccountState:(state,action:PayloadAction<string>) =>{
            state.dataAccountState=action.payload
        }
    }
})
export const {setDataAccountState}= accountStateSlice.actions
export default accountStateSlice.reducer