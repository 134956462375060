export const removeObject = (list: any, obj: any) => {
  let tab: any[] = [];
  if (list?.length && obj.id) {
    tab = [...list];
    const index = tab.findIndex((t) => t?.id == obj.id);
    if (index != -1) {
      tab.splice(index, 1);
    }
  }
  return tab;
};

export const addOrUpdateObject = (list: any, obj: any) => {
  let tab: any[] = [];
  if (list?.length) {
    tab = [...list];
    if (obj?.id) {
      const index = tab.findIndex((t) => t?.id == obj.id);
      if (index == -1) {
        //Add
        tab = [obj, ...tab];
      } else {
        //Update
        tab[index] = obj;
      }
    }
  } else {
    //Add
    tab = [obj, ...tab];
  }
  return tab;
};
