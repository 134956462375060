export default {
  'transaction.creancy.update.success':
    'Mis à jour du créancier effectuée avec succès',
  'transaction.creancy.add.success': 'Ajout du créancier effectuée avec succès',
  'transaction.creancy.delete.success':
    'Suppression de créancier effectuée avec succès',
  'transaction.beneficiary.delete.success':
    'Suppression de beneficiaire effectuée avec succès',
  'transaction.delete.title': 'Suppression',
  'transaction.creancy.message.delete':
    'Voulez-vous vraiment supprimer ce créancier?',
  'dialog.createTransaction.title': 'Choisissez le type de transaction :',
  'dialog.createVirement': 'Créer virement',
  'dialog.createPrelevement': 'Créer prélèvement',
  'dialog.create.virement.beneficiary': 'Bénéficiaire',
  'dialog.create.virement.beneficiary.name': 'Nom',
  'dialog.create.virement.beneficiary.bic': 'BIC',
  'dialog.create.virement.beneficiary.iban': 'IBAN',
  'dialog.create.virement.details': 'Intitulé',
  'dialog.create.virement.title': 'Intitulé',
  'dialog.create.virement.details.amount': 'Montant',
  'dialog.create.virement.details.category': 'Catégories',
  'dialog.create.virement.details.recurrence': 'Récurrence',
  'dialog.create.virement.details.file': 'Pièce justificative (1)',
  'dialog.create.virement.details.textarea': 'Note',
  'dialog.create.virement.details.button.addBeneficiary':
    'Ajouter plusieurs bénéficiaires (CSV)',
  'dialog.create.prelevement.creditor': 'Créancier',
  'dialog.create.prelevement.name': 'Nom',
  'dialog.create.prelevement.ics': 'Identifiant créancier',
  'dialog.create.prelevement.reference': 'Référence Unique de Mandat',
  'creancy.ics.empty': "Veuillez insérer l'identifiant créancier",
  'creancy.name.empty': 'Veuillez insérer le nom du créancier',
  'creancy.ref.empty': 'Veuillez insérer la référence unique de mandat',
  'dialog.create.prelevement.ref': 'Ref',
  'dialog.create.prelevement.search': 'Rechercher un créancier',
  'dialog.button.cancel': 'Annuler',
  'dialog.button.create': 'Créer',
  'dialog.create.virement.details.endTransaction': 'Date fin virement',
  'dialog.create.virement.details.dateTransaction': 'Date transaction',
  'dialog.create.virement.search': 'Rechercher un bénéficiaire',
  'transaction.name.empty': 'Le nom du bénéficiaire est requis',
  'transaction.title.empty': "L'intitulé du virement est requis",
  'transaction.ref.empty': 'La référence est requis',
  'transaction.amount.empty': 'Montant invalide',
  'transaction.categoryId.empty':
    'Veuillez saisir la catégorie de la transaction',
  'transaction.dateTransaction.notValid':
    'Veuillez saisir une date valide pour la transaction',
  'transaction.recurrenceDate.notValid': 'Veuillez saisir une date fin valide',
  'transaction.amount.moreThan.authoriezBalance':
    'Vous avez saisi un montant supérieur à votre solde actuel',

  'transaction.beneficiary.external':
    'Impossible de créer un virement pour un bénéficiaire externe',
  'transaction.beneficiary.message.delete':
    'Voulez-vous vraiment supprimer ce bénéficiaire?',
  'transaction.amount.min':
    'Le montant de la transaction doit être supérieur à 1 Euro',

  'virement.dialog.text': `(1) Si vous avez une pièce justificative de votre virement. Merci de l’importer ici`,
  'code.confirmed.by.mobile': 'Votre transaction est validé par mobile.',
  'code.confirmed.by.mobile.error':
    'Validation de la transaction par mobile a échoué',
};
