import gql from 'graphql-tag';
import { COMPANY_BENEFICIARY_FRAGMENT } from '../company/fragment';
const BENEFICIARY_FRAGMENT = gql`
  fragment BeneficiaryInfo on Beneficiary {
    id
    name
    treezorUserId
    iban
    bic
  }
`;

const CREANCY_FRAGMENT = gql`
  fragment CreancyInfo on Creancy {
    id
    ics
    ref
    name
  }
`;

const BENEFICIARY_TRANSACTION_FRAGMENT = gql`
  fragment BeneficiaryTransactionInfo on Beneficiary {
    id
    name
    treezorUserId
    iban
    bic
    ics
    mandateId
    company {
      ...CompanyBeneficiaryInfo
    }
  }
  ${COMPANY_BENEFICIARY_FRAGMENT}
`;

const BENEFICIARY_COMPANY_FRAGMENT = gql`
  fragment BeneficiaryCompanyInfo on Beneficiary {
    id
    name
    treezorUserId
    iban
    bic
  }
`;

const BENEFICIARY_RECURRENCE_FRAGMENT = gql`
  fragment BeneficiaryRecurrenceInfo on Beneficiary {
    id
    name
    treezorUserId
    iban
    bic
  }
`;
export {
  BENEFICIARY_FRAGMENT,
  CREANCY_FRAGMENT,
  BENEFICIARY_RECURRENCE_FRAGMENT,
  BENEFICIARY_TRANSACTION_FRAGMENT,
  BENEFICIARY_COMPANY_FRAGMENT,
};
