import gql from 'graphql-tag';

export const MARK_NOTIFICATION_AS_READ = gql`
  mutation MarkNotification($id: ID!) {
    markNotificationAsRead(id: $id) {
      id
      viewed
    }
  }
`;
