import gql from 'graphql-tag';
import { CHEQUE_FRAGMENT } from './fragment';

export const CHEQUE = gql`
  query Cheque($id: ID!) {
    cheque(id: $id) {
      ...ChequeInfo
    }
  }
  ${CHEQUE_FRAGMENT}
`;

export const CHEQUES = gql`
  query Cheques($filter: ChequeFilterInputs) {
    cheques(filter: $filter) {
      cheques {
        ...ChequeInfo
      }
      page
      size
      totalPage
      totalElements
    }
  }
  ${CHEQUE_FRAGMENT}
`;

export const NOT_VIEWED_CHEQUE = gql`
  query NotViewedCheques {
    notViewedCheques {
      ...ChequeInfo
    }
  }
  ${CHEQUE_FRAGMENT}
`;
