import moment from 'moment';
import { IntlShape } from 'react-intl';
import { CategoriesForStatInterface } from '../components/Dashboard/Statistic/StatisticUtils';
import { IItems } from '../interface/CommonInteface';
import { CURRENCY, CURRENCY_SYMBOL, ROLE_TYPE } from './constant';
import {
  MyRoleRights_me,
  MyRoleRights_me_roleRights,
  MyRoleRights_me_role,
} from '../graphql/account/types/MyRoleRights';
import { StatLabelInterface } from '../components/Dashboard/AccountState/AccountStateProvider';
import axios from 'axios';
import { ListCategory_listCategory } from '../graphql/graphql_transaction/types/ListCategory';
import { ListCard_listCard } from '../graphql/graphql_card/card/types/ListCard';

// Provisoir
const API_IP_URL = 'https://api.ipify.org?format=json';

const compare = (a: IItems, b: IItems) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};
export const emailToLowerCase = (email: string) => {
  const _email = removeSpace(email);
  return _email.toLowerCase();
};
export const formatIban = (iban: string): string => {
  const _val = iban.replace(/ /g, '');
  if (0 < _val.length % 4) {
    return _val.replace(/(([0-9a-zA-Z]){4}(?!\s))/g, '$1 ');
  }
  return iban;
};

export const sortArray = (array: any, type?: string): IItems[] => {
  return array.sort(compare);
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const countPhysicalCard = (cards: (ListCard_listCard | null)[]) => {
  if (cards?.length) {
    let physics = cards.filter((card) => card?.isPhysical === true);
    let cardNumber = {
      nbPhysics: physics.length,
      nbVirtual: cards.length - physics.length,
    };
    return cardNumber;
  }
  return {
    nbPhysics: 0,
    nbVirtual: 0,
  };
};

export const checkPhone = (phone: string | undefined) => {
  if (phone && phone[0] && phone[0] !== '+') {
    return '+' + phone;
  }
  return phone;
};

export const compareTwoObject = (obj1: Object, obj2: Object): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const getString = (_name: string, isTitle?: boolean): string => {
  const limit = isTitle ? 25 : 15;
  let name = _name;
  //check is Last
  if (name && name.length) {
    let isLast = name.length - limit;
    if (isLast === 1) {
      return name;
    }
  }
  if (!/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/.test(name)) {
    name = name.replace(/\s+$/, '');
  }
  if (name.length < limit) return name;
  else {
    let result = name.substring(0, limit);
    return (result += '...');
  }
};

export const formatDate = (date: string): string => {
  return moment(date).format('DD MMMM YYYY');
};
export const formatFactureDate = (date: string): string => {
  return moment(date).format('DD-MMMM-YYYY');
};

export const isNotValidCardNumber = (cardNumber: string) => {
  const _cn = cardNumber.replace(/ /g, '');
  return !cardNumber || isNaN(+_cn);
};
export const arrondi = (nombre: number): string => {
  return nombre.toFixed(2);
};

export const arrondiSpec = (nombre: number, decimal: number) => {
  return nombre.toFixed(decimal);
};

export const calculTva = (tva: string, amount: string): number => {
  return (parseFloat(tva.replace('%', '')) / 100) * parseFloat(amount);
};

export const getPersonInitial = (
  firstName: string | null,
  name: string | null,
): string => {
  const firstNameCapital = !!firstName
    ? firstName.slice(0, 1).toUpperCase()
    : '';
  const nameCapital = !!name ? name.slice(0, 1).toUpperCase() : '';
  return `${firstNameCapital}${nameCapital}`;
};

export const getInitial = (toInitial: string): string => {
  if (!toInitial) {
    return '';
  }
  const initialArray = toInitial.split(' ');
  const initial =
    initialArray.length > 1
      ? `${initialArray[0].slice(0, 1)}${initialArray[1].slice(0, 1)}`
      : toInitial.slice(0, 1);
  return initial.toUpperCase();
};

export const checkArray = (array: []) => {
  // array does not exist, is not an array, or is empty
  return !Array.isArray(array) || !array.length;
};
export const insensitiveContains = (word: any, criteria: any): boolean => {
  if (typeof word === 'string' && typeof criteria === 'string') {
    const wordLower = word.toLowerCase();
    const criteriaLower = criteria.toLowerCase();
    return wordLower.includes(criteriaLower);
  } else {
    return word === criteria;
  }
};

export const userHasGivenRights = (
  rightsList: Array<MyRoleRights_me_roleRights | null> | null,
  constantCode: string,
): boolean => {
  let matchOne = false;
  if (rightsList) {
    rightsList.forEach((right) => {
      if (right && right.constantCode === constantCode) {
        matchOne = true;
        return;
      }
    });
  }
  return matchOne;
};

export const isRoleAdmin = (role: MyRoleRights_me_role | null): boolean => {
  if (role) {
    return role.name === ROLE_TYPE.ADMIN;
  }
  return false;
};

export const useHasGivenRole = (
  role: MyRoleRights_me_role | null,
  constantCode: string,
): boolean => {
  if (role) {
    return role.name === constantCode;
  }
  return false;
};

export const dynamicArrayObjSort = (objProperty: string): any => {
  let sortOrder = 1;
  if (objProperty[0] === '-') {
    sortOrder = -1;
    objProperty = objProperty.substr(1);
  }
  return (a: any, b: any) => {
    // next line works with strings and numbers. To customize as needs
    const result =
      a[objProperty] < b[objProperty]
        ? -1
        : a[objProperty] > b[objProperty]
        ? 1
        : 0;
    return result * sortOrder;
  };
};

const arrayFirstColumnComparator = (a: number[], b: number[]): number => {
  if (a[0] < b[0]) return -1;
  if (a[0] > b[0]) return 1;
  return 0;
};

export const isSelectFocused = (value: string) => {
  return 0 < +value;
};

export const formatDataForChart = (dataList: any[]): number[][] => {
  const formattedData: number[][] = [];
  const formattedDataDict: any = {};
  dataList.forEach((data: any) => {
    const fixedTime =
      moment(data.dateTransaction).set({ second: 0 }).unix() * 1000;
    formattedDataDict[fixedTime] = parseFloat(data.total);
  });

  Object.keys(formattedDataDict).forEach((key) => {
    formattedData.push([parseInt(key, 10), formattedDataDict[key]]);
  });

  return formattedData.sort(arrayFirstColumnComparator);
};

export const formatCustomDataForChart = (dataList: any[]): number[][] => {
  const formattedData: number[][] = [];
  const formattedDataDict: any = {};
  dataList.forEach((data: any) => {
    const fixedTime =
      moment(data.dateTransaction).set({ second: 0 }).unix() * 1000;
    formattedDataDict[fixedTime] = parseFloat(data.solde);
  });

  Object.keys(formattedDataDict).forEach((key) => {
    formattedData.push([parseInt(key, 10), formattedDataDict[key]]);
  });

  return formattedData.sort(arrayFirstColumnComparator);
};

export const formatCustomLabelForChart = (
  stat: any[],
): StatLabelInterface[] => {
  const donutStat: StatLabelInterface[] = [];

  stat.forEach((s: any, index: number) => {
    donutStat.push({
      label: s.dateTransaction,
      in: `${s.totalIn}`,
      out: `${s.totalOut}`,
      solde: `${s.solde}`,
    });
  });
  return donutStat;
};

export const twoDecimal = (toTransform: number): number => {
  try {
    return Math.round(toTransform * 100) / 100;
  } catch {
    return 0.0;
  }
};

export const getPercent = (current: number, total: number): number => {
  return (current * 100) / total;
};

export const formatCategoryForStat = (
  categories: Array<ListCategory_listCategory | null>,
): CategoriesForStatInterface => {
  const CategoriesObject: CategoriesForStatInterface = {};

  categories.forEach((category: ListCategory_listCategory | null) => {
    if (category && category.id) {
      CategoriesObject[category.id] = category;
    }
  });
  return CategoriesObject;
};

export const categoriesFieldsItems = (
  categories: CategoriesForStatInterface,
  allLabel: string,
  intl: IntlShape,
): IItems[] => {
  const items: IItems[] = [];
  Object.keys(categories).map((key) => {
    items.push({
      id: key,
      label: intl.formatMessage({ id: `${categories[key].constant_code}` }),
    });
  });
  const _items = sortArray(items);
  _items.unshift({ id: '0', label: allLabel });
  return _items;
};

export const constantToFilterItems = (
  constant: IItems[],
  intl: IntlShape,
): IItems[] => {
  return constant.map((c: IItems) => ({
    id: c.id,
    label: intl.formatMessage({ id: c.label }),
  }));
};

export const roleRightsIsAdmin = (
  myRoleRights: MyRoleRights_me | null,
): boolean => {
  let isAdmin = false;
  if (myRoleRights && myRoleRights.role && myRoleRights.role.name) {
    return myRoleRights.role.name === ROLE_TYPE.ADMIN;
  }
  return isAdmin;
};

export const htmlDecode = (input: string): string => {
  var e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? '' : (e.childNodes[0].nodeValue as string);
};
export const splitFileTypeExt = (
  filetype: string,
): { type: string; ext: string } => {
  let fileTypeExt = { type: '', ext: '' };
  if (!!filetype) {
    const split = filetype.split('/');
    fileTypeExt = { type: split[0], ext: split[1] };
  }
  return fileTypeExt;
};

export const spaceString = (someString: string) => {
  const matchString = someString && someString.match(/.{1,4}/g);
  return matchString ? matchString.join(' ') : '';
};

export const removeSpace = (word: string): string => {
  return word.replace(/ /g, '');
};

export const getIp = () => {
  return axios
    .get(API_IP_URL)
    .then((result) => result)
    .catch((err) => {
      return { data: null };
    });
};

export const setCurrency = (currency: string | null, defaultEuro: boolean) => {
  if (defaultEuro) return CURRENCY_SYMBOL[CURRENCY.EUR];
  return CURRENCY_SYMBOL[currency ? currency : CURRENCY.EUR];
};

export const checkPrice = (price: string) => {
  const priceMatchPoint = price.match(new RegExp('.', 'g'));
  const priceMatchComma = price.match(new RegExp(',', 'g'));
  const isPoint = priceMatchPoint && priceMatchPoint.length == 1;
  const isComma = priceMatchComma && priceMatchComma.length == 1;
  if (isPoint && isComma) return false;
  if (
    price.includes(',') &&
    (price.match(new RegExp(',', 'g')) || []).length <= 1
  ) {
    return true;
  }
  return false;
};

export const formatPrice = (amount: number, nFraction?: number): string => {
  const price = amount.toLocaleString('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: nFraction ? nFraction : 0,
  });
  return price;
};
