export default {
  'forgot.title': 'Remember password',
  'forgot.details': "You'll receive link to reset passord in your email",
  'forgot.email.send': 'Please check your email',
  'forgot.button.send': 'Send',
  'email.notValid': 'Email not valid',
  'invite.headTitle': 'Fill in the form to create your account',
  'invite.information': 'Your informations',
  'invite.paragraph.1': 'You have been invited to join the team ',
  'invite.paragraph.2': ' as ',
  'invite.infomrmation': 'My legal information',
  'invite.address': 'My home address',
  'invite.infomrmationAttachment': 'Proof of identity document',
  'invite.infomrmationAttachmentType': 'Type ID',
  'invite.addressAttachmentType': 'Type of proof of address',
  'invite.addressAttachment': 'Proof of address',
  'invite.checkbox': 'I accept the terms and conditions of Sapheer'
};
