export default {
  'card.dialog.title': ' Choisissez le type de carte :',
  'card.pile.locked': 'Activer la carte ',
  'card.dialog.label.physic': 'Carte physique',
  'card.dialog.label.virtual': 'Carte virtuelle',
  'card.dialog.addNew.title': 'Hors forfait !',
  'card.dialog.addNew.paragraph':
    'Vous avez atteint la limite de création de carte.',
  'card.dialog.payement.amountToPay': 'Montant à payer',
  'card.dialog.payement.cardNumber': 'Numéro de carte',
  'card.payement.pay': 'Payer',
  'card.codePin': 'Code Pin',
  'card.selected':
    '  Sélectionner une carte pour prévisualiser les informations.',
  'card.confirmPin': 'Confirmer code pin',
  'card.oldPin': 'Ancien code pin',
  'card.cardAuth.title': 'Détails de la carte',
  'card.cardAuth.subTitle': 'Sécurité',
  'card.cardAuth.paragraph':
    'Pour des raisons de sécurité, vous devez fournir un mot de passe afin de visualiser en détails le numéro PAN et le CVV de la carte',
  'card.cardPin.title': 'Code PIN',
  'card.cardDialogue.physicalTitle': 'Carte physique',
  'card.cardDialogue.virtualTitle': 'Carte virtuelle',
  'card.cardDialogue.type': 'Types de carte',
  'card.cardDialogue.ownerTitle': 'Propriétaire',
  'card.cardDialogue.address': 'Addresse',
  'card.cardDialogue.codeTitle': 'Choisir un code à 4 chiffres',
  'card.cardDialogue.priceTitle': 'Limites',
  'card.cardDialogue.member': 'Membre',
  'card.cardDialogue.categories': 'Catégories',
  'card.cardDialogue.cardType': 'Type',
  'card.pin.notMatch': 'Veuillez confirmer votre code pin',
  'card.info.withDrawalLimit':
    " La limite mensuelle maximale pour les retraits d'espèces pour cette carte est de" +
    "1 000,00 €, sur une période de 30 jours à compter de la date d'activation de la" +
    'carte.',
  'card.info.paymentLimit':
    'La limite mensuelle maximale pour les paiements est de 20 000,00 €, sur une' +
    "période de 30 jours à compter de la date d'activation de la carte.",
  'card.virtual': 'Virtuelle',
  'mainCard.nocard': `Vous n'avez pas encore créé de carte. Vous pouvez créer des cartes physiques ou virtuelles pour votre société en cliquant sur le bouton "Créer une carte."`,
  'card.physical': 'Physique',
  'mainCard.label.addCard': 'Créer une carte',
  'mainCard.label.searchCard': 'Rechercher une carte',
  'mainCard.label.transaction': 'Détails des paiements',
  'mainCard.dialogueTitle': 'Création de la carte',
  'mainLabel.notCard': "Vous n'avez pas encore de carte",
  'card.modal.paiement': 'Paiement',
  'card.modal.paiement.day': 'Paiement / jour',
  'card.modal.paiement.week': 'Paiement / semaine',
  'card.modal.retrait': 'Retrait',
  'card.paiement': 'Paiement :',
  'card.retrait': 'Retrait :',
  'card.retrait.pile': 'Retrait',
  'card.foreign': 'Etranger',
  'card.online': 'En ligne',
  'card.nfc': 'Sans Contact',
  'card.active': 'Débloquer',
  'card.details': 'Détails',
  'card.return': 'Retour',
  'card.add.nopayment': 'Veuillez entrer la limite de paiement de la carte',
  'card.selected.categories': 'Veuillez sélectionner une catégorie',
  'card.add.nocashwithDrawal':
    'Veuillez entrer la limite de retrait de la carte',
  'card.add.noDeliveryAddress': 'Veuillez choisir une adresse',
  'card.created.success': 'Carte créée avec succès.',
  'card.updated.success': 'Carte mise à jour avec succès.',
  'card.dialogue.cancel': 'Annuler',
  'card.dialogue.create': 'Créer',
  'card.add.noMember': 'Veuillez choisir un membre pour la carte',
  'card.create.error': `Assurez vous que le propriétaire de la carte a été validé`,
  'card.rigthPanel.description':
    'Sélectionner une carte pour voir les détails de paiement',
  'card.error.notAllowAddPhysical': `ne vous permet plus de créer une carte physique.`,
  'card.error.notAllowAddVirtual': `ne vous permet plus de créer une carte virtuelle.`,
  'card.plus.error.exceed':
    'Vous avez atteint la limite maximale pour ce type de carte',
  'card.pin.empty': 'Veuillez enter un code pin',
  'card.updatePin.success': 'Code PIN mis à jour avec succès.',
  'card.updatePin.error': 'Erreur lors de la mis à jour du code PIN',
  'card.last.card': 'Derniers paiements',
  'card.cardDetails.title': 'Voir détails',
  'card.dialogTitle.checkPassword': 'Vérifier le mot de passe',
  'card.filter.forms': 'Formes',
  'card.filter.title': 'Filtre Carte',
  'card.filter.subtitle': 'Filtrez vos cartes',
  'card.filter.paragraph':
    'Visualiser vos cartes selon vos critères et préférences',
  'card.image.error': 'Erreur lors du chargement des informations de la carte',
  'card.beneficiary.name': 'Bénéficiaire ',
  'card.dialog.text.received': `Vous recevrez votre carte dans un délai de 72h suivant votre localisation.`,

  ACTIVATION_CARD_ERROR: `Erreur lors de l'activation de la carte`,
  CARD_3DS_ERRORS: 'Erreur lors de la mise en place du 3DS',
  TREEZOR_CREATION_CARD_ERROR: 'Erreur lors de la création de la carte',
  ACCOUNT_NEED_TO_VALIDATE: `Votre compte doit être validé`,
  TREEZOR_CONVERSION_CARD_ERROR: `Erreur lors de la création de la carte physique à partir de la carte virtuel`,
  PAIEMENT_OR_WITHDRAW_VALUE_ERROR: `La valeur choisie ne correspond pas à votre offre.`,
  CARD_NOT_ACTIVE: `Veuillez activer la carte`,
  'card.not.treezorCardId': `La carte ne dispose pas d'identifiant chez le créateur`,

  'card.currentPin': 'Pin actuel',
  'currentPin.empty': `Veuillez remplir votre code pin actuel`,
  'card.getPin.error': 'Erreur lors de la recupération du code pin actuel',

  'card.created.error': 'Erreur lors de la création de la carte`',
  'card.created.can.not.created': `Vous n'avez pas le droit de créer une carte`,
  'card.created.need.validate': `Assurez vous que le propriétaire de la carte a été validé`,
  'card.created.paiement.withdrawal.error': `Le paiement ou le retrait est supérieur à celle autorisée`,
  'card.created.pin.error': `Les codes Pin insérés sont différents`,
  'card.created.error.treezor': `Une erreur s'est produite lors de la création de la carte chez treezor`,
  'card.created.covertion.error': `Une erreur s'est produite lors de la conversion de la carte`,
  'card.account.can.t.manage': `Vous n'avez pas le droit de modifier une carte`,
  'card.active.success': `Carte activée avec succès.`,
  'card.created.insufficient.amount': `Vous n'avez pas assez de fonds pour créer cette carte`,
  'card.update.success': `Mise à jour de la carte succès`,
  'card.input.required': `Veuillez contacter le service Sapheer, car des informations de l'utilisateur requises manquent lors de la création de la carte.`,
  'card.company.limited': `Vous n'avez pas le droit de mettre à jour cette carte car votre compte est limité.`,
};
