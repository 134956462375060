export default {
  'reset.title': 'Réinitialisation',
  'reset.details': ' Entrez un nouveau mot de passe',
  'reset.back': 'Retourner à la page de ',
  'reset.link': 'connexion',
  'reset.success': 'Votre mot de passe a été mis à jour avec succès',
  'reset.link.notValid': "Votre lien n'est pas valide",
  'account.data.password.notValid':
    'Le mot de passe doit comporter au moins huit caractères, deux lettres majuscules, deux lettres minuscules, deux chiffre et deux « caractères spéciaux ».',
  'form.password.contentError':
    'Votre mot de passe doit contenir au moins un caractère minuscule, un caractère majuscule, un chiffre et un caractère spécial',
  'pwd.weak': 'Facile',
  'pwd.fair': 'Intermediaire',
  'pwd.good': 'Bon',
  'pwd.strong': 'Sécurisée',
  'pwd.meter': 'Niveau du mot de passe :',
  'reset.password.notMatch': 'Les mots de passe ne sont pas identiques '
};
