import Box, { BoxProps } from '@material-ui/core/Box';
import { getPersonInitial } from '../../../utils/common';
import CustomAvatar from '../CustomAvatar';
import { useAppSelector } from '../../../redux/hook';
import React from 'react';

const PersonAvatar = (props: BoxProps) => {
  const { avatarUrl, myCompany } = useAppSelector((state) => state.account);
  let avatar = avatarUrl || '';
  let name = myCompany?.myAccount?.name || '';
  let firstName = myCompany?.myAccount?.firstName || '';
  return (
    <Box {...props}>
      {!!avatar ? (
        <CustomAvatar style={{ width: 40, height: 40 }}>
          <img width="100%" height="100%" src={avatar} alt="person avatar" />
        </CustomAvatar>
      ) : (
        <CustomAvatar>{getPersonInitial(firstName, name)}</CustomAvatar>
      )}
    </Box>
  );
};

export default PersonAvatar;
