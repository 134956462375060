import {
  clearLocalStorage,
  getAccessToken,
  getConnexionID,
  setAccessToken,
} from '../../../services/LocalStorage';
import { useAppDispatch } from '../../../redux/hook';
import { useNavigate } from 'react-router-dom';
import { clearToken } from '../../../redux/auth/loginSlice';
import useAddConnexion from '../SignIn/addConnexion';
import { DashboardItem, _dashboards } from '../../../indexDB/data';
import { API_ACCOUNT } from '../../../config';
import { fetcherWEB } from '../../../apolloClient';
import {
  Logout,
  LogoutVariables,
} from '../../../graphql/graphql_account/signin/types/Logout';
import { LOGOUT } from '../../../graphql/graphql_account/signin/mutation';

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = getAccessToken() || '';

  const { addConnexion } = useAddConnexion();

  const logoutApi = async (variables: LogoutVariables) => {
    return await fetcherWEB<LogoutVariables, Logout>(
      LOGOUT,
      variables,
      API_ACCOUNT,
    );
  };

  const logout = async () => {
    const connexionString = getConnexionID();
    if (connexionString) {
      const field = JSON.parse(connexionString);
      const { id, email } = field;
      if (id && email)
        await addConnexion({
          id: field.id,
          email: field.email,
          isSignin: false,
        });
    }
    await logoutApi({ token });
    dispatch(clearToken());
    setAccessToken('');
    clearLocalStorage();
    await _dashboards.removeItem(DashboardItem.MY_ID);
    navigate('/login');
  };

  return { logout };
};
