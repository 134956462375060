import { API_URL, API_V2_URL, APP_WS_URL } from './config';
import { ApolloClient, DocumentNode, InMemoryCache } from '@apollo/client';
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { ACCESS_TOKEN_KEY } from './services/LocalStorage';
import { GraphQLClient } from 'graphql-request';
import { PatchedRequestInit } from 'graphql-request/dist/types';

export const initializeClient = (token: string | null) => {
  const wsLink = new WebSocketLink({
    uri: `${APP_WS_URL}`,
    options: {
      reconnect: true,
      connectionParams: {
        authToken: token || '',
      },
    },
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const httpLink = new HttpLink({
    uri: `${API_URL}`,
  });

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink),
  );

  return new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  });
};

type IFetcherWeb = <TVariables, TResponse>(
  query: DocumentNode,
  variables: TVariables,
  endpoint?: string | undefined,
  timeout?: number,
) => Promise<TResponse>;

export const fetcherWEB: IFetcherWeb = async (
  query,
  variables,
  _endpoint,
  timeout,
) => {
  const endpoint = _endpoint ? (_endpoint as string) : (API_V2_URL as string);
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  let option: PatchedRequestInit = {
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  };
  if (timeout) {
    option = { ...option, timeout };
  }
  const graphQLClient = new GraphQLClient(endpoint, option);
  return graphQLClient.request(query, variables as any);
};
