export default {
  'notification.error.getNotifications':
    'Erreur lors de la récupération des notifications.',
  'notification.error.cannotGetMedia': `Erreur lors de la récupération de l'image ou de la vidéo de la notification, veuillez vérifier votre connexion Internet.`,
  'notification.text.noNotification': `Vous n'avez pas encore de notifications.`,
  'notification.text.selectNotification': `Sélectionnez une notification pour l'afficher dans cette partie.`,
  'notification.text.notificationList': 'Liste des notifications',
  'notification.title.functionalities': 'Fonctionnalités',
  'notification.title.messages': 'Messages',
  'notification.title.servicesPlus': 'Services Plus',
};
