import { useEffect } from 'react';
import { TimeItem, _dashboards, _time } from '../../indexDB/data';
import useGenerateToken from './token';

export const useTimerToken = () => {
  const { generateToken } = useGenerateToken();

  const setTime = async (time: number) => {
    await _time.setItem(TimeItem.TOKEN_TIME, time + 1);
  };

  const getTime = async () => {
    const time = await _time.getItem(TimeItem.TOKEN_TIME);
    if (time) {
      if ((time as number) > 60 * 10) {
        // 60sec * 10 min
        await _time.clear();
        await generateToken();
      } else {
        setTime(time as number);
      }
    } else {
      setTime(0);
    }
    return time;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getTime();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
};

export default useTimerToken;
