export default {
  'donut.total': 'Totals',
  'dialog.createTransaction': 'Creating a transfer or direct debit',
  'label.transaction.category': 'Category',
  'label.transaction.modality': 'Modality',
  'dialog.create.transaction.ref': 'Reference',
  'label.transaction.amount': 'Amount',
  'label.transaction.beneficiary': 'Counterpart',
  'label.transaction.dateTransaction': 'Date',
  'label.title': 'Title :',
  'label.ref': 'Ref :',
  'transaction.bic.notValid': 'BIC is invalid',
  'transaction.iban.notValid': 'IBAN code is invalid',
  'transaction.recurrence.weekly': 'Weekly',
  'transaction.recurrence.monthly': 'Monthly',
  'transaction.recurrence.yearly': 'Yearly',
  'transaction.modality.transfer': 'Transfer',
  'transaction.label.card.out': 'Card out',
  'transaction.label.card.payment': 'Payment',
  'transaction.label.card.refund': 'Re-Fund',
  'transaction.modality.payin': 'Pay In',
  'transaction.modality.payout': 'Pay Out',
  'transaction.modality.card': 'Card',
  'transaction.modality.abonnement': 'Subscription',
  'transaction.modality.frais': 'Expenses',
  'transaction.modality.check': 'Check',
  'transaction.grid.noData': 'No transaction',
  'transaction.validate.noData': 'No transaction yet',
  'transaction.dateTransaction.notValid':
    'Please set valid date of transaction',
  'transaction.success.update': 'Transaction updated successfully.',
  'transaction.success.payin.update': 'Transfer updated successfully.',
  'transaction.success.payout.update': 'Levy updated successfully.',
  'transaction.success.card.update':
    'Transaction by card updated successfully.',
  'transaction.virement.create.success': 'New transaction add with success.',
  'transaction.error.getLastTransaction':
    'Error while getting latest transactions.',
  'transaction.virement.create.error': 'Error while creating transfer.',
  'transaction.error.getTransactionState': 'Error while getting account state.',
  'transaction.error.getTransactionCategoriesStat': `Error while getting categories's transaction statistic.`,
  'transaction.error.getTransactionFluxStat': `Error while getting categories's flux statistic.`,
  'transaction.error.getValidatedTransaction':
    'Error while getting validated transactions.',
  'transaction.error.getInValidatedTransaction':
    'Error while getting invalidated transactions.',
  'transaction.error.getBalance':
    'Error while getting balance for transactions.',
  sender: 'Sender :',
  receiver: 'Receiver',
  'transaction.text.noLevy': 'No levy yet.',
  'transaction.error.getDetails': 'Error while getting transaction details.',
  'transaction.success.virement.treezor': `Transfert done successfully.`,
  'transaction.error.virement.treezor': `Error while transfert operation. Please try later.`,
  'dialogue.title': 'VALIDATION CODE',
  'dialogue.content':
    "Thank's to see your mail address and insert code sending",
  'account.code.send': "Thank's to see your email and insert the right code ",
  'dialogue.button.send': 'Send',
  'dialogue.button.send.click': 'Click here',
  'dialogue.form.valid': 'Validate',
  'dialogue.code.send': 'Please check your e-mail , and insert the code',
  'transaction.none': 'Once',
  'transaction.modality.cheque': 'Cheque',
  'transaction.modality.facture': 'Facture',

  'popup.amount.empty': 'Please verify your amount',

  'transaction.amount.nan': 'Amount not a number',

  'transaction.payout.type': 'Payin Fee',
  'transaction.payout.abonnement': 'Subscription',
  'transaction.payout.topup': 'Crédit',
  'transaction.card.refund': 'Re-fund',

  'transaction.card.payment': 'Payment',
  'transaction.card.withdrawal': 'Withdrawal',
  'transaction.card.topup': 'Topup',
  'transaction.card.command': 'Command card',
  'transaction.card.deposit': 'Deposit capital',

  'virement.dialog.text': `(1) If you have a proof of your transfer. Please import it here`,

  'transaction.label.transactionProof': 'Transaction proof',
  'transaction.error.transactionProofs':
    'Error when downloading transaction proof',
  'prelevement.canceled.success': 'Picking cancelled successfully',
  'canceled.prelevement': 'Canceled picking',
  'create.beneficiary.error': 'Please check beneficiary information',
};
