import { Box, Button, useMediaQuery } from "@material-ui/core";
import classname from "classnames";
import React, { FC, forwardRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Card from "../../../Assets/Icons/Card/Card";
import Cheque from "../../../Assets/Icons/Cheque/Cheque";
import Dashboard from "../../../Assets/Icons/Dashboard/Dashboard";
import Service from "../../../Assets/Icons/Service/Service";
import Team from "../../../Assets/Icons/Team/Team";

import MenuItem from "./MenuItem/MenuItem";
import styles from "./style";
import TransactionMenu from "./TransactionMenu/TransactionMenu";
const logo = "/assets/svg/logo.svg";

const Menu: FC = (props) => {
  const history = useLocation();
  const path: string = history.pathname;
  const classes = styles();
  const isFullWidth = useMediaQuery('(min-width:1601px)');

  const CustomRouterLink = forwardRef((props: any, ref) => (
    <NavLink {...props} />
  ));

  return (
    <Box
      className={classname(
        classes.menu,
        classes.bg,
        isFullWidth ? classes.fullWidth : {}
      )}
    >
      <img className={classes.logo} src={logo} alt="logo" />
      <Box className={classes.menuColumn}>
        <Button
          disableRipple
          className={classes.button}
          component={CustomRouterLink}
          to={'/dashboard'}
        >
          <MenuItem
            icon={<Dashboard htmlColor="#FFF" viewBox="0 0 24.918 24.917" />}
            title="Dashboard"
            active={path === '/dashboard'}
          />
        </Button>
        {/* menu transaction*/}
        <TransactionMenu />
        <Button
          disableRipple
          className={classes.button}
          component={CustomRouterLink}
          to={'/card'}
        >
          <MenuItem
            icon={<Card htmlColor="#FFF" viewBox="0 0 26.675 19.562" />}
            title="Cartes"
            active={path === '/card'}
          />
        </Button>
        <Button
          disableRipple
          className={classes.button}
          component={CustomRouterLink}
          to={'/cheque'}
        >
          <MenuItem
            icon={<Cheque htmlColor="#FFF" viewBox="0 0 26.802 26.842" />}
            title="Chèques"
            active={path === '/cheque'}
          />
        </Button>
        <Button
          disableRipple
          className={classes.button}
          component={CustomRouterLink}
          to={'/team'}
        >
          <MenuItem
            icon={<Team htmlColor="#FFF" viewBox="0 0 26.807 19.68" />}
            title="Equipe"
            active={path === '/team'}
          />
        </Button>
        <Button
          disableRipple
          className={classes.button}
          component={CustomRouterLink}
          to={'/service'}
        >
          <MenuItem
            icon={<Service htmlColor="#FFF" viewBox="0 0 26.807 22.408" />}
            title="Services+"
            active={path === '/service'}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default Menu;
