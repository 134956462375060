import { gql } from 'graphql-tag';

export const GET_SIGNED_URL = gql`
  mutation GetSignedUrl($filename: String!, $type: String, $uuid: String!) {
    getSignedUrl(filename: $filename, type: $type, uuid: $uuid)
  }
`;

export const PUT_SIGNED_URL = gql`
  mutation PutSignedUrl($filename: String!, $type: String) {
    putSignedUrl(filename: $filename, type: $type) {
      putSignedUrl
      uuid
    }
  }
`;
