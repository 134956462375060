/**
 * IMPORT ICON = ASSETS
 */

import { ApolloClient } from '@apollo/client';
import {
  CAN_CREATE_TR,
  IS_ALL_TRANSACTION_PANEL,
} from '../graphql/Common/snackbar/query';
import { AllCompanyTransactionsVariables } from '../graphql/transaction/types/AllCompanyTransactions';
import { DownloadAccountStatementVariables } from '../graphql/transaction/types/DownloadAccountStatement';
import {
  NumberKeyMapInterface,
  StringMapInterface,
} from '../interface/CommonInteface';
import { TransactionPanelState } from '../interface/SnackVariableInterface';
/** END IMPORT  */
import { DEFAULT_ROWS_PER_PAGE, DEFAULT_TR_INTO_GRID } from './constant';

const alimentation = '/assets/svg/alimentation.svg';
const assurances = '/assets/svg/assurances.svg';
const autresDepenses = '/assets/svg/autres_depenses.svg';
const autresPrestataires = '/assets/svg/autres_prestataires.svg';
const equipement = '/assets/svg/equipement_et_materiel.svg';
const finance = '/assets/svg/finance.svg';
const fournitures = '/assets/svg/fournitures_de_bureau.svg';
const hotelsLogements = '/assets/svg/hotels_et_logement.svg';
const impotsTaxes = '/assets/svg/impots_et_taxes.svg';
const itElectronique = '/assets/svg/it_et_electronique.svg';
const juridiqueComptabilite = '/assets/svg/juridique_et_comptabilite.svg';
const locationBureau = '/assets/svg/location_de_bureau.svg';
const logistique = '/assets/svg/logistique.svg';
const marketing = '/assets/svg/marketing.svg';
const production = '/assets/svg/production.svg';
const restaurantsBars = '/assets/svg/restaurants_et_bars.svg';
const retraits = '/assets/svg/retraits.svg';
const salaires = '/assets/svg/salaires.svg';
const servicesEnLigne = '/assets/svg/services_en_ligne.svg';
const stationsEssence = '/assets/svg/stations_essence.svg';
const transports = '/assets/svg/transports.svg';
export enum TRANSACTION_MODALITY {
  TRANSFERT = 0, // Wallet to Wallet
  PAYIN = 1, // VIREMENT IN BACK
  PAYOUT = 2, // PRELEVEMENT IN BACK
  CARTE = 3,
  CHEQUE = 4,
  FACTURE = 5,
  SAPHEER_ABONNEMENT = 6,
  SAPHEER_FRAIS = 7,
  REFUND = 8,
  PAIEMENT = 9,
  WITHDRAWAL = 10,
  TOPUP = 12,
  COMMAND_CARD = 13,
  DEPOSIT_CAPITAL = 14,
}

export enum RECURRENCE_TYPE {
  WEEKLY = 1,
  MONTHLY = 2,
  YEARLY = 3,
}

export const RECURRENCE = [
  { state: '1', value: 'transaction.recurrence.weekly' },
  { state: '2', value: 'transaction.recurrence.monthly' },
  { state: '3', value: 'transaction.recurrence.yearly' },
];

export const MODALITY = [
  { modality: 0, value: 'transaction.modality.transfer' },
  { modality: 1, value: 'transaction.modality.payin' },
  { modality: 2, value: 'transaction.modality.payout' },

  { modality: 3, value: 'transaction.modality.card' },

  { modality: 4, value: 'transaction.modality.cheque' },
  { modality: 5, value: 'transaction.modality.facture' },
  { modality: 6, value: 'transaction.payout.abonnement' },
  { modality: 7, value: 'transaction.payout.type' },

  { modality: 8, value: 'transaction.card.refund' },

  { modality: 9, value: 'transaction.card.payment' },
  { modality: 10, value: 'transaction.card.withdrawal' },
  { modality: 12, value: 'transaction.card.topup' },
  { modality: 13, value: 'transaction.card.command' },
  { modality: 14, value: 'transaction.card.deposit' },
];

export const MODALITY_LABEL_MAP: StringMapInterface = {
  '0': 'transaction.modality.transfer',
  '1': 'transaction.modality.payin',
  '2': 'transaction.modality.payout',
  '3': 'transaction.modality.card',
  '4': 'transactioin.modality.cheque',
  '5': 'transaction.modality.facture',
  '6': 'transaction.payout.abonnement',
  '7': 'transaction.payout.type',
  '8': 'transaction.card.refund',
  '9': 'transaction.card.payment',
  '10': 'transaction.card.withdrawal',
  '12': 'transaction.card.topup',
  '13': 'transaction.card.command',
  '14': 'transaction.card.deposit',
};

export enum TRANSACTION_MODALITY_TYPE {
  IN = 0,
  OUT = 1,
}

export enum TRANSACTION_STATE {
  UNVALIDATED = 'UNVALIDATED',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  TERMINATED = 'VALIDATED',
  REJECTED = 'REJECTED',
  SCHEDULED = 'SCHEDULED',
  DECLINED = 'DECLINED',
  REVERDED = 'REVERDED',
  SETTLED = 'SETTLED',
  REFUND = 'REFUND',
  VALIDATED = 'VALIDATED',
}

export enum PAYMENT_STATE {
  PAID = 0,
  UNPAID = 1,
}

export const CURRENCY = [
  { constant_code: 'EUR', icon: ' €' },
  { constant_code: 'USD', icon: ' $' },
  { constant_code: 'GBP', icon: ' GBP' },
];

export const CATEGORY_TRANSACTION: StringMapInterface = {
  ALIMENTATION: alimentation,
  EQUIPEMENT_MATERIEL: equipement,
  ASSURANCE: assurances,
  AUTRE_DEPENSE: autresDepenses,
  FINANCE: finance,
  FOURNITURE_BUREAU: fournitures,
  HOTEL_LOGEMENT: hotelsLogements,
  IMPOT_TAXE: impotsTaxes,
  IT_ELECTRONIQUE: itElectronique,
  JURIDIQUE_COMPTA: juridiqueComptabilite,
  LOCATION_BUREAU: locationBureau,
  LOGISTIQUE: logistique,
  MARKETING: marketing,
  PRODUCTION: production,
  RESTAURANT_BAR: restaurantsBars,
  RETRAIT: retraits,
  SALAIRE: salaires,
  SERVICE_EN_LIGNE: servicesEnLigne,
  STATION_ESSENCE: stationsEssence,
  TRANSPORT: transports,
  UTILITAIRE: autresPrestataires,
};

export enum CATEGORY_ENUM {
  ALIMENTATION = 'ALIMENTATION',
  EQUIPEMENT_MATERIEL = 'EQUIPEMENT_MATERIEL',
  ASSURANCE = 'ASSURANCE',
  AUTRE_DEPENSE = 'AUTRE_DEPENSE',
  FINANCE = 'FINANCE',
  FOURNITURE_BUREAU = 'FOURNITURE_BUREAU',
  HOTEL_LOGEMENT = 'HOTEL_LOGEMENT',
  IMPOT_TAXE = 'IMPOT_TAXE',
  IT_ELECTRONIQUE = 'IT_ELECTRONIQUE',
  JURIDIQUE_COMPTA = 'JURIDIQUE_COMPTA',
  LOCATION_BUREAU = 'LOCATION_BUREAU',
  LOGISTIQUE = 'LOGISTIQUE',
  MARKETING = 'MARKETING',
  PRODUCTION = 'PRODUCTION',
  RESTAURANT_BAR = 'RESTAURANT_BAR',
  RETRAIT = 'RETRAIT',
  SALAIRE = 'SALAIRE',
  SERVICE_EN_LIGNE = 'SERVICE_EN_LIGNE',
  STATION_ESSENCE = 'STATION_ESSENCE',
  TRANSPORT = 'TRANSPORT',
  UTILITAIRE = 'UTILITAIRE',
}

export const RECURRENCE_MAP: NumberKeyMapInterface = {
  1: 'transaction.recurrence.weekly',
  2: 'transaction.recurrence.monthly',
  3: 'transaction.recurrence.yearly',
};

export const TRANSACTION_DASHBOARD_LIMIT = {
  recentTransactions: 5,
  cardTransactionsMin: 3,
  cardTransactionsMax: 5,
};

export const LAST_CARD_TRANSACTION_VARIABLES = {
  transactionTreezorId: false,
  // FIX ME : all transaction card?
  modality: [TRANSACTION_MODALITY.PAIEMENT, TRANSACTION_MODALITY.WITHDRAWAL],
  transactionState: [TRANSACTION_STATE.SETTLED],
  size: TRANSACTION_DASHBOARD_LIMIT.cardTransactionsMax,
};

export const LAST_TRANSACTION_VARIABLES = {
  transactionTreezorId: false,
  transactionState: [
    TRANSACTION_STATE.TERMINATED,
    TRANSACTION_STATE.SETTLED,
    TRANSACTION_STATE.REFUND,
    TRANSACTION_STATE.REJECTED,
    TRANSACTION_STATE.DECLINED,
    // TRANSACTION_STATE.REVERDED
  ],
  size: TRANSACTION_DASHBOARD_LIMIT.recentTransactions,
};
export const LAST_TRANSACTION_DASHBOARD = {
  transactionTreezorId: false,
  transactionState: [
    TRANSACTION_STATE.TERMINATED,
    TRANSACTION_STATE.SETTLED,
    TRANSACTION_STATE.REFUND,
    TRANSACTION_STATE.REJECTED,
    TRANSACTION_STATE.DECLINED,
    // TRANSACTION_STATE.REVERDED
  ],
  size: DEFAULT_TR_INTO_GRID,
};

// Default validated transaction args for grid
export const DEFAULT_VALIDATED_TR_ARGS: AllCompanyTransactionsVariables = {
  transactionState: [
    TRANSACTION_STATE.TERMINATED,
    TRANSACTION_STATE.SETTLED,
    TRANSACTION_STATE.REFUND,
    TRANSACTION_STATE.REJECTED,
    TRANSACTION_STATE.DECLINED,
    //   TRANSACTION_STATE.REVERDED
  ],
  page: 1,
  size: DEFAULT_TR_INTO_GRID,
  modality: null,
  modalityType: null,
  totalDay: null,
  categoryId: null,
  beneficiaryName: null,
  recurrenceType: null,
  transactionTreezorId: false,
};

// Default validated transaction args for table (must be different to for grid to avoid bug)
export const DEFAULT_VALIDATED_TR_ARGS_TABLE: AllCompanyTransactionsVariables =
  {
    ...DEFAULT_VALIDATED_TR_ARGS,
    size: DEFAULT_ROWS_PER_PAGE,
  };

export const DEFAULT_NOT_VALIDATED_TR_ARGS: AllCompanyTransactionsVariables = {
  transactionState: [
    TRANSACTION_STATE.UNVALIDATED,
    TRANSACTION_STATE.PENDING,
    TRANSACTION_STATE.SCHEDULED,
  ],
  transactionTreezorId: true,
};

export const TR_INVALIDATED_SIZE = 50;

// Default transaction statement args
export const DEFAULT_STATEMENT_ARGS: DownloadAccountStatementVariables = {
  transactionStates: [
    TRANSACTION_STATE.TERMINATED,
    TRANSACTION_STATE.SETTLED,
    TRANSACTION_STATE.REFUND,
  ],
  modality: null,
  modalityType: null,
  totalDay: null,
  recurrenceType: null,
  startDate: new Date(),
  endDate: new Date(),
  selectedDate: null,
  accountId: null,
};

export const toggleTransactionPanelView = (
  client: ApolloClient<any>,
  data: TransactionPanelState,
) => {
  client.writeQuery({
    query: IS_ALL_TRANSACTION_PANEL,
    data: {
      isAllTransactionPanel: { ...data, __typename: 'IsAllTransactionPanel' },
    },
  });
};

export interface TransactionPreCreate {
  date: Date | null;
  total: number | null;
}

export const transactionPreCreate = (
  client: ApolloClient<any>,
  data: TransactionPreCreate,
) => {
  client.writeQuery({
    query: CAN_CREATE_TR,
    data: {
      canCreateTr: { ...data, __typename: 'CanCreateTr' },
    },
  });
};

export const transactionPreData = (client: ApolloClient<any>) => {
  try {
    const tokenData = client.readQuery({ query: CAN_CREATE_TR });
    return tokenData?.canCreateTr || null;
  } catch (err) {
    return null;
  }
};

export const diffDateCreateTr = (date: any) => {
  try {
    if (!date) return false;
    const now: any = new Date();
    const msDifference = Math.abs(date - now);
    let minutes = Math.floor(msDifference / 1000 / 60);
    if (minutes < 14) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
