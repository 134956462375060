import { useApolloClient, useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { GET_COMPANY_ID } from '../../../graphql/company/query';
import { CompanyId } from '../../../graphql/company/types/CompanyId';
import TransactionSub from './TransactionSub';
import { toggleChequeTabView } from '../../Cheque/ChequeLogic';
import StatementSub from './StatementSub/StatementSub';
import ErrorSub from './ErrorSub/ErrorSub';
import { useAppSelector } from '../../../redux/hook';

const GlobalSubListener = () => {
  const client = useApolloClient();
  const [companyId, setCompanyId] = useState('');
  const company = useAppSelector((state) => state.account.myCompany?.company);
  const [getUserCompany] = useLazyQuery<CompanyId>(GET_COMPANY_ID, {
    onCompleted: async (data) => {
      if (data?.myCompany?.id) {
        setCompanyId(data.myCompany.id);
      }
    },
  });

  useEffect(() => {
    toggleChequeTabView(client, { isTab: false });
    getCompanyData();
  }, []);

  const getCompanyData = async () => {
    if (!company?.id) {
      return getUserCompany();
    }
    setCompanyId(company.id);
  };

  return (
    <>
      {!!companyId ? (
        <>
          {' '}
          <TransactionSub companyId={companyId} />
          <StatementSub />
          <ErrorSub />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default GlobalSubListener;
