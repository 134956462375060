import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../redux/hook';
import { ApolloError } from 'apollo-client';
import { fetcherWEB } from '../../../apolloClient';
import { API_ACCOUNT, API_TRANSACTION } from '../../../config';
import {
  MyCompany,
  MyCompany_myCompany,
} from '../../../graphql/graphql_account/company/types/MyCompany';
import {
  DashboardItem,
  MyCompanyInfoItem,
  TeamItem,
  _myCompanyInfo,
  _dashboards,
  _teams,
  _offers,
  OfferItem,
} from '../../../indexDB/data';
import { MY_COMPANY_INFO } from '../../../graphql/graphql_account/company/query';
import {
  setCompanyReadOnly,
  setMyCompany,
} from '../../../redux/account/accountSlice';
import { setMyOffer } from '../../../redux/offer/offerSlice';
import { setCanCreateCard } from '../../../redux/card/cardSlice';
import { CheckUserInitTransfer } from '../../../graphql/graphql_transaction/types/CheckUserInitTransfer';
import { CHECK_USER_INIT_TRANSFER } from '../../../graphql/graphql_transaction/query';
import { setCanCreateTransaction } from '../../../redux/transaction/transactionSlice';
import useGetAvatar from '../account/avatar';

const useGetMyCompanyInfo = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { getAvatar } = useGetAvatar(true);

  const fetchMyCompany = async () => {
    return await fetcherWEB<null, MyCompany>(MY_COMPANY_INFO, null, API_ACCOUNT)
      .then(async (data) => {
        if (data?.myCompany) {
          await _myCompanyInfo.setItem(
            MyCompanyInfoItem.MY_COMPANY,
            data.myCompany,
          );
          await formalizeData(data.myCompany);
          await setData(data.myCompany);
          setLoading(false);
        }
      })
      .catch((error: ApolloError) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    fetcherWEB<null, CheckUserInitTransfer>(
      CHECK_USER_INIT_TRANSFER,
      null,
      API_TRANSACTION,
    ).then((data) => {
      dispatch(setCanCreateTransaction(!!data.checkUserInitTransfer));
    });
  }, []);

  const getData = async () => {
    setLoading(true);
    const _cacheMyCompany = await _myCompanyInfo.getItem(
      MyCompanyInfoItem.MY_COMPANY,
    );
    if (_cacheMyCompany) {
      await setData(_cacheMyCompany as MyCompany_myCompany);
      setLoading(false);
    }
    await fetchMyCompany();
  };

  const setData = async (data: MyCompany_myCompany) => {
    dispatch(setMyCompany(data));
    const { offer, company, myAccount } = data;
    if (offer) {
      dispatch(setMyOffer(offer));
    }
    if (company?.id) {
      const readOnly = company?.readOnly || false;
      dispatch(
        setCanCreateCard((company?.canCreateCard && !readOnly) || false),
      );
      dispatch(setCompanyReadOnly(readOnly));
    }
    const { avatarUuid: uuid, avatarFilename: filename } =
      myAccount?.account || {};
    if (uuid && filename) {
      await getAvatar({ uuid, filename });
    }
  };

  const formalizeData = async (data: MyCompany_myCompany) => {
    const { offer, myAccount, company, leader, members } = data;
    if (offer) {
      await _offers.setItem(OfferItem.MY_OFFER, offer);
      const { id, name } = offer;
      await _dashboards.setItem(DashboardItem.MY_OFFER, { id, name });
    }
    if (company?.id) {
      await _dashboards.setItem(DashboardItem.MY_COMPANY_ID, company.id);
    }

    if (myAccount?.account?.email) {
      await _dashboards.setItem(
        DashboardItem.MY_EMAIL,
        myAccount.account.email,
      );
    }
    if (members?.length) {
      await _teams.setItem(TeamItem.MEMBERS, members);
    }
    if (leader) {
      await _teams.setItem(TeamItem.LEADER, leader);
    }
  };

  return { loading };
};
export default useGetMyCompanyInfo;
