import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { MyCompany_myCompany_offer } from '../../graphql/graphql_account/company/types/MyCompany';

export const offerSlice = createSlice({
  name: 'offer',
  initialState: {
    myOffer: null as MyCompany_myCompany_offer | null,
    listOffer: [] as (MyCompany_myCompany_offer | null)[] | null,
  },
  reducers: {
    setMyOffer: (state, action: PayloadAction<MyCompany_myCompany_offer>) => {
      state.myOffer = action.payload;
    },
    setListOffer: (
      state,
      action: PayloadAction<Array<MyCompany_myCompany_offer | null>>,
    ) => {
      state.listOffer = action.payload;
    },
  },
});

export const { setMyOffer, setListOffer } = offerSlice.actions;

export default offerSlice.reducer;
