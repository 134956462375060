export default {
  'mainTeam.error.getRoles': `Erreur lors de la récupération de la liste des roles.`,
  'mainTeam.error.getMembers': `Erreur lors de la récupération de la liste des membres.`,
  'mainTeam.error.updateMember': `Erreur lors de la mise à jour du membre.`,
  'mainTeam.error.sendInvitationMail': `Erreur lors de l'envoi de l'email au nouveau membre.`,
  'mainTeam.error.deleteUser': `Erreur lors de la suppression de l'utilisateur.`,
  'mainTeam.error.addMember': `Erreur lors de la création du nouveau membre. Il se peut que l'email existe déjà.`,
  'mainTeam.error.memberLimitReached':
    "Veuillez changer d'offre pour bénéficier de plus de membre",
  'mainTeam.success.addMember': `Nouveau membre ajouté avec succès.`,
  'mainTeam.success.updateMember': `Membre mis à jour avec succès.`,
  'mainTeam.success.sendInvitationMail': `Un email a été envoyé au nouveau membre.`,
  'mainTeam.success.deleteUser': `Utilisateur supprimé avec succès.`,
  'mainTeam.dialogTitle.createMember': `Création de membre`,
  'mainTeam.dialogTitle.editMember': `Modification de membre`,
  'mainTeam.label.addMember': 'Ajouter un membre',
  'mainTeam.label.searchMember': 'Rechercher un membre',
  'mainTeam.label.authorizations': 'Autorisations',
  'mainTeam.label.authorizationsInfo': `Sélectionner un membre pour voir les droits qui lui ont été attribué.`,
  'mainTeam.error.notAllowAddMember': `ne vous permet plus d'ajouter un membre.`,
  'mainTeam.label.reinvite': 'Inviter',
  'team.filter.team': 'Equipe',
  'team.filter.role': 'Rôle',
  'team.filter.state': 'Statut',
  'team.filter.title': 'Filtre Membre',
  'team.filter.subtitle': 'Filtrez vos membres',
  'team.filter.paragraph':
    'Visualiser vos équipes selon vos critères et préférences',
};
