export default {
  ALIMENTATION: 'Food',
  EQUIPEMENT_MATERIEL: 'Equipment and material',
  ASSURANCE: 'Insurance',
  AUTRE_DEPENSE: 'Other Expenses',
  FINANCE: 'Finance',
  FOURNITURE_BUREAU: 'Office Supplies',
  HOTEL_LOGEMENT: 'Hotels and accommodation',
  IMPOT_TAXE: 'Taxes and duties',
  IT_ELECTRONIQUE: 'IT and electronics',
  JURIDIQUE_COMPTA: 'Legal and accounting',
  LOCATION_BUREAU: 'Office rental',
  LOGISTIQUE: 'Logistics',
  MARKETING: 'Marketing',
  PRODUCTION: 'Production',
  RESTAURANT_BAR: 'Restaurants and bars',
  RETRAIT: 'Withdrawals',
  SALAIRE: 'Salaries',
  SERVICE_EN_LIGNE: 'Online Services',
  STATION_ESSENCE: 'Petrol stations',
  TRANSPORT: 'Transport',
  UTILITAIRE: 'Utilities',
};
