import gql from 'graphql-tag';

export const WALLET_FRAGMENT = gql`
  fragment WalletInfo on Wallet {
    id
    treezorWalletId
    iban
    bic
  }
`;
