import { PaletteType } from '@material-ui/core';
import { VIEW } from '../utils/constant';

export const THEME_TYPE = 'theme';
export const ACCESS_TOKEN_KEY = btoa('access_token');
export const CONNEXION_ID = btoa('connexion_id');
export const TX_ORDER_ID = btoa('tx_order_id');
export const COMMAND_CARD = btoa('command');
export const LOADING_STATEMENT = btoa('loading_statement');

export const isAuthenticated = (): boolean =>
  !!localStorage.getItem(ACCESS_TOKEN_KEY);

// Setters
export const setThemeTypeStorage = (type: PaletteType) => {
  localStorage.setItem(THEME_TYPE, type);
};
export const setAccessToken = (token: string) =>
  localStorage.setItem(ACCESS_TOKEN_KEY, token);

export const setConnexionID = (connId: string) => {
  return localStorage.setItem(CONNEXION_ID, btoa(connId));
};
export const setCommnandCard = (variables: string) => {
  return localStorage.setItem(COMMAND_CARD, btoa(variables));
};
export const setTxOrderId = (tx_id: string) => {
  localStorage.setItem(TX_ORDER_ID, tx_id);
};
export const setLoadingStatement = (value: string) =>
  localStorage.setItem(LOADING_STATEMENT, value);

// Getters
export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};
export const getConnexionID = () => {
  const local = localStorage.getItem(CONNEXION_ID);
  return local ? atob(local) : local;
};
export const getCommandCard = () => {
  const local = localStorage.getItem(COMMAND_CARD);
  return local ? atob(local) : local;
};
export const getThemeTypeStorage = (): PaletteType => {
  const result = localStorage.getItem(THEME_TYPE);
  if (result) {
    return result === VIEW.LIGHT ? VIEW.LIGHT : VIEW.DARK;
  }
  return VIEW.LIGHT;
};
export const getTxOrderId = () => {
  return localStorage.getItem(TX_ORDER_ID)
    ? localStorage.getItem(TX_ORDER_ID)
    : '';
};

export const getLoadingStatement = () => {
  const result = localStorage.getItem(LOADING_STATEMENT);
  if (result) {
    return result == 'true' ? true : false;
  }
  return false;
};
// Clear
export const clearTxOrderId = () => {
  if (getTxOrderId()) {
    localStorage.removeItem(TX_ORDER_ID);
  }
};
export const clearCommandCard = () => {
  if (getCommandCard()) {
    localStorage.removeItem(COMMAND_CARD);
  }
};
export const clearLocalStorage = () => {
  const theme = getThemeTypeStorage();
  localStorage.clear();
  setThemeTypeStorage(theme);
};
