import { gql } from 'graphql-tag';
import { OFFER_FRAGMENT } from './fragments/fragment_offer';

export const MY_OFFER = gql`
  query MyOffer {
    myOffer {
      ...OfferInfo
    }
  }
  ${OFFER_FRAGMENT}
`;

export const OFFERS = gql`
  query Offers {
    offers {
      ...OfferInfo
    }
  }
  ${OFFER_FRAGMENT}
`;
