import { useApolloClient } from '@apollo/client';
import { defaultErrorHandler } from '../../../utils/snackBarUtils';
import { useIntl } from 'react-intl';
import { fetcherWEB } from '../../../apolloClient';
import { API_ACCOUNT } from '../../../config';
import { useState } from 'react';
import {
  GetSignedUrl,
  GetSignedUrlVariables,
} from '../../../graphql/graphql_account/file/types/GetSignedUrl';
import { GET_SIGNED_URL } from '../../../graphql/graphql_account/file/url';

const useGetUrl = () => {
  const client = useApolloClient();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const getUrl = async (variables: GetSignedUrlVariables) => {
    setLoading(true);
    return await fetcherWEB<GetSignedUrlVariables, GetSignedUrl>(
      GET_SIGNED_URL,
      variables,
      API_ACCOUNT,
    )
      .then(async (data) => {
        setLoading(false);
        if (data?.getSignedUrl) {
          return data.getSignedUrl;
        }
        return null;
      })
      .catch((error) => {
        setLoading(false);
        defaultErrorHandler(client, error, intl);
        return null;
      });
  };

  return { loading, getUrl };
};
export default useGetUrl;
