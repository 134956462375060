export default {
  'teamMemberDialog.label.role': `Rôle`,
  'teamMemberDialog.label.cardInfo': `Un membre peut avoir des cartes et voir ses propres transactions.`,
  'teamMemberDialog.label.informations': `Informations`,
  'teamMemberDialog.label.authorizations': `Autorisations`,
  'teamMemberDialog.label.createAndInvite': `Créer et inviter`,
  'teamMemberDialog.label.deleteUser': `Supprimer l'utilisateur`,
  'teamMemberDialog.label.adminDescription': `
  Un administrateur occupe un rôle hautement stratégique à la tête d'une entreprise. Nommé par les actionnaires, il fait partie du conseil d'administration et vote les décisions les plus importantes pour la vie de sa société. Il est le visage de cette dernière auprès de ses employés, de ses prestataires, de ses partenaires et parfois du grand public.`,
  'teamMemberDialog.label.collaboratorDescription': `Un collaborateur est l'aide précieuse pour un manager pour atteindre ses objectifs.`,
  'teamMemberDialog.label.accountantDescription': `Un comptable est responsable de la tenue des comptes. Sans oublier qu'il établi et cloture les liens fiscales.`,
  'teamMemberDialog.error.noRoleSelected': `Veuillez sélectionner un rôle.`,
  'teamMemberDialog.error.noTeamSelected': `Veuillez sélectionner une équipe.`,
  'teamMemberDialog.error.invalidAddressEmail': `Veuillez entrer une adresse email valide.`,
  'teamMemberDialog.error.nameNotSet': `Veuillez renseigner le nom du membre.`,
  'teamMemberDialog.error.firstNameNotSet': `Veuillez renseigner le prénom du membre.`,
};
