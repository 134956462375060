import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Theme, withStyles } from "@material-ui/core/styles";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import React, { ChangeEvent } from "react";

import { VIEW } from "../../../../../utils/constant";
const Moon = "/assets/svg/moon.svg";
const Sun = "/assets/svg/sun.svg";
interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

interface ISwitchMode {
  checked: boolean | false;
  handleChange(): void;
}

const CustomSwitch = withStyles((theme: Theme) => ({
  root: {
    width: 60,
    height: 32,
    padding: 1,
  },
  switchBase: {
    padding: 0,
    border: "none",
    "&$checked": {
      transform: "translateX(28px)",
      "& + $track": {
        border: `1px solid ${theme.palette.text.primary}`,
        background: `${theme.palette.text.primary} url(${Sun}) left 8px center / 10px no-repeat`,
        opacity: 1,
      },
      "& $thumb": {
        background: `${theme.palette.background.paper} url(${Moon}) center center / 10px no-repeat`,
      },
    },
  },
  thumb: {
    width: 30,
    height: 30,
    margin: 1,
    background: `${theme.palette.background.paper} url(${Sun}) center center / 10px no-repeat`,
    border: `1px solid ${theme.palette.text.primary}`,
    boxShadow: "none",
  },
  track: {
    borderRadius: 25,
    border: `1px solid ${theme.palette.text.primary}`,
    background: `${theme.palette.text.primary} url(${Moon}) right 8px center / 10px no-repeat`,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }: Props) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
));

export default function SwitchMode(props: ISwitchMode) {
  const { checked, handleChange } = props;
  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange();
  };
  return (
    <FormControlLabel
      control={
        <CustomSwitch
          checked={checked}
          onChange={handleSwitchChange}
          value={!checked ? VIEW.LIGHT : VIEW.DARK}
        />
      }
      label=""
    />
  );
}
