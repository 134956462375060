import { Box, BoxProps, CircularProgress } from '@material-ui/core';
import React from 'react';

const CustomProgress = (props: BoxProps) => {
  return (
    <Box position="absolute" left="50%" top="50%" {...props}>
      <CircularProgress size={30} />
    </Box>
  );
};

export default CustomProgress;
