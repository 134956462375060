import gql from 'graphql-tag';
import { ACCOUNT_MEMBER_STAT_FRAGMENT } from '../account/fragment';
import { ROLES_FRAGMENT } from '../role/fragment';
import { TEAM_FRAGMENT } from '../team/fragment';

export const GET_COMPANY_ROLES_TEAMS = gql`
  query CompanyRolesTeams {
    myCompany {
      id
      roles {
        ...roleInfo
      }
      teams {
        ...teamInfo
      }
    }
  }
  ${ROLES_FRAGMENT}
  ${TEAM_FRAGMENT}
`;

export const GET_COMPANY_ID = gql`
  query CompanyId {
    myCompany {
      id
      canCreateCard
    }
  }
`;

export const GET_ACCOUNTS_FOR_STAT = gql`
  query AccountForStat {
    accountForStat {
      ...AccountMemberStatInfo
    }
  }
  ${ACCOUNT_MEMBER_STAT_FRAGMENT}
`;
