import React, { FC, useEffect } from 'react';
import { Routes as RoutesDom, Route, Navigate } from 'react-router-dom';
import { isAuthenticated, setAccessToken } from './services/LocalStorage';
import { CrossStorageClient } from 'cross-storage';
import { ACCOUNT_URL, API_ACCOUNT, API_TRANSACTION } from './config';
import CustomProgress from './components/Common/CustomProgress/CustomProgress';
import { useLazyQuery } from '@apollo/client';
import { CheckConnection } from './graphql/account/types/CheckConnection';
import { CHECK_CONNECTION } from './graphql/account/query';
import NoConnection from './components/CheckConnection/NoConnection';
import Layout from './components/Common/Layout/Layout';
import { useAppDispatch } from './redux/hook';
import { setToken } from './redux/auth/loginSlice';
import { DashboardItem, _dashboards } from './indexDB/data';
import { useCheckConnection } from './components/customHooks/checkConnection/checkConnection';

interface CountConn {
  [key: string]: number;
}

const SignIn = React.lazy(() => import('./components/Auth/SignIn/SignIn'));
const ForgotPassword = React.lazy(
  () => import('./components/Auth/ForgotPassword/ForgotPassword'),
);
const PasswordReset = React.lazy(
  () => import('./components/Auth/PasswordReset/PasswordReset'),
);
const PasswordMemberTeam = React.lazy(
  () => import('./components/PasswordMember/PasswordMemberProvider'),
);
const Expirate = React.lazy(() => import('./components/Expirate/Expirate'));
const PageNotFound = React.lazy(() => import('./components/404'));
const ShowTrAttachement = React.lazy(
  () => import('./components/Transaction/ShowTrAttachement/ShowTrAttachement'),
);
const HipayAccept = React.lazy(() => import('./components/Hipay/Accept'));
const HipayPending = React.lazy(() => import('./components/Hipay/Pending'));
const HipayCancel = React.lazy(() => import('./components/Hipay/Cancel'));
const HipayDecline = React.lazy(() => import('./components/Hipay/Decline'));
const HipayException = React.lazy(() => import('./components/Hipay/Exception'));
const Dashboard = React.lazy(
  () => import('./components/Dashboard/DashboardProvider'),
);
const Transaction = React.lazy(
  () => import('./components/Transaction/TransactionProvider'),
);
const Card = React.lazy(() => import('./components/Card/CardProvider'));
const Cheque = React.lazy(() => import('./components/Cheque/ChequeProvider'));
const Team = React.lazy(() => import('./components/Team/TeamProvider'));
const Service = React.lazy(() => import('./components/Service/Service'));
const Setting = React.lazy(
  () => import('./components/NewSetting/NewSettingProvider'),
);
const NotValidatedCompany = React.lazy(
  () => import('./components/NotValidatedCompany'),
);
const LoginFromAccount = () => {
  const dispatch = useAppDispatch();
  const clientStorage = new CrossStorageClient(`${ACCOUNT_URL}`, {});
  clientStorage
    .onConnect()
    .then(() => clientStorage.get('token'))
    .then(async (res) => {
      if (res) {
        setAccessToken(res);
        dispatch(setToken({ token: res, email: '' }));
        await _dashboards.setItem(DashboardItem.MY_ID, res);
        window.location.href = '/dashboard';
      } else {
        window.location.href = '/login';
      }
    })
    .catch((e) => {});
  return <CustomProgress />;
};

//Init countConn
let countConn: CountConn = {
  gateway: 0,
  account: 0,
  transaction: 0,
};

const Routes: FC = () => {
  const CONNEXION_MAX = 5;

  const [getCon] = useLazyQuery<CheckConnection>(CHECK_CONNECTION);
  const { checkConnection: checkAccount } = useCheckConnection(API_ACCOUNT);
  const { checkConnection: checkTransaction } =
    useCheckConnection(API_TRANSACTION);

  async function handleConnectionStatus() {
    const tasks = [
      { func: getCon, key: 'gateway' },
      { func: checkAccount, key: 'account' },
      { func: checkTransaction, key: 'transaction' },
    ];

    const results = await Promise.allSettled(tasks.map((task) => task.func()));

    results.forEach((result, index) => {
      const { status } = result;
      const task = tasks[index];

      if (status === 'fulfilled') {
        countConn = { ...countConn, [task.key]: 0 };
      } else {
        countConn = { ...countConn, [task.key]: countConn[task.key] + 1 };
      }
    });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      handleConnectionStatus();
    }, 5000); // 5 sec
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (
    countConn.gateway > CONNEXION_MAX ||
    countConn.account > CONNEXION_MAX ||
    countConn.transaction > CONNEXION_MAX
  ) {
    return <NoConnection />;
  }
  return (
    <RoutesDom>
      <Route path="/loginFromAccount" element={<LoginFromAccount />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/forgot" element={<ForgotPassword />} />
      <Route path="/reset/:token" element={<PasswordReset />} />
      <Route path="/invite/:token" element={<PasswordMemberTeam />} />
      <Route path="/expirate" element={<Expirate />} />
      <Route path="/404" element={<PageNotFound />} />
      <Route path="/hipay/accept" element={<HipayAccept />} />
      <Route path="/hipay/cancel" element={<HipayCancel />} />
      <Route path="/hipay/pending" element={<HipayPending />} />
      <Route path="/hipay/decline" element={<HipayDecline />} />
      <Route path="/hipay/exception" element={<HipayException />} />
      <Route path="/payouts/:uuid/:filename" element={<ShowTrAttachement />} />
      <Route element={<Layout />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="transaction" element={<Transaction />} />
        <Route path="card" element={<Card />} />
        <Route path="cheque" element={<Cheque />} />
        <Route path="team" element={<Team />} />
        <Route path="service" element={<Service />} />
        <Route path="setting" element={<Setting />} />
        <Route
          path="/not-validated-company"
          element={<NotValidatedCompany />}
        />
        <Route
          path="/"
          element={
            isAuthenticated() ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Route>{' '}
    </RoutesDom>
  );
};

export default Routes;
