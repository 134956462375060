import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AccountNotifications_getAccountNotifications } from '../../../graphql/notification/types/AccountNotifications';
import { NOTIFICATION_TYPE } from '../../../utils/constant';
import CustomNotificationIcon from '../../Common/CustomNotificationIcon';
import NotificationEntries from '../NotificationEntries';
import { getNotificationIntlId } from '../NotificationLogic';

interface NotificationPropsInterface {
  accountNotifications: Array<AccountNotifications_getAccountNotifications | null>;
  handleDisplayDetails: (id: string) => void;
}

const NotificationList = (props: NotificationPropsInterface) => {
  const { accountNotifications, handleDisplayDetails } = props;
  const intl = useIntl();
  const [selectedNotif, setSelectedNotif] = useState('');
  const handleNotificationSelect = (selectedId: string) => {
    setSelectedNotif(selectedId);
    handleDisplayDetails(selectedId);
  };

  return (
    <>
      {!!accountNotifications.length ? (
        <>
          {accountNotifications.map(
            (
              notif: AccountNotifications_getAccountNotifications | null,
              index: number,
            ) => {
              if (
                notif &&
                notif.notification &&
                notif.date &&
                notif.notification.id
              ) {
                const viewed = notif.viewed !== null ? notif.viewed : false;
                const { date, notification } = notif;
                const notifConstantCode =
                  (notification.notificationType &&
                    notification.notificationType.constantCode) ||
                  NOTIFICATION_TYPE.MESSAGE;
                const parsedDate = isNaN(+date) ? new Date() : new Date(+date);
                return (
                  <NotificationEntries
                    key={`notif-${notif.id}-${index}`}
                    icon={
                      <CustomNotificationIcon
                        constantCode={notifConstantCode}
                      />
                    }
                    title={intl.formatMessage({
                      id: getNotificationIntlId(notifConstantCode),
                    })}
                    content={notification.title || ''}
                    date={parsedDate}
                    viewed={viewed}
                    isSelected={notif.notification.id === selectedNotif}
                    notificationId={notif.notification.id}
                    handleSelect={handleNotificationSelect}
                  />
                );
              }
            },
          )}
        </>
      ) : (
        <Typography
          color="textSecondary"
          variant="body2"
          style={{ marginLeft: -8 }}
        >
          <FormattedMessage id="notification.text.noNotification" />
        </Typography>
      )}
    </>
  );
};

export default NotificationList;
