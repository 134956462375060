import gql from 'graphql-tag';
import { WALLET_BALANCE_FRAGMENT } from '../wallet/fragment';

export const ON_BALANCE_UPDATED = gql`
  subscription OnBalanceUpdated($companyId: ID!) {
    onBalanceUpdated(companyId: $companyId) {
      ...WalletBalanceInfo
    }
  }
  ${WALLET_BALANCE_FRAGMENT}
`;
