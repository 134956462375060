import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../redux/hook';
import { MY_OFFER } from '../../../graphql/graphql_account/offer/query';
import { API_ACCOUNT } from '../../../config';
import { fetcherWEB } from '../../../apolloClient';
import {
  MyOffer,
  MyOffer_myOffer,
} from '../../../graphql/graphql_account/offer/types/MyOffer';
import { setMyOffer } from '../../../redux/offer/offerSlice';
import { defaultErrorHandler } from '../../../utils/snackBarUtils';
import { useIntl } from 'react-intl';
import { useApolloClient } from '@apollo/client';
import { OfferItem, _offers } from '../../../indexDB/data';

const useGetMyOffer = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const client = useApolloClient();

  const fetchMyOfferApi = async () => {
    return await fetcherWEB<null, MyOffer>(MY_OFFER, null, API_ACCOUNT)
      .then(async (data) => {
        setLoading(false);
        if (data?.myOffer) {
          dispatch(setMyOffer(data.myOffer));
          await _offers.setItem(OfferItem.MY_OFFER, data.myOffer);
          return data.myOffer;
        }
        return null;
      })
      .catch(async (error) => {
        setLoading(false);
        defaultErrorHandler(client, error, intl, 'setting.getOfferError');
      });
  };

  useEffect(() => {
    myOffer();
  }, []);

  const myOffer = async () => {
    setLoading(true);
    const cache = await _offers.getItem(OfferItem.MY_OFFER);
    if (cache) {
      setLoading(false);
      dispatch(setMyOffer(cache as MyOffer_myOffer));
      return cache as MyOffer_myOffer | null;
    }
    return fetchMyOfferApi() as Promise<MyOffer_myOffer | null>;
  };

  return { loading, myOffer };
};
export default useGetMyOffer;
