export default {
  'hipay.accept': 'Votre transaction a bien été enregistrée',
  'hipay.pending': 'Votre transaction est en attente de validation',
  'hipay.cancel': 'Votre transaction a été annulée',
  'hipay.decline': 'Votre transaction a été refusée',
  'hipay.exception': 'Erreur exception dans votre transaction',

  'hipay.transaction.error': 'Erreur lors de la mise à jour de la transaction',




  // Error from hipay
  ERROR_CARD_HOLDER_ALPHANUMERICAL:
    'Le titulaire de la carte doit être alphanumérique.',
  ERROR_CARD_HOLDER_MAX_LENGTH:
    'La longueur maximale du titulaire de la carte est dépassée.',
  ERROR_CARD_HOLDER_MAX_DIGITS:
    'Le titulaire de la carte ne doit pas comporter plus de 8 chiffres',
  ERROR_CARD_HOLDER_INVALID: "Le titulaire de la carte n'est pas valide",
  ERROR_CARD_NUMBER_INVALID: "Le numéro de la carte n'est pas valable",
  ERROR_CARD_BRAND_NOT_ALLOWED: "Le type de carte n'est pas autorisé",
  ERROR_EXPIRY_DATE_INVALID_FORMAT:
    "Le format de la date d'expiration n'est pas valable",
  ERROR_EXPIRY_DATE_PAST_DATE:
    "La date d'expiration ne peut pas être dans le passé.",
  ERROR_EXPIRY_DATE_INVALID: "La date d'expiration n'est pas valide",
  ERROR_EXPIRY_MONTH_INVALID_FORMAT:
    "Le format du mois d'expiration n'est pas valide",
  ERROR_EXPIRY_MONTH_LENGTH_MAX:
    "La longueur maximale du mois d'expiration est dépassée",
  ERROR_EXPIRY_MONTH_INVALID: "Le mois d'expiration n'est pas valide",
  ERROR_EXPIRY_YEAR_INVALID_FORMAT:
    "Le format de l'année d'expiration n'est pas valide",
  ERROR_EXPIRY_YEAR_LENGTH_MAX:
    "La longueur maximale de l'année d'expiration est dépassée",
  ERROR_EXPIRY_YEAR_PAST_DATE:
    "L'année d'expiration ne peut pas être dans le passé.",
  ERROR_EXPIRY_YEAR_INVALID: "L'année d'expiration n'est pas valable",
  ERROR_CARD_CVC_FORMAT: "Le format CVC n'est pas valide",
  ERROR_CARD_CVC_MAX_LENGTH:
    'La longueur maximale de la carte CVC est dépassée',
  ERROR_CARD_CVC_INVALID: "Le format CVC n'est pas valide",
  ERROR_CARD_HOLDER_MISSING: 'Le titulaire de la carte est manquant',
  ERROR_CARD_NUMBER_MISSING: 'Il manque le numéro de carte',
  ERROR_EXPIRY_DATE_MISSING: "Il manque la date d'expiration.",
  ERROR_CARD_CVC_MISSING: 'CVC manque.',
  ERROR_CARD_TOKENIZATION: 'Une erreur est survenue lors de la tokenisation.',
  ERROR_BIC_FORMAT: "Le format BIC n'est pas valide",
  ERROR_BIC_MAX_LENGTH: 'La longueur maximale du BIC est dépassée',
  ERROR_BIC_INVALID: "Le format BIC n'est pas valide",
  ERROR_IBAN_FORMAT: "Le format IBAN n'est pas valide",
  ERROR_IBAN_NOT_ALPHANUMERICAL: "L'IBAN doit être alphanumérique",
  ERROR_IBAN_INVALID: "L'IBAN n'est pas valide",
  ERROR_IBAN_INVALID_CODE_PAYS: "Le code pays IBAN n'est pas valide",
  ERROR_IBAN_MAX_LENGTH: "La longueur maximale de l'IBAN est dépassée",
  ERROR_TEXT_FIELD_INVALID: 'Champ de texte non valide',
  ERROR_TEXT_FIELD_MAX_LENGTH:
    'La longueur maximale du champ de texte est dépassée',
  ERROR_TEXT_FIELD_MAX_DIGITS:
    'Le champ de texte ne doit pas contenir plus de 8 chiffres',
  ERROR_CPF_INVALID: "CPF n'est pas valide",
  ERROR_CPF_NUMERICAL: 'Le CPF doit être numérique',
  ERROR_CPF_MAX_LENGTH: 'La longueur maximale du CPF est dépassée',
  ERROR_CURP_INVALID: "CURP n'est pas valide",
  ERROR_CURP_ALPHANUMERICAL: 'CURP doit être alphanumérique',
  ERROR_CURP_MAX_LENGTH: 'La longueur maximale de CURP est dépassée',
  ERROR_BANK_NAME_MISSING: 'Le nom de la banque est manquant',
  ERROR_FIRSTNAME_MISSING: 'Le prénom est manquant',
  ERROR_LASTNAME_MISSING: 'Nom de famille manquant',
  ERROR_COMPANY_MISSING: 'Nom de la société manquant',
  ERROR_IBAN_MISSING: 'IBAN manquant.',
  ERROR_ISSUER_BANK_ID_MISSING: 'BIC manquant.',
  ERROR_NATIONAL_IDENTIFICATION_NUMBER_MISSING:
    "Le numéro d'identification national est manquant",
  ERROR_OCCURED: "Une erreur s'est produite.",

  'payment.phone.empty': 'Veuillez renseigner un numéro de téléphone',
  'hipay.topup.limit': `5 TopUp autorisés par jour pour un montant total maximum de 245€.`
};
