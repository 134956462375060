import { ClickAwayListener } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import { ReferenceObject } from 'popper.js';
import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import Logout from '../../../../Assets/Icons/Logout/Logout';
import Settings from '../../../../Assets/Icons/Settings/Settings';
import { useLogout } from '../../../../Auth/Logout/Logout';

const menuStyles = makeStyles((theme) => ({
  actionStyles: {
    maxWidth: 200,
    marginTop: theme.spacing(2),
    borderRadius: 5,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 7px rgba(0,0,0,.25)',
    zIndex: 10,
  },
  icon: {
    minWidth: 30,
    '& svg': {
      fontSize: '1.125rem',
    },
  },
}));

interface IAccountAction {
  open: boolean;
  anchorEl: null | ReferenceObject;
  handleClickAway(): void;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: IAccountAction) {
  const classes = menuStyles();
  const { logout } = useLogout();
  const CustomRouterLink = forwardRef((props: any, ref) => (
    <NavLink {...props} />
  ));
  const { open, anchorEl, handleClickAway } = props;

  const handleLogout = async () => {
    await logout();
  };
  return (
    <Popper
      className={classes.actionStyles}
      open={open}
      transition
      anchorEl={anchorEl}
      placement="bottom-end"
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <List component="nav" aria-label="main mailbox folders" disablePadding>
          <ListItem button component={CustomRouterLink} to={'/setting'}>
            <ListItemIcon className={classes.icon}>
              <Settings viewBox="0 0 17.816 17.816" color="inherit" />
            </ListItemIcon>
            <ListItemText primary="Paramètres" />
          </ListItem>
          <Divider />
          <ListItem button onClick={handleLogout}>
            <ListItemIcon className={classes.icon}>
              <Logout viewBox="0 0 17.993 17.992" color="inherit" />
            </ListItemIcon>
            <ListItemText primary="Déconnexion" />
          </ListItem>
        </List>
      </ClickAwayListener>
    </Popper>
  );
}
