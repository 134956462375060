import React from "react";
import { SvgIcon } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

const Service = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g
        id="Groupe_12473"
        data-name="Groupe 12473"
        transform="translate(1010.921 -82.518)"
      >
        <path
          id="Tracé_969"
          data-name="Tracé 969"
          d="M-1002.369,93.157l7.775,4.445-7.775,4.528Z"
          transform="translate(3.352 3.388)"
        />
        <path
          id="Tracé_970"
          data-name="Tracé 970"
          d="M-1009.524,97.615c2.722-1.554,5.135-2.93,7.75-4.422v8.927Z"
          transform="translate(-0.191 3.398)"
        />
        <path
          id="Tracé_971"
          data-name="Tracé 971"
          d="M-1009.921,97.149V88.354l7.651,4.322Z"
          transform="translate(-1 1.199)"
        />
        <path
          id="Tracé_972"
          data-name="Tracé 972"
          d="M-992.619,89.5V98.3l-7.65-4.322Z"
          transform="translate(2.349 0.048)"
          fill="none"
          stroke="#fafafc"
          strokeWidth="1"
        />
        <path
          id="Tracé_973"
          data-name="Tracé 973"
          d="M-1002.131,92.125V83.518l7.471,4.234Z"
          transform="translate(3.114 -1)"
        />
        <path
          id="Tracé_974"
          data-name="Tracé 974"
          d="M-1009.524,87.749l7.426-4.231v8.576Z"
          transform="translate(-0.191 -1)"
        />
      </g>
    </SvgIcon>
  );
};

export default Service;
