export default {
  'toolbar.button.addTransaction': 'Create a transaction',
  'toolbar.input.searchTransaction': 'Find a transaction',
  'toolbar.input.searchBeneficiary': 'Search a beneficiary',
  'toolbar.input.calendarTransaction': 'Period',
  'transaction.filter.category': 'Category',
  'transaction.filter.modality': 'Modality',
  'transaction.filter.type': 'Type',
  'transaction.filter.reccurence': 'Recurrence',
  'transaction.filter.beneficiary': 'Beneficiary',
  'transaction.toolbar.today': 'Today',
  'transaction.toolbar.lastThreeDay': '3 days',
  'transaction.toolbar.sevenDay': '7 days',
  'transaction.toolbar.lastMonth': '1 month',
  'transaction.toolbar.twoLastMonth': '2 months',
  'transaction.toolbar.lastThreeMonth': '3 months',
  'transaction.virement.cancel.success': 'Transaction successfully deleted',
  'transaction.filter': 'Filter',
  'transaction.filter.title': 'Filter your transactions',
  'transaction.filter.paragraph':
    'View your history according to your criteria and preferences.',
  'transaction.tootlbar.title': 'Transaction filter',
  'transaction.modal.button.initialize': 'Reset',
  'transaction.modal.button.apply': 'Apply',
  'transaction.validate.sussess': 'Transaction completed successfully',
};
