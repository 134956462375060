import { Box, Divider, Typography } from "@material-ui/core";
import Notifications from "@material-ui/icons/Notifications";
import React, { useState, MouseEvent, useEffect } from "react";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import { FormattedMessage } from "react-intl";
import { AccountNotifications_getAccountNotifications_notification } from "../../../graphql/notification/types/AccountNotifications";
import { htmlDecode, splitFileTypeExt } from "../../../utils/common";
import styles from "../style";
import Image from "material-ui-image";
import { CustomButton } from "../../Common/CustomButton";
import parse from 'html-react-parser';
interface NotificationDetailsPropsInterface {
  filetype?: string;
  fileSignedUrl?: string;
  urlsProvider?: Array<string>;
  loading?: boolean;
  notification: AccountNotifications_getAccountNotifications_notification | null;
}
const NotificationDetails = (props: NotificationDetailsPropsInterface) => {
  const { notification, urlsProvider } = props;
  const filetype = props.filetype || "";
  const signedUrl = props.fileSignedUrl || "";
  const classes = styles();
  const [imageUrl, setImageUrl] = useState("");
  const [indexUrl, setIndexUrl] = useState(0);
  useEffect(() => {
    if (urlsProvider && urlsProvider.length) {
      setImageUrl(
        urlsProvider && urlsProvider.length > 0 ? urlsProvider[0] : ""
      );
    }
  }, [urlsProvider]);

  const handlePrevious = (ev: MouseEvent) => {
    let j = indexUrl - 1;
    if (0 <= j) {
      const selectedUrl =
        urlsProvider &&
        urlsProvider.find((u, index) => {
          return index == j;
        });
      if (selectedUrl) {
        setIndexUrl(j);
        setImageUrl(selectedUrl);
      }
    }
  };

  const handleNext = (ev: MouseEvent) => {
    let j = indexUrl + 1;
    if (urlsProvider && j < urlsProvider.length) {
      const selectedUrl =
        urlsProvider &&
        urlsProvider.find((u, index) => {
          return index == j;
        });
      if (selectedUrl) {
        setIndexUrl(j);
        setImageUrl(selectedUrl);
      }
    }
  };
  return (
    <>
      <Box margin={notification ? "" : "auto"} width="100%">
        {notification ? (
          <>
            <Box className={classes.contentTitle} paddingBottom={1}>
              {notification.title || ""}
            </Box>
            <Divider variant="fullWidth" />
            {!!imageUrl && (
              <Box className={classes.center}>
                <Box className={classes.motherContent}>
                  {splitFileTypeExt(filetype).type === "image" ? (
                    <Box className={classes.imageContent}>
                      <Image
                        src={imageUrl}
                        style={{ paddingTop: "calc(70%)" }}
                      />
                      {urlsProvider && 1 < urlsProvider.length && (
                        <Box mt={1}>
                          <CustomButton
                            variant="outlined"
                            color={"default"}
                            style={{
                              marginRight: 8,
                              fontSize: 10,
                              textAlign: "left",
                            }}
                            disabled={indexUrl < 0}
                            onClick={handlePrevious}
                          >
                            {" "}
                            <FormattedMessage id="previous" />
                          </CustomButton>
                          <CustomButton
                            variant="outlined"
                            color={"default"}
                            style={{
                              fontSize: 10,
                              textAlign: "left",
                            }}
                            disabled={
                              !urlsProvider ||
                              (urlsProvider && urlsProvider.length <= indexUrl)
                            }
                            onClick={handleNext}
                          >
                            {" "}
                            <FormattedMessage id="next" />
                          </CustomButton>
                        </Box>
                      )}
                    </Box>
                  ) : splitFileTypeExt(filetype).type === 'video' ? (
                    <>
                      <Video
                        autoPlay
                        loop
                        muted
                        controls={[
                          "PlayPause",
                          "Seek",
                          "Time",
                          "Volume",
                          "Fullscreen",
                        ]}
                      >
                        <source src={signedUrl} type={filetype} />
                      </Video>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            )}

            <Box className={classes.textContent}>
            {notification.content ? parse(notification.content) : ''}
            </Box>
          </>
        ) : (
          <Box className={classes.emptyContent}>
            <Notifications htmlColor="#DFDFDF" style={{ fontSize: 50 }} />
            <Typography color="textSecondary" variant="body2">
              <FormattedMessage id="notification.text.selectNotification" />
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default NotificationDetails;
