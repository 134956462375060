import {
  Box,
  Divider,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import emojiLunette from "../Assets/emojiLunette.png";
import maintenance from "../Assets/maintenance2.png";

import MuiDialogContent from "@material-ui/core/DialogContent";
const polygone = "/assets/svg/polygone.svg";
const sapheer = "/assets/svg/logo.svg";

const Content = withStyles((theme: Theme) => ({
  root: {
    overflow: "hidden",
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 345,
    height: "100%",
    overflow: "hidden",
    background: `linear-gradient(rgba(0,229,255,.8), rgba(100,122,228,0.8)), url(${polygone}) center center  / cover no-repeat`,
    [theme.breakpoints.down("sm")]: {
      width: 250,
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  logo: {
    minWidth: 150,
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },
    margin: "auto",
  },
  rightContent: {
    position: "absolute",
    top: 0,
    left: 345,
    width: "calc(100% - 345px)",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      left: 250,
      width: "calc(100% - 250px)",
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.down("xs")]: {
      left: 0,
      width: "100%",
      padding: theme.spacing(0, 2),
    },
  },
  headerTitle: {
    "@media (max-width: 360px)": {
      display: "none",
    },
  },
  title: {
    fontSize: "2.1875rem",
    fontWeight: 600,
  },
  divider: {
    width: 100,
    height: 2,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    width: 480,
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 766px)": {
      width: 400,
    },
    "@media (max-width: 667px)": {
      width: 350,
    },
    "@media (max-width: 599px)": {
      width: "100%",
    },
  },
  contentTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
    backgroundImage: "linear-gradient(to right, #5E71FF 30%, #00E5FF)",
    height: 100,
  },
  contentDescription: {
    height: 400,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 766px)": {
      height: "100%",
    },
  },
  subtitle1: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "center",
    margin: "16px",
  },
  emoji: {
    width: 50,
    height: 50,
  },
}));
const NoConnection: FC = () => {
  const intl = useIntl();
  const classes = useStyles({});
  return (
    <Box display="flex">
      <Box display="flex" className={classes.logoContainer}>
        <img className={classes.logo} src={sapheer} alt="Logo sapheer" />
      </Box>
      <Box className={classes.rightContent}>
        <Box mb={2} className={classes.headerTitle}>
          <Typography className={classes.title} variant="h2" color="primary">
            <FormattedMessage id="signin.title" />
          </Typography>
          <Divider className={classes.divider} variant="middle" />
        </Box>
        <Box className={classes.content}>
          <Box className={classes.contentTitle}>
            <Typography
              variant="h6"
              style={{ fontSize: 20, fontWeight: 500, textAlign: "center" }}
            >
              <FormattedMessage id="maintenance.title" />
            </Typography>
          </Box>
          <Content className={classes.contentDescription}>
            <Typography className={classes.subtitle1} color="primary">
              <FormattedMessage id="maintenance.subtitle1" />
            </Typography>
            <img
              className={classes.emoji}
              src={maintenance}
              alt="Logo maintenance"
            />
            <Typography className={classes.subtitle1} color="primary">
              <FormattedMessage id="maintenance.subtitle2" />
            </Typography>
            <img
              className={classes.emoji}
              src={emojiLunette}
              alt="Emoji sapheer"
            />
          </Content>
        </Box>
      </Box>
    </Box>
  );
};
export default NoConnection;
