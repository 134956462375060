import gql from 'graphql-tag';

const ACCOUNT_TEAM_FRAGMENT = gql`
  fragment accountTeamInfo on Account {
    email
  }
`;

const TEAM_FRAGMENT = gql`
  fragment teamInfo on Team {
    id
    name
    isActive
  }
`;

const TEAMS_COMPANY_FRAGMENT = gql`
  fragment teamsCompany on Team {
    id
    name
    isActive
  }
`;
export { ACCOUNT_TEAM_FRAGMENT, TEAM_FRAGMENT, TEAMS_COMPANY_FRAGMENT };
