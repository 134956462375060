import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useIntl } from 'react-intl';
import { useLogout } from '../../../Auth/Logout/Logout';
import ConfirmationDialog from '../../ConfirmationDialog/ConfirmationDialog';

interface Props {
  children: React.ReactNode;
}

const UserInactivity = (props: Props) => {
  const { children } = props;
  const intl = useIntl();
  let timeout: any = null;
  const [confirmation, setConfirmation] = useState(false);
  const confirmationTime = 1000 * 60; // 1min
  // ---- unité en milliseconde
  // ---- logout apres 8 minutes d'inactivité
  const inactivityTimer = 1000 * 60 * 8;
  const message = intl.formatMessage({ id: 'user.confirmation.deconnect' });
  const { logout } = useLogout();
  const handleOnIdle = (event: any) => {
    setConfirmation(true);
  };

  useEffect(() => {
    if (confirmation) {
      timeout = setTimeout(() => {
        logout();
      }, confirmationTime); // 1 min
    }
  }, [confirmation]);

  const handleOnActive = (event: any) => {};
  const handleClose = () => {
    clearTimeout(timeout);
    setConfirmation(false);
  };
  const handleAccept = () => {};
  const handleOnAction = (e: any) => {};

  useIdleTimer({
    timeout: inactivityTimer,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  return (
    <div style={{ display: 'flex' }}>
      {confirmation && (
        <ConfirmationDialog
          title="disconnect"
          isNotConfirm={true}
          message={message}
          open={confirmation}
          handleAccept={handleAccept}
          handleClose={handleClose}
        />
      )}
      {children}
    </div>
  );
};

export default UserInactivity;
