export default {
  'toolbar.button.add': 'Dépôt de chèque',
  'toolbar.input.search': 'Rechercher un émetteur',
  'dialog.create.headerTitle': 'Dépôt de chèque',
  'dialog.create.title': 'Complétez les informations',
  'dialog.create.button': 'Déposer',
  'dialog.filter.headerTitle': 'Filtre chèques',
  'dialog.filter.title': 'Filtrez vos chèques',
  'dialog.filter.paragraph':
    'Visualisez vos chèques selon vos critères et préférences.',
  'dialog.filter.fields.status': 'Statut',
  'cheque.input.debitor.empty': 'Veuillez saisir le nom du débiteur',
  'cheque.input.amount.empty': 'Veuillez saisir un montant',
  'cheque.input.amount.max': 'Vous avez saisi un montant supérieur à 10 000 €',
  'cheque.input.info.empty': 'Veuillez saisir les informations du cheque',
  'cheque.create.error': 'Erreur lors de la création du cheque.',
  'cheque.get.error':
    'Erreur lors de la récuperation des informations du cheque.',
  'cheque.error.lists': 'Erreur lors de la récuperation des cheques',
  'cheque.input.info.cmc7length': `Veuillez vérifier les informations du cheque`,
};
