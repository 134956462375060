import gql from 'graphql-tag';
import { PERSON_INVITE_FRAGMENT } from '../person/fragment';
import { RIGHT_FRAGMENT, ROLES_INVITE_FRAGMENT } from '../role/fragment';

export const CHECK_CONNECTION = gql`
  query CheckConnection {
    checkConnection
  }
`;

export const GET_MY_ROLE_RIGHTS = gql`
  query MyRoleRights {
    me {
      id
      role {
        id
        name
      }
      roleRights {
        ...rightInfo
      }
    }
  }
  ${RIGHT_FRAGMENT}
`;

export const GET_MY_ACCOUNT_INVITE = gql`
  query MyAccountInvite($token: String!) {
    accountInvite(token: $token) {
      id
      email
      phone
      codeSpark
      isInvitationAccepted
      company {
        legalName
      }
      role {
        ...roleInviteInfo
      }
      person {
        ...personInviteInfo
      }
    }
  }
  ${ROLES_INVITE_FRAGMENT}
  ${PERSON_INVITE_FRAGMENT}
`;

export const GET_MY_CARD_LIMIT = gql`
  query MyCardLimit {
    me {
      id
      offer {
        id
        limitPaymentMonth
        limitAtmWeek
      }
    }
  }
`;

export const GET_MY_COMPANY_CREATED_AT = gql`
  query GetMyCompanyCreatedAt {
    me {
      id
      company {
        id
        createdAt
      }
    }
  }
`;
