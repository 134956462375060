import { useApolloClient, useSubscription } from '@apollo/client';
import React, { FC } from 'react';
import { ON_DOWNLOAD_STATEMENT } from '../../../../graphql/transaction/subscription';
import { OnDownloadStatement } from '../../../../graphql/transaction/types/OnDownloadStatement';
import { useOpenFile } from '../../../customHooks/file/dowloadOrOpenFile';

const StatementSub: FC = (props) => {
  const client = useApolloClient();
  const { openUrlFile } = useOpenFile();

  useSubscription<OnDownloadStatement>(ON_DOWNLOAD_STATEMENT, {
    client,
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (
        data?.onDownloadStatement?.url &&
        data?.onDownloadStatement?.filename
      ) {
        openUrlFile(
          data.onDownloadStatement.url,
          data.onDownloadStatement.filename,
        );
      }
    },
  });
  return <></>;
};

export default StatementSub;
