import gql from 'graphql-tag';

export const CHEQUE_FRAGMENT = gql`
  fragment ChequeInfo on Cheque {
    id
    payinId
    payinStatus
    amount
    viewed
    currency
    createdDate
    debitorName
    cmc7
    key
    modalityType
    executeDate
    receivedDate
  }
`;
