import gql from 'graphql-tag';

const CARD_TYPE_FRAGMENT = gql`
  fragment cardTypeInfo on CardType {
    id
    name
  }
`;

const CARD_FRAGMENT = gql`
  fragment cardInfo on Card {
    id
    isBlocked
    isActive
    isPhysical
    enableNFC
    enableOnline
    enableForeign
    enableWithDrawal
    type
    treezorCardId
    paymentLimit
    withDrawalLimit
    withContract
    maskedPan
    totalMonthPayement
    totalWeekWithDraw
    cardType {
      ...cardTypeInfo
    }
  }
  ${CARD_TYPE_FRAGMENT}
`;
const CARD_PIN_FRAGMENT = gql`
  fragment CardPinInfo on Card {
    id
  }
`;

const CARDS_FRAGMENT = gql`
  fragment cardsInfo on Card {
    id
    isBlocked
    type
    enableNFC
    isPhysical
    enableOnline
    enableForeign
    enableWithDrawal
    maskedPan
    totalMonthPayement
    totalWeekWithDraw
    cardType {
      ...cardTypeInfo
    }
  }
  ${CARD_TYPE_FRAGMENT}
`;

const WALLET_CARDS_INFO = gql`
  fragment walletCardsInfo on Card {
    id
    isBlocked
    type
    enableNFC
    isPhysical
    enableOnline
    enableForeign
    enableWithDrawal
    maskedPan
    totalMonthPayement
    totalWeekWithDraw
    cardType {
      ...cardTypeInfo
    }
  }
  ${CARD_TYPE_FRAGMENT}
`;

const CARD_WALLET_FRAGMENT = gql`
  fragment CardWalletInfo on Card {
    id
    isBlocked
    isActive
    isPhysical
    enableNFC
    enableOnline
    enableForeign
    enableWithDrawal
    type
    treezorCardId
    paymentLimit
    withDrawalLimit
    withContract
    maskedPan
    lastPayment
    totalMonthPayement
    totalWeekWithDraw
    cardLockState
    deliveryAddress
    account {
      id
      person {
        id
        name
        firstName
      }
    }
    cardType {
      ...cardTypeInfo
    }
    limits {
      totalAtmWeek
      totalPaymentMonth
    }
  }
  ${CARD_TYPE_FRAGMENT}
`;

const CARD_OPTIONS_FRAGMENT = gql`
  fragment CardOptionsInfo on Card {
    id
    isBlocked
    isActive
    isPhysical
    enableNFC
    enableOnline
    enableForeign
    enableWithDrawal
    type
    treezorCardId
    paymentLimit
    withDrawalLimit
    withContract
    maskedPan
    lastPayment
    totalMonthPayement
    totalWeekWithDraw
    cardLockState
  }
`;

const CARD_PAN_PHYSICAL_FRAGMENT = gql`
  fragment CardPanPhysicalInfo on Card {
    id
    isPhysical
    maskedPan
    account {
      id
      person {
        id
        name
        firstName
      }
    }
  }
`;

export {
  CARD_FRAGMENT,
  CARDS_FRAGMENT,
  WALLET_CARDS_INFO,
  CARD_WALLET_FRAGMENT,
  CARD_PAN_PHYSICAL_FRAGMENT,
  CARD_PIN_FRAGMENT,
  CARD_OPTIONS_FRAGMENT,
};
