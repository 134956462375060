import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetMembers_getMembers_leader,
  GetMembers_getMembers_members,
} from '../../graphql/graphql_account/members/types/GetMembers';
export interface ISetTeam {
  companyRolesTeams?: string;
  teams?: string;
}
export const teamSlice = createSlice({
  name: 'team',
  initialState: {
    header: {
      companyRolesTeams: '',
      teams: '',
    },
    listMembers: [] as (GetMembers_getMembers_members | null)[] | null,
    leader: {} as GetMembers_getMembers_leader | null,
  },
  reducers: {
    setTeam: (state, action: PayloadAction<ISetTeam>) => {
      let newObject = {};
      Object.assign(newObject, action.payload);
      state.header = { ...state.header, ...newObject };
    },
    setListMembers: (
      state,
      action: PayloadAction<Array<GetMembers_getMembers_members | null>>,
    ) => {
      state.listMembers = action.payload;
    },
    setLeader: (
      state,
      action: PayloadAction<GetMembers_getMembers_leader | null>,
    ) => {
      state.leader = action.payload;
    },
  },
});

export const { setTeam, setListMembers, setLeader } = teamSlice.actions;
export default teamSlice.reducer;
