import { OnTransferCreated_onTransferCreate } from '../../../graphql/graphql_subscription/types/OnTransferCreated';
import { LastTransaction_lastTransaction } from '../../../graphql/graphql_transaction/types/LastTransaction';
import { TransactionItem, _transactions } from '../../../indexDB/data';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';
import {
  setLastCardTransaction,
  setLastTransaction,
  setTransactions,
  setTxInvalidated,
} from '../../../redux/transaction/transactionSlice';
import {
  TRANSACTION_MODALITY,
  TRANSACTION_STATE,
} from '../../../utils/transaction';
import { addOrUpdateObject, removeObject } from '../../../utils/utils';
import { useGetBalance } from '../../Common/Layout/AppBar/AppBarData/AppBarData';

export const useCreateOrUpdateTransactionCache = () => {
  const dispatch = useAppDispatch();
  const { txInvalidated, transactions, lastTransaction, lastCardTransaction } =
    useAppSelector((state) => state.transaction);

  const { getData } = useGetBalance();

  const updateOrCreate = async (
    transaction:
      | LastTransaction_lastTransaction
      | OnTransferCreated_onTransferCreate,
  ) => {
    // update list transaction invalidated
    const { transactionState, modalityType } = transaction;
    if (
      transactionState == TRANSACTION_STATE.VALIDATED ||
      transactionState == TRANSACTION_STATE.SETTLED ||
      transactionState == TRANSACTION_STATE.REFUND ||
      transactionState == TRANSACTION_STATE.REJECTED ||
      transactionState == TRANSACTION_STATE.DECLINED
    ) {
      await removeInvalid(transaction.id);
      // update list transaction validated
      const listValid = addOrUpdateObject(transactions, transaction);
      dispatch(setTransactions([...listValid]));
      if (
        modalityType == TRANSACTION_MODALITY.PAIEMENT ||
        modalityType == TRANSACTION_MODALITY.WITHDRAWAL
      ) {
        // update list last card transaction validated
        const listLastCardTx = addOrUpdateObject(
          lastCardTransaction,
          transaction,
        );
        dispatch(setLastCardTransaction([...listLastCardTx]));
        await _transactions.setItem(
          TransactionItem.LAST_CARD_TRANSACTION,
          JSON.stringify(listLastCardTx),
        );
      } else if (
        modalityType == TRANSACTION_MODALITY.TRANSFERT ||
        modalityType == TRANSACTION_MODALITY.PAYIN
      ) {
        // update list last transaction validated
        const listLastTx = addOrUpdateObject(lastTransaction, transaction);
        dispatch(setLastTransaction([...listLastTx]));
        await _transactions.setItem(
          TransactionItem.LAST_TRANSACTION,
          JSON.stringify(listLastTx),
        );
      }
    } else if (
      (transactionState == TRANSACTION_STATE.UNVALIDATED ||
        transactionState == TRANSACTION_STATE.PENDING) &&
      (modalityType == TRANSACTION_MODALITY.TRANSFERT ||
        modalityType == TRANSACTION_MODALITY.PAYIN)
    ) {
      const listInvalid = addOrUpdateObject(txInvalidated, transaction);
      dispatch(setTxInvalidated([...listInvalid]));
      await _transactions.setItem(
        TransactionItem.TX_INVALIDATED,
        JSON.stringify(listInvalid),
      );
    }
    await getData();
  };

  const removeInvalid = async (id: string | null) => {
    if (id) {
      const listInvalid = removeObject(txInvalidated, { id });
      dispatch(setTxInvalidated([...listInvalid]));
      await _transactions.setItem(
        TransactionItem.TX_INVALIDATED,
        JSON.stringify(listInvalid),
      );
      await getData();
    }
  };

  return { updateOrCreate, removeInvalid };
};
