import gql from 'graphql-tag';

export const ATTACHEMENT_TRANSACTION_FRAGMENT = gql`
  fragment AttachmentTransactionInfo on Attachment {
    id
    uuid
    filename
    filetype
    location
    longitude
    latitude
  }
`;
