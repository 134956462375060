export const API_URL = process.env.REACT_APP_API_URL;
export const API_V2_URL = process.env.REACT_APP_API_V2_URL;
export const API_TRANSACTION = process.env.REACT_APP_API_TRANSACTION_URL;
export const API_ACCOUNT = process.env.REACT_APP_API_ACCOUNT_URL;
export const API_CARD = process.env.REACT_APP_API_CARD_URL;

export const ACCOUNT_URL = process.env.REACT_APP_ACCOUNT_URL;
export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;
export const APP_WS_URL = process.env.REACT_APP_WS_URL;
export const FRESHCHAT_TOKEN = process.env.REACT_APP_FRESHCHAT_TOKEN;
export const FRESHCHAT_EMAIL = process.env.REACT_APP_FRESHCHAT_EMAIL;
export const FRESHCHAT_FIRSTNAME = process.env.REACT_APP_FRESHCHAT_FIRSTNAME;
export const HIPAY_USERNAME = process.env.REACT_APP_HIPAY_USERNAME;
export const HIPAY_PASSWORD = process.env.REACT_APP_HIPAY_PASSWORD;
export const HIPAY_ENVIRONMENT = process.env.REACT_APP_HIPAY_ENVIRONMENT;
export const HIPAY_LANG = process.env.REACT_APP_HIPAY_LANG;
export const CGS_LINK = process.env.REACT_APP_CGS_LINK;
export const PCC_LINK = process.env.REACT_APP_PCC_LINK;

export const PAYMENT_LINK = process.env.REACT_APP_PAYMENT_LINK;
export const CARD_LINK = process.env.REACT_APP_CARD_LINK;
export const SERVICE_LINK = process.env.REACT_APP_SERVICE_LINK;
export const PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL;
export const CONTACT_URL = process.env.REACT_APP_CONTACT_URL;

export const CLOUDINARY_NAME = process.env.REACT_APP_CLOUDINARY_NAME;
export const CLOUDINARY_UPLOAD_PRESET =
  process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;

export const BO_URL = process.env.REACT_APP_BO_URL;

export const LINKCY_URL =
  process.env.REACT_APP_LINKCY_URL ?? "https://dashboard.sapheer.co";
