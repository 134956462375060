import gql from 'graphql-tag';

export const RECURRENCE_TRANSACTION_FRAGMENT = gql`
  fragment RecurrenceTransactionInfo on Recurrence {
    id
    dateDebut
    dateFinale
    nextExecutionDate
    state
    amount
    type
  }
`;
