import { makeStyles } from "@material-ui/core";

import { VIEW } from "../../../../utils/constant";
const BgDark = "/assets/svg/bg-dark.svg";
const BgLight = "/assets/svg/bg-light.svg";
const lightBg = {
  backgroundImage: `linear-gradient(to bottom, rgba(0, 229, 255,.7), rgba(94, 113, 255,.8)), url(${BgLight})`,
  backgroundPosition: "top",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const darkBg = {
  background: `url(${BgDark}) top / cover`,
};

export default makeStyles((theme) => ({
  menu: {
    width: 104,
    minHeight: "100vh",
    padding: theme.spacing(0, 1),
    color: theme.palette.common.white,
    boxShadow: "0 0 5px rgba(0,0,0,.15)",
  },
  menuColumn: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  fullWidth: {
    width: 250,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    "& img": {
      marginBottom: theme.spacing(4),
    },
    "& p": {
      fontSize: "1.125rem",
      transform: "none",
    },
    "&>div": {
      width: "100%",
    },
    "& a": {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: theme.spacing(2),
      "&>span>div": {
        display: "flex",
        alignItems: "center",
      },
      "& p": {
        marginLeft: theme.spacing(2),
      },
    },
  },
  bg: theme.palette.type === VIEW.LIGHT ? lightBg : darkBg,
  logo: {
    width: 70,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    textTransform: "none",
    "&:hover": {
      background: "none",
    },
  },
}));
